@for (fieldType of fieldTypes; track fieldType; let mainIndex = $index) {
  <div [class]="'cw-' + fieldType.type">
    @switch (fieldType.type) {
      @case ('title') {
        @if (partner?.widget_title_shown == 1) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label id="lable_title_{{ index }}" for="title_{{ index }}" class="input-label">
                {{ 'summary_page_translations.title' | translate | addRequired: { isRequired: (partner?.widget_title_required === 1 || customerRequiredFields.indexOf('title') > -1) } }}
              </label>

              <ng-select
                id="title_{{ index }}"
                name="title_{{ index }}"
                [(ngModel)]="userState.title"
                #title="ngModel"
                [required]="(partner?.widget_title_required === 1 || customerRequiredFields.indexOf('title') > -1)"
                [clearable]="false"
                (ngModelChange)="onChangeUserData()"
                [ngClass]="{ 'is-invalid': title.touched && title.invalid }"
                labelForId="lable_title_{{ index }}"
                autocomplete="honorific-suffix"
                attr.aria-invalid="{{ title.touched && title.invalid }}"
                attr.aria-describedby="{{ title.touched && title.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="lable_title_{{ index }}"
              >
                @for (title of titleTypes; track title) {
                  <ng-option [value]="title.id">
                    {{ title.name | translate }}
                  </ng-option>
                }
              </ng-select>

              @if (title.touched && title.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (title.errors?.required) {
                    <div role="alert">{{ 'appointments.step4.customFields.error.required' | translate }}</div>
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('salutation') {
        @if (partner?.show_salutation_in_widget == 1) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label id="lable_gender_{{ index }}" for="gender_{{ index }}" class="input-label">
                {{ 'summary_page_translations.salutation' | translate | addRequired: { isRequired: (partner?.widget_salutation_required === 1 || customerRequiredFields.indexOf('salutation') > -1) } }}
              </label>

              <ng-select
                id="gender_{{ index }}"
                name="gender_{{ index }}"
                [(ngModel)]="userState.gender"
                #gender="ngModel"
                [required]="(partner?.widget_salutation_required === 1 || customerRequiredFields.indexOf('salutation') > -1)"
                [clearable]="false"
                (ngModelChange)="onChangeUserData()"
                [ngClass]="{ 'is-invalid': gender.touched && gender.invalid }"
                labelForId="lable_gender_{{ index }}"
                autocomplete="honorific-prefix"
                attr.aria-invalid="{{ gender.touched && gender.invalid }}"
                attr.aria-describedby="{{ gender.touched && gender.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="lable_gender_{{ index }}"
              >
                @for (salutationType of salutationTypes; track salutationType) {
                  <ng-option [value]="salutationType.value">
                    {{ 'summary_page_translations.' + salutationType.display | translate }}
                  </ng-option>
                }
              </ng-select>

              @if (gender.touched && gender.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (gender.errors.required) {
                    {{ 'appointments.step4.customFields.error.required' | translate }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('prename') {
        <div class="row cb-personal-data-form-layout">
          <div class="col-sm-12 custom-field form-group summary-padding">
            <label class="input-label" for="firstName_{{ index }}" id="label_firstName_{{ index }}">
              <span [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 220, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired) | trustHtml"></span>
            </label>

            <input
              [required]="customerRequiredFields.indexOf('prename') > -1"
              minlength="2"
              maxlength="100"
              [(ngModel)]="userState.forename"
              placeholder="{{ 'customer.prename.placeholder' | translate }}"
              name="firstName_{{ index }}"
              #firstName="ngModel"
              id="firstName_{{ index }}"
              type="text"
              class="form-control cbw-input-field"
              [disabled]="userState.customerId"
              (ngModelChange)="onChangeUserData()"
              autocomplete="given-name"
              attr.aria-invalid="{{ firstName.touched && firstName.invalid }}"
              attr.aria-describedby="{{ firstName.touched && firstName.invalid ? 'error-cf-' + index : undefined }}"
              attr.aria-labelledby="label_firstName_{{ index }}"
            />

            @if (firstName.touched && firstName.invalid) {
              <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                @if (firstName.errors?.required) {
                  {{ 'customer.prename.error.required' | translate }}
                }
                @if (firstName.errors?.minlength) {
                  {{ 'customer.prename.error.minChars' | translate:firstName.errors['minlength'] }}
                }
              </div>
            }
          </div>
        </div>
      }

      @case ('lastname') {
        <div class="row cb-personal-data-form-layout">
          <div class="col-sm-12 custom-field form-group summary-padding">
            <label class="input-label" for="lastName_{{ index }}" id="label_lastName_{{ index }}">
              <span [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 221, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired) | trustHtml"></span>
            </label>

            <input
              [required]="customerRequiredFields.indexOf('lastname') > -1"
              minlength="2"
              maxlength="100"
              [(ngModel)]="userState.lastName"
              placeholder="{{ 'customer.lastname.placeholder' | translate }}"
              #lastName="ngModel"
              id="lastName_{{ index }}"
              name="lastName_{{ index }}"
              type="text"
              class="form-control cbw-input-field"
              [disabled]="userState.customerId"
              (ngModelChange)="onChangeUserData()"
              autocomplete="family-name"
              attr.aria-invalid="{{ lastName.touched && lastName.invalid }}"
              attr.aria-describedby="{{ lastName.touched && lastName.invalid ? 'error-cf-' + index : undefined }}"
              attr.aria-labelledby="label_lastName_{{ index }}"
            />

            @if (lastName.touched && lastName.invalid) {
              <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                @if (lastName.errors?.required) {
                  {{ 'customer.lastname.error.required' | translate }}
                }
                @if (lastName.errors?.minlength) {
                  {{ 'customer.lastname.error.minChars' | translate:lastName.errors['minlength'] }}
                }
              </div>
            }
          </div>
        </div>
      }

      @case ('meeting_types') {
        @if (meetingTypes?.length > 1) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="meeting_type_id_{{ index }}" id="label_meeting_type_id_{{ index }}">
                <span [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml"></span>
                @if (customerRequiredFields.indexOf('meeting_types') > -1) {
                  <span> *</span>
                }
              </label>

              <ng-select
                id="meeting_type_id_{{ index }}"
                name="meeting_type_id_{{ index }}"
                [(ngModel)]="userState.meeting_type_id"
                #meeting_type_id="ngModel"
                [items]="meetingTypes"
                [clearable]="false"
                [bindValue]="'id'"
                [required]="customerRequiredFields.indexOf('meeting_types') > -1"
                [searchable]="false"
                (change)="onMeetingTypeChange($event); onChangeUserData();"
                class="cbw-meeting-type"
                labelForId="label_meeting_type_id_{{ index }}"
                attr.aria-describedby="{{ (widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang }}"
                attr.aria-labelledby="label_meeting_type_id_{{ index }}"
              >
                <ng-template ng-option-tmp let-item="item">
                  <div class="d-flex align-items-center">
                    <div class="meeting-icon d-flex align-items-center">

                      <!-- @for (image of meetingTypeImages; track image){
                        @if (userState.meetingType.id === image.id){
                          <img [src]=" environment.baseUrl + '/images/meetings/' + image.src" [alt]="image.alt" width="26px" />
                        }
                      } -->

                      @if (item.id === 1) {
                        <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="26px" />
                      } @else if (item.id === 2) {
                        <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="26px" />
                      } @else if (item.id === 3) {
                        <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="26px" />
                      } @else if (item.id === 4) {
                        <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
                      } @else if (item.id === 5) {
                        <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="26px" />
                      } @else if (item.id === 6) {
                        <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="26px" />
                      } @else if (item.id === 7) {
                        <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="26px" />
                      } @else if (item.id === 8) {
                        <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="26px" />
                      } @else if (item.id === 9) {
                        <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="26px" />
                      } @else if (item.id === 10) {
                        <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="26px" />
                      } @else if (item.id === 11) {
                        <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
                      } @else if (item.id === 13) {
                        <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="26px" />
                      }
                    </div>

                    @if ((widgetTemplates | calioMeetingTemplate: { id: item.id, type: 'meeting_template' }); as widgetTemplate) {
                      <div class="meeting-option">
                        @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                          <div
                            class="meeting-type-name"
                            [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                          </div>
                        }
                        @if ((widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                          <div
                            [innerHTML]="(widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                          </div>
                        }
                        @if (item.id === 1 && selectedStore) {
                          <div class="cbw-summary-worker-store-location">
                            <span class="cbw-summary-worker-store-location-name">{{ selectedStore | translation: 'name' : lang }}</span>
                            @if (selectedStore.is_online === 0) {
                              <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                              <span class="cbw-summary-worker-store-location-address">
                                @if (selectedStore.street) {
                                  <span>{{ selectedStore.street }}</span>
                                }
                                @if (selectedStore.zip) {
                                  <span>,&nbsp;{{ selectedStore.zip }}</span>
                                }
                                @if (selectedStore.city) {
                                  <span>&nbsp;{{ selectedStore.city }}</span>
                                }
                                @if (selectedStore.country) {
                                  <span>,&nbsp;{{ selectedStore.country | translation: 'name' : lang }}</span>
                                }
                              </span>
                            }
                          </div>
                        }
                      </div>
                    }
                  </div>
                </ng-template>

                <ng-template ng-label-tmp let-item="item">
                  <div class="d-flex align-items-center">
                    <div class="meeting-icon d-flex align-items-center">
                      <!-- @for (image of meetingTypeImages; track image){
                        @if(userState.meetingType.id === image.id){
                          <img [src]=" environment.baseUrl + '/images/meetings/' + image.src" [alt]="image.alt" width="26px" />
                        }
                      } -->

                      @if (item.id === 1) {
                        <img src="{{ environment.baseUrl }}/images/meetings/store.svg" alt="Store Logo" width="26px" />
                      } @else if (item.id === 2) {
                        <img src="{{ environment.baseUrl }}/images/meetings/zoom.svg" alt="Zoom Logo" width="26px" />
                      } @else if (item.id === 3) {
                        <img src="{{ environment.baseUrl }}/images/meetings/calenso.svg" alt="Calenso Logo" width="26px" />
                      } @else if (item.id === 4) {
                        <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
                      } @else if (item.id === 5) {
                        <img src="{{ environment.baseUrl }}/images/meetings/customer.svg" alt="Customer Logo" width="26px" />
                      } @else if (item.id === 6) {
                        <img src="{{ environment.baseUrl }}/images/meetings/unblu.svg" alt="Unblu Logo" width="26px" />
                      } @else if (item.id === 7) {
                        <img src="{{ environment.baseUrl }}/images/meetings/teams.svg" alt="Teams Logo" width="26px" />
                      } @else if (item.id === 8) {
                        <img src="{{ environment.baseUrl }}/images/meetings/meet.svg" alt="Meet Logo" width="26px" />
                      } @else if (item.id === 9) {
                        <img src="{{ environment.baseUrl }}/images/meetings/gotomeeting.png" alt="Gotomeeting Logo" width="26px" />
                      } @else if (item.id === 10) {
                        <img src="{{ environment.baseUrl }}/images/meetings/webex.png" alt="Webex Logo" width="26px" />
                      } @else if (item.id === 11) {
                        <img src="{{ environment.baseUrl }}/images/meetings/phone.svg" alt="Phone Logo" width="26px" height="30px" />
                      } @else if (item.id === 13) {
                        <img src="{{ environment.baseUrl }}/images/meetings/metaverse.svg" alt="Metaverse" width="26px" />
                      }
                    </div>

                    @if ((widgetTemplates | calioMeetingTemplate: { id: item.id, type: 'meeting_template' }); as widgetTemplate) {
                      <div class="meeting-option">
                        @if ((widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                          <div class="meeting-type-name"
                            [innerHTML]="(widgetTemplate.titleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                          </div>
                        }
                        @if ((widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang)) {
                          <div [innerHTML]="(widgetTemplate.descTemplate | translation: templateContent.TEMPLATE_CONTENT: lang) | trustHtml">
                          </div>
                        }
                      </div>
                    }
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        }
      }

      @case ('notification_preferences') {
        @if (partner?.ask_for_customer_notification_preference == 1 && notificationTypes?.length) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="customer_notification_preference_{{ index }}" id="label_customer_notification_preference_{{ index }}">
                {{ 'summary_page_translations.notification_type' | translate | addRequired: {
                  isRequired: customerRequiredFields.indexOf('notification_preferences') > -1
                } }}
              </label>

              <ng-select
                id="customer_notification_preference_{{ index }}"
                name="customer_notification_preference_{{ index }}"
                [(ngModel)]="userState.customer_notification_preference"
                #customer_notification_preference="ngModel"
                (ngModelChange)="onChangeUserData()"
                [required]="customerRequiredFields.indexOf('notification_preferences') > -1"
                [ngClass]="{ 'is-invalid': customer_notification_preference.touched && customer_notification_preference.invalid }"
                labelForId="label_customer_notification_preference_{{ index }}"
                attr.aria-invalid="{{ customer_notification_preference.touched && customer_notification_preference.invalid }}"
                attr.aria-describedby="{{ customer_notification_preference.touched && customer_notification_preference.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_customer_notification_preference_{{ index }}"
              >
                @for (notificationType of notificationTypes; track notificationType) {
                  <ng-option [value]="notificationType.value">
                    {{ 'summary_page_translations.' + notificationType.display | translate }}
                  </ng-option>
                }
              </ng-select>

              @if (customer_notification_preference.touched && customer_notification_preference.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (customer_notification_preference.errors?.required) {
                    {{ 'customer.lastname.error.required' | translate }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('email') {
        @if (globals?.isInternal) {
          @if (partner?.internal_widget_email_shown === 1) {
            <div class="row cb-personal-data-form-layout ">
              <div class="col-sm-12 custom-field form-group summary-padding">
                <label class="input-label" for="internaleMail_{{ index }}" id="label_internaleMail_{{ index }}">
                  {{ 'customer.eMail.label' | translate | addRequired: { isRequired: (partner?.internal_widget_email_required === 1 || customerRequiredFields.indexOf('email') > -1) } }}
                </label>

                <input
                  [required]="partner?.internal_widget_email_required === 1 || customerRequiredFields.indexOf('email') > -1"
                  [minlength]="(partner?.internal_widget_email_required === 1) ? 5: 0"
                  maxlength="100"
                  [(ngModel)]="userState.eMail"
                  type="email"
                  [placeholder]="((widgetTemplates | calioMeetingTemplate: { id: 169, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang )"
                  #internaleMail="ngModel"
                  id="internaleMail_{{ index }}"
                  name="internaleMail_{{ index }}"
                  class="form-control cbw-input-field"
                  (ngModelChange)="onEmailChange(internaleMail)"
                  [disabled]="userState.customerId"
                  email
                  autocomplete="email"
                  attr.aria-invalid="{{ internaleMail.touched && internaleMail.invalid }}"
                  attr.aria-describedby="{{ internaleMail.touched && internaleMail.invalid ? 'error-cf-' + index : undefined }}"
                  attr.aria-labelledby="label_internaleMail_{{ index }}"
                />

                @if (internaleMail.touched && internaleMail.invalid) {
                  <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                    @if (internaleMail.errors?.required) {
                      {{ 'customer.eMail.error.required' | translate }}
                    }
                    @if (internaleMail.errors?.minlength) {
                      {{ 'customer.eMail.error.minChars' | translate: internaleMail.errors.minlength }}
                    }
                    @if (internaleMail.errors?.email) {
                      {{ 'customer.eMail.error.pattern' | translate }}
                    }
                  </div>
                }
              </div>
            </div>
          }
        } @else {
          @if (partner?.widget_email_shown === 1) {
            <div class="row cb-personal-data-form-layout ">
              <div class="col-sm-12 custom-field form-group summary-padding">
                <label class="input-label" for="email_{{ index }}" id="label_email_{{ index }}">
                  <span
                    [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 222, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired: {isRequired: (partner?.widget_email_required === 1 || customerRequiredFields.indexOf('email') > -1)}) | trustHtml"
                  ></span>
                </label>

                <input
                  [required]="(partner?.widget_email_required === 1 || customerRequiredFields.indexOf('email') > -1)"
                  [minlength]="(partner?.widget_email_required === 1) ? 5: 0"
                  maxlength="100"
                  [(ngModel)]="userState.eMail"
                  type="email"
                  [placeholder]="((widgetTemplates | calioMeetingTemplate: { id: 169, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang)"
                  #eMail="ngModel"
                  id="email_{{ index }}"
                  name="email_{{ index }}"
                  class="form-control cbw-input-field"
                  (ngModelChange)="onEmailChange(eMail)"
                  [disabled]="userState.customerId"
                  email
                  autocomplete="given-name"
                  attr.aria-invalid="{{ eMail.touched && eMail.invalid }}"
                  attr.aria-describedby="{{ eMail.touched && eMail.invalid ? 'error-cf-' + index : undefined }}"
                  attr.aria-labelledby="label_email_{{ index }}"
                />

                @if (eMail.touched && eMail.invalid) {
                  <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                    @if (eMail.errors?.required) {
                      {{ 'customer.eMail.error.required' | translate }}
                    }
                    @if (eMail.errors?.minlength) {
                      {{ 'customer.eMail.error.minChars' | translate:eMail.errors['minlength'] }}
                    }
                    @if (eMail.errors?.email) {
                      {{ 'customer.eMail.error.pattern' | translate }}
                    }
                  </div>
                }
              </div>
            </div>
          }
        }
      }

      @case ('phone') {
        @if (partner?.needPhoneNumber) {
          <div class="row cb-personal-data-form-layout">
            <div class="form-field-margin summary-padding" [ngClass]="{ 'col-md-6': showPhoneSmsCodeInputField, 'col-md-12': !showPhoneSmsCodeInputField }">
              <label class="input-label" for="phoneNumber_{{ index }}" id="label_phoneNumber_{{ index }}">
                <span
                  [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 223, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired: {isRequired: (partner.widget_phone_required === 1 || customerRequiredFields.indexOf('phone') > -1)}) | trustHtml"
                ></span>
              </label>

              <app-cw-intl-tel-input
                [(ngModel)]="userState.phone"
                [name]="'phoneNumber_' + index"
                [id]="'phoneNumber_' + index"
                #phoneNumber="ngModel"
                cssClass="customer-form-tel-input"
                [required]="(partner.widget_phone_required === 1 || customerRequiredFields.indexOf('phone') > -1)"
                [defaultCountry]="partner?.countryCode"
                [showRequestSmsCodeButton]="showPhoneSmsCodeButton"
                [smsButtonloading]="smaPhoneButtonLoading"
                (requestSmsCodeEvent)="requestSmsCode(userState.phone, false, 'phone')"
                (blurEvent)="onBlurPhoneNumber('phone')"
                [ngClass]="{ 'is-invalid': phoneNumber.touched && phoneNumber.invalid }"
                autocomplete="tel"
                attr.aria-invalid="{{ phoneNumber.touched && phoneNumber.invalid }}"
                attr.aria-describedby="{{ phoneNumber.touched && phoneNumber.invalid ? 'error-cf-' + index : 'phoneNumber-hint' }}"
                attr.aria-labelledby="label_phoneNumber_{{ index }}"
              />

              @if (showPhoneNumberVerifiedMessage) {
                <div id="phoneNumber-hint" role="alert" aria-live="polite" class="alert-success-msg">
                  {{ 'summary_page_translations.phone_number_verified_successfully' | translate }}
                </div>
              }
              @if (showPhoneSmsCodeVerifiedMessage) {
                <div id="phoneNumber-hint" role="alert" aria-live="polite" class="alert-success-msg">
                  {{ 'summary_page_translations.phone_number_verified_successfully' | translate }}
                </div>
              }
              @if (phoneNumber.touched && phoneNumber.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (phoneNumber.errors?.required) {
                    {{ 'customer.phone.error.required' | translate }}
                  }
                  @if (phoneNumber.errors?.invalidNumber) {
                    {{ 'customer.phone.error.invalid' | translate }}
                  }
                  @if (phoneNumber.errors?.minlength) {
                    {{ 'customer.phone.error.minChars' | translate: phoneNumber.errors?.minlength }}
                  }
                  @if (phoneNumber.errors?.pattern) {
                    {{ 'customer.phone.error.pattern' | translate }}
                  }
                  @if (phoneNumber.errors?.verficationNeeded) {
                    {{ 'summary_page_translations.mobile_number_must_be_verified' | translate }}
                  }
                  @if (phoneNumber.errors?.exceededSmsLimits) {
                    <span [innerHTML]="('summary_page_translations.exceeded_sms_verification_limit_msg' | translate) | trustHtml"></span>
                  }
                </div>
              }
            </div>

            <!-- sms code -->
            @if (showPhoneSmsCodeInputField) {
              <div class="col-md-6 form-field-margin custom-field form-group">
                <label class="input-label" for="smsPhoneCode_{{ index }}" id="label_smsPhoneCode_{{ index }}">
                  {{ 'summary_page_translations.verification_code' | translate }}*
                </label>
                <div class="input-group sms-code-input-group">
                  <input
                    required
                    [(ngModel)]="smsPhoneCode"
                    id="smsPhoneCode_{{ index }}"
                    name="smsPhoneCode_{{ index }}"
                    #smsPhoneCodeModel="ngModel"
                    id="smsPhoneCode"
                    type="number"
                    class="form-control cbw-input-field custom-input-mobile-radius"
                    (keydown)="smsCodeVerification($event, 'phone')"
                    autocomplete="one-time-code"
                    attr.aria-invalid="{{ smsPhoneCodeModel.touched && smsPhoneCodeModel.invalid }}"
                    attr.aria-describedby="{{ smsPhoneCodeModel.touched && smsPhoneCodeModel.invalid ? 'error-cf-smsPhoneCode-' + index : 'smsPhoneCodeModel-hint' }}"
                    attr.aria-labelledby="label_smsPhoneCode_{{ index }}"
                  />

                  @if ((smsPhoneCode + '').length === 6) {
                    <div role="button" class="input-group-text confirm-code-button" (click)="confirmSmsCode(userState.phone, smsPhoneCode, 'phone')">
                      {{ 'summary_page_translations.check' | translate }}
                    </div>
                  }
                  @if ((smsPhoneCode + '').length !== 6) {
                    <div role="button" aria-disabled="true" class="input-group-text confirm-code-button disable-button">
                      {{ 'summary_page_translations.check' | translate }}
                    </div>
                  }
                </div>
                @if (!(smsPhoneCodeModel.errors && smsPhoneCodeModel.errors['exceededSmsLimits'])) {
                  <small id="smsPhoneCodeModel-hint" class="calio-text-muted custom-muted-font">
                    <div>{{ 'summary_page_translations.resend_sms_helper_msg_1' | translate }}</div>
                    <a href="javascript:void(0)" (click)="requestSmsCode(userState.phone, true, 'phone')">
                      {{ 'summary_page_translations.resend_sms_helper_msg_2' | translate }}
                    </a>
                  </small>
                }
                @if (smsResendForPhone) {
                  <div id="{{ 'error-cf-smsPhoneCode-' + index }}"  role="alert" aria-live="polite" class="alert-success-msg summary-warning">
                    {{ 'summary_page_translations.resend_sms_successfully' | translate }}
                  </div>
                }
                @if (smsPhoneCodeModel.touched && smsPhoneCodeModel.invalid) {
                  <div id="{{ 'error-cf-smsPhoneCode-' + index }}" role="alert" aria-live="assertive" class="alert alert-danger summary-warning">
                    @if (smsPhoneCodeModel.errors?.validCodeNeeded) {
                      {{ 'summary_page_translations.sms_code_verification_failed_msg' | translate }}
                    }
                    @if (smsPhoneCodeModel.errors?.exceededSmsLimits) {
                      <span [innerHTML]="('summary_page_translations.exceeded_sms_verification_limit_msg' | translate) | trustHtml"></span>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      }

      @case ('mobile') {
        @if (partner?.needMobileNumber) {
          <div class="row cb-personal-data-form-layout">
            <div class="form-field-margin summary-padding" [ngClass]="{ 'col-md-6': showMobileSmsCodeInputField, 'col-md-12': !showMobileSmsCodeInputField }">
              <label class="input-label" for="mobileNumber_{{ index }}" id="label_mobileNumber_{{ index }}">
                <span
                  [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 263, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired: {isRequired: (partner.widget_mobile_required === 1 || customerRequiredFields.indexOf('mobile') > -1)}) | trustHtml"
                ></span>
              </label>

              <app-cw-intl-tel-input
                [(ngModel)]="userState.mobile"
                id="mobileNumber_{{ index }}"
                name="mobileNumber_{{ index }}"
                #mobileNumber="ngModel"
                cssClass="customer-form-tel-input"
                [required]="(partner.widget_mobile_required === 1 || customerRequiredFields.indexOf('mobile') > -1)"
                [defaultCountry]="partner?.countryCode"
                [showRequestSmsCodeButton]="showMobileSmsCodeButton"
                [smsButtonloading]="smaMobileButtonLoading"
                (requestSmsCodeEvent)="requestSmsCode(userState.mobile, false, 'mobile')"
                autocomplete="tel"
                (blurEvent)="onBlurPhoneNumber('mobile')"
                [ngClass]="{ 'is-invalid': mobileNumber.touched && mobileNumber.invalid }"
                attr.aria-invalid="{{ mobileNumber.touched && mobileNumber.invalid }}"
                attr.aria-describedby="{{ mobileNumber.touched && mobileNumber.invalid ? 'error-cf-' + index : 'mobileNumber-hint' }}"
                attr.aria-labelledby="label_mobileNumber_{{ index }}"
              />

              @if (showMobileNumberVerifiedMessage) {
                <div id="mobileNumber-hint" role="alert" aria-live="polite" class="alert-success-msg">
                  {{ 'summary_page_translations.phone_number_verified_successfully' | translate }}
                </div>
              }
              @if (showMobileSmsCodeVerifiedMessage) {
                <div id="mobileNumber-hint" role="alert" aria-live="polite" class="alert-success-msg">
                  {{ 'summary_page_translations.phone_number_verified_successfully' | translate }}
                </div>
              }
              @if (mobileNumber.touched && mobileNumber.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (mobileNumber.errors?.required) {
                    {{ 'customer.phone.error.required' | translate }}
                  }
                  @if (mobileNumber.errors?.invalidNumber) {
                    {{ 'customer.phone.error.invalid' | translate }}
                  }
                  @if (mobileNumber.errors?.minlength) {
                    {{ 'customer.phone.error.minChars' | translate:mobileNumber.errors.minlength }}
                  }
                  @if (mobileNumber.errors?.pattern) {
                    {{ 'customer.phone.error.pattern' | translate }}
                  }
                  @if (mobileNumber.errors?.verficationNeeded) {
                    {{ 'summary_page_translations.mobile_number_must_be_verified' | translate }}
                  }
                  @if (mobileNumber.errors?.exceededSmsLimits) {
                    <span [innerHTML]="('summary_page_translations.exceeded_sms_verification_limit_msg' | translate) | trustHtml"></span>
                  }
                </div>
              }
            </div>

            <!-- sms code -->
            @if (showMobileSmsCodeInputField) {
              <div class="col-md-6 form-field-margin custom-field form-group">
                <label class="input-label" for="smsMobileCode_{{ index }}" id="label_smsMobileCode_{{ index }}">
                  {{ 'summary_page_translations.verification_code' | translate }}*
                </label>
                <div class="input-group sms-code-input-group">
                  <input
                    required
                    [(ngModel)]="smsMobileCode"
                    id="smsMobileCode_{{ index }}"
                    name="smsMobileCode_{{ index }}"
                    #smsMobileCodeModel="ngModel"
                    id="smsMobileCode"
                    type="number"
                    class="form-control cbw-input-field custom-input-mobile-radius"
                    (keydown)="smsCodeVerification($event, 'mobile')"
                    autocomplete="one-time-code"
                    attr.aria-invalid="{{ smsMobileCodeModel.touched && smsMobileCodeModel.invalid }}"
                    attr.aria-describedby="{{ smsMobileCodeModel.touched && smsMobileCodeModel.invalid ? 'error-cf-smsMobileCode-' + index : 'smsMobileCode-hint' }}"
                    attr.aria-labelledby="label_smsMobileCode_{{ index }}"
                  />

                  @if ((smsMobileCode + '').length === 6) {
                    <div role="button" class="input-group-text confirm-code-button" (click)="confirmSmsCode(userState.mobile, smsMobileCode, 'mobile')">
                      {{ 'summary_page_translations.check' | translate }}
                    </div>
                  }
                  @if ((smsMobileCode + '').length !== 6) {
                    <div role="button" aria-disabled="true" class="input-group-text confirm-code-button disable-button">
                      {{ 'summary_page_translations.check' | translate }}
                    </div>
                  }
                </div>
                @if (!(smsMobileCodeModel.errors?.exceededSmsLimits)) {
                  <small id="smsMobileCode-hint" class="calio-text-muted custom-muted-font">
                    <div>{{ 'summary_page_translations.resend_sms_helper_msg_1' | translate }}</div>
                    <a href="javascript:void(0)" (click)="requestSmsCode(userState.mobile, true, 'mobile')">
                      {{ 'summary_page_translations.resend_sms_helper_msg_2' | translate }}
                    </a>
                  </small>
                }
                @if (smsResendForMobile) {
                  <div id="{{ 'error-cf-' + index }}" role="alert" aria-live="polite" class="alert-success-msg summary-warning">
                    {{ 'summary_page_translations.resend_sms_successfully' | translate }}
                  </div>
                }
                @if (smsMobileCodeModel.touched && smsMobileCodeModel.invalid) {
                  <div id="{{ 'error-cf-' + index }}" role="alert" aria-live="assertive" class="alert alert-danger summary-warning">
                    @if (smsMobileCodeModel.errors?.validCodeNeeded) {
                      <div>
                        {{ 'summary_page_translations.sms_code_verification_failed_msg' | translate }}
                      </div>
                    }
                    @if (smsMobileCodeModel.errors?.exceededSmsLimits) {
                      <div>
                        <span [innerHTML]="('summary_page_translations.exceeded_sms_verification_limit_msg' | translate) | trustHtml"></span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      }

      @case ('address') {
        @if (partner?.needCustomerAddress || userState?.meeting_type_id === meetingTypeId.CUSTOMER) {
          <div class="row cb-personal-data-form-layout ">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="street_{{ index }}" id="label_street_{{ index }}">
                {{ 'customer.street.label' | translate | addRequired: { isRequired: (partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1) } }}
              </label>

              <input
                [required]="partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1"
                [minlength]="partner?.widget_address_required === 1 ? 4: 0"
                maxlength="40"
                [(ngModel)]="userState.street"
                placeholder="{{ 'customer.street.placeholder' | translate }}"
                name="street_{{ index }}"
                id="street_{{ index }}"
                #street="ngModel"
                type="text"
                class="form-control cbw-input-field"
                (ngModelChange)="onChangeUserData()"
                autocomplete="street-address"
                attr.aria-invalid="{{ street.touched && street.invalid }}"
                attr.aria-describedby="{{ street.touched && street.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_street_{{ index }}"
              />

              @if (street.touched && street.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (street.errors?.required) {
                    {{ 'customer.street.error.required' | translate }}
                  }
                  @if (street.errors?.minlength) {
                    {{ 'customer.street.error.minChars' | translate:street.errors?.minlength }}
                  }
                </div>
              }
            </div>
          </div>

          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-6 custom-field form-group summary-padding">
              <label class="input-label" for="zip_{{ index + 1 }}" id="label_zip_{{ index + 1 }}">
                {{ 'customer.zip.label' | translate | addRequired: { isRequired: (partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1) } }}
              </label>

              <input
                [required]="partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1"
                [(ngModel)]="userState.zip"
                placeholder="{{ 'customer.zip.placeholder' | translate }}"
                id="zip_{{ index + 1 }}"
                name="zip_{{ index + 1 }}"
                #zip="ngModel"
                [pattern]="zipRegex"
                type="text"
                class="form-control cbw-input-field"
                (ngModelChange)="onChangeUserData()"
                autocomplete="postal-code"
                attr.aria-invalid="{{ zip.touched && zip.invalid }}"
                attr.aria-describedby="{{ zip.touched && zip.invalid ? 'zip-error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_zip_{{ index + 1 }}"
              />

              @if (zip.touched && zip.invalid) {
                <div id="{{ 'zip-error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (zip.errors?.required) {
                    {{ 'customer.zip.error.required' | translate }}
                  }
                  @if (zip.errors?.pattern) {
                    {{ 'customer.zip.error.pattern' | translate }}
                  }
                  @if (zip.errors?.minlength) {
                    {{ 'customer.zip.error.minChars' | translate:zip.errors?.minlength }}
                  }
                </div>
              }
            </div>

            <div class="col-sm-6 custom-field form-group summary-padding">
              <label class="input-label" for="city_{{ index + 2 }}" id="label_city_{{ index + 2 }}">
                {{ 'customer.city.label' | translate | addRequired: { isRequired: (partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1) } }}
              </label>

              <input
                [required]="partner?.widget_address_required === 1 || customerRequiredFields.indexOf('address') > -1"
                [minlength]="partner?.widget_address_required === 1 ? 2: 0"
                maxlength="40"
                [(ngModel)]="userState.city"
                placeholder="{{ 'customer.city.placeholder' | translate }}"
                id="city_{{ index + 2 }}"
                name="city_{{ index + 2 }}"
                #city="ngModel"
                type="text"
                class="form-control cbw-input-field"
                (ngModelChange)="onChangeUserData()"
                autocomplete="address-level2"
                attr.aria-invalid="{{ city.touched && city.invalid }}"
                attr.aria-describedby="{{ city.touched && city.invalid ? 'city-error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_city_{{ index + 2 }}"
              />

              @if (city.touched && city.invalid) {
                <div id="{{ 'city-error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (city.errors?.required) {
                    {{ 'customer.city.error.required' | translate }}
                  }
                  @if (city.errors?.minlength) {
                    {{ 'customer.city.error.minChars' | translate:city.errors?.minlength }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('country') {
        @if (partner?.widget_country_shown == 1) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="country_{{ index }}" id="label_country_{{ index }}">
                {{ 'summary_page_translations.country' | translate | addRequired: { isRequired: (partner?.widget_country_required === 1 || customerRequiredFields.indexOf('country') > -1) } }}
              </label>

              <app-country-select
                id="country_{{ index }}"
                name="country_{{ index }}"
                [countries]="countries"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="userState.country_id"
                #country="ngModel"
                [searchable]="true"
                [clearable]="(partner?.widget_country_required === 1 || customerRequiredFields.indexOf('country') > -1)"
                [required]="(partner?.widget_country_required === 1 || customerRequiredFields.indexOf('country') > -1)"
                (selectChange)="onChangeUserData($event)"
                autocomplete="country"
                attr.aria-invalid="{{ country.touched && country.invalid }}"
                attr.aria-describedby="{{ country.touched && country.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_country_{{ index }}"
              />

              @if (country.touched && country.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (country.errors?.required) {
                    {{ 'appointments.step4.customFields.error.required' | translate }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('comment') {
        @if (type == appointmentConstant.APPOINTMENT && partner?.needMessage) {
          <div class="row cb-personal-data-form-layout">
            <div
              class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="comment_{{ index }}" id="label_comment_{{ index }}">
                {{ 'appointments.step4.message.label' | translate | addRequired: { isRequired: (partner?.widget_message_required === 1 || customerRequiredFields.indexOf('comment') > -1) } }}
              </label>

              <textarea
                [(ngModel)]="userState.comment"
                id="comment_{{ index }}"
                name="comment_{{ index }}"
                placeholder="{{ 'appointments.step4.message.placeholder' | translate }}"
                cols="30"
                rows="5"
                class="cbw-input-field textarea-field"
                [maxLength]="2000"
                #comment="ngModel"
                (ngModelChange)="onChangeUserData()"
                [required]="(partner?.widget_message_required === 1 || customerRequiredFields.indexOf('comment') > -1)"
                [ngClass]="{ 'is-invalid': comment.touched && comment.invalid }"
                autocomplete="off"
                attr.aria-invalid="{{ comment.touched && comment.invalid }}"
                attr.aria-describedby="{{ comment.touched && comment.invalid ? 'error-cf-' + index : 'comment-hint' }}"
                attr.aria-labelledby="label_comment_{{ index }}"
              ></textarea>
              <small id="comment-hint" class="calio-text-muted custom-muted-font">{{ 'appointments.step4.comment_helper_text' | translate }}</small>

              @if (comment.touched && comment.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (comment.errors?.required) {
                    {{ 'customer.customFields.error.required' | translate }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('internal_comment') {
        @if ((partner?.widget_internal_comment_shown === 1 && type == appointmentConstant.APPOINTMENT && globals?.isInternal)) {
          <div class="row cb-personal-data-form-layout form-field-margin">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="internal_comment_{{ index }}" id="label_internal_comment_{{ index }}">
                {{ 'appointments.step4.internal_comment.label' | translate | addRequired: { isRequired: (partner?.widget_internal_comment_required === 1 || false) } }}
              </label>

              <textarea
                [(ngModel)]="userState.internal_comment"
                placeholder="{{ 'appointments.step4.internal_comment.placeholder' | translate }}"
                id="internal_comment_{{ index }}"
                name="internal_comment_{{ index }}"
                cols="30"
                rows="5"
                class="cbw-input-field textarea-field"
                [maxLength]="5000"
                #internalComment="ngModel"
                (ngModelChange)="onChangeUserData()"
                [required]="(partner?.widget_internal_comment_required === 1 || false)"
                [ngClass]="{ 'is-invalid': internalComment.touched && internalComment.invalid }"
                autocomplete="on"
                attr.aria-invalid="{{ internalComment.touched && internalComment.invalid }}"
                attr.aria-describedby="{{ internalComment.touched && internalComment.invalid ? 'error-cf-' + index : 'internal-comment-hint' }}"
                attr.aria-labelledby="label_internal_comment_{{ index }}"
              ></textarea>
              <small id="internal-comment-hint" class="calio-text-muted custom-muted-font">{{ 'appointments.step4.internal_comment.comment_helper_text' | translate }}</small>

              @if (internalComment.touched && internalComment.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (internalComment?.errors?.required) {
                    <ng-container role="alert">{{ 'customer.customFields.error.required' | translate }}</ng-container>
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('policy') {
        @if (partner?.show_privacy_message_checkbox === 1) {
          <div class="row cb-personal-data-form-layout">
            <div class="form-field-margin col-sm-12 summary-padding">
              <app-cw-checkbox
                customLabelClass="ps-2"
                id="acceptDataPolicy_{{ index }}"
                name="acceptDataPolicy_{{ index }}"
                [(fieldNgModel)]="userState.acceptDataPolicy"
                [isRequired]="customerRequiredFields.indexOf('policy') > -1"
                (selectionChange)="toggleDataPolicy($event)"
                attr.aria-labelledby="label_internal_comment_{{ index }}"
              >
                <div class="custom-field-checkbox-text ps-1" id="label_internal_comment_{{ index }}">
                  <span class="input-label"
                    [innerHtml]="('summary_page_translations.accept_data_policy' | translate) | trustHtml"
                  ></span>
                  @if (customerRequiredFields.indexOf('policy') > -1) {
                    <span class="required-star"> *</span>
                  }
                </div>
              </app-cw-checkbox>
            </div>
          </div>
        }
      }

      @case ('company') {
        @if ((partner?.is_customer_profiles_feature_enabled === 1 || partner?.widget_company_name_shown === 1)) {
          <div class="row cb-personal-data-form-layout">
            <div class="col-sm-12 custom-field form-group summary-padding">
              <label class="input-label" for="company_{{ index }}" id="label_company_{{ index }}">
                {{ 'appointments.step4.company_name.label' | translate | addRequired: {
                isRequired: (partner?.widget_company_name_required === 1 || false)
                } }}
              </label>

              <input
                type="text"
                id="company_{{ index }}"
                name="company_{{ index }}"
                class="form-control cbw-input-field"
                #company="ngModel"
                [required]="(partner?.widget_company_name_required === 1 || false)"
                minlength="2"
                maxlength="100"
                [(ngModel)]="userState.company"
                (ngModelChange)="onChangeUserData()"
                autocomplete="organization"
                attr.aria-invalid="{{ company.touched && company.invalid }}"
                attr.aria-describedby="{{ company.touched && company.invalid ? 'error-cf-' + index : undefined }}"
                attr.aria-labelledby="label_company_{{ index }}"
              />

              @if (company.touched && company.invalid) {
                <div id="{{ 'error-cf-' + index }}" role="alert" class="alert alert-danger summary-warning">
                  @if (company.errors?.required) {
                    {{ 'customer.customFields.error.required' | translate }}
                  }
                  @if (company.errors?.minlength) {
                    {{ 'appointments.step4.company_name.error.minChars' | translate: company.errors?.minlength }}
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      @case ('custom_field_before') {
        @if (showBeforeCustomField) {
          <ng-content select="[beforeCustomField]" />
        }
      }
      @case ('custom_field_after') {
        @if (showAfterCustomField) {
          <ng-content select="[afterCustomField]" />
        }
      }
    }
  </div>
}

@if (appointmentState?.captcha) {
  <div class="cw-captcha">
    <div class="row cb-personal-data-form-layout">
      <div class="col-sm-12 custom-field form-group summary-padding captcha-section">
        <label class="input-label cbw-captcha-label"  for="captcha" id="label_captcha">
          <span [innerHTML]="((widgetTemplates | calioMeetingTemplate: { id: 242, type: 'template' }) | translation: templateContent.TEMPLATE_CONTENT: lang | addRequired) | trustHtml"></span>
        </label>

        <div>
          <img [src]="'data:image/png;base64,' + appointmentState.captcha.captcha" alt="captcha" class="captcha-img cbw-captcha-img">
        </div>
        <div class="pt-3 captcha-input-width">
          <div class="d-flex gap-2 align-items-center">
            <input
              required
              maxlength="6"
              minlength="6"
              spellcheck="false"
              [(ngModel)]="userState.captcha_challenge"
              placeholder="{{ 'customer.captcha.placeholder' | translate }}"
              name="captcha"
              id="captcha"
              #captcha="ngModel"
              type="text"
              class="form-control cbw-input-field cbw-input-captcha-field"
              autocomplete="off"
              attr.aria-invalid="{{ captcha.touched && captcha.invalid }}"
              attr.aria-describedby="{{ captcha.touched && captcha.invalid ? 'error-cf-captcha' : (captcha.touched && captcha.invalid ? 'success-cf-captcha' : undefined) }}"
              aria-labelledby="label_captcha"
            />
            @if (validatingCaptchaChallenge) {
              <fa-icon class="icon" icon="circle-notch" spin="true" />
            }
            @if (captchaValid) {
              <fa-icon class="icon" icon="check" />
            }
          </div>

          @if (captcha.touched && captcha.invalid) {
            <div id="error-cf-captcha" role="alert" class="alert alert-danger summary-warning cbw-captcha-error">
              @if (captcha.errors?.required) {
                {{ 'customer.captcha.required' | translate }}
              }
              @if (captcha.errors?.minlength) {
                {{ 'customer.captcha.invalid' | translate }}
              }
              @if (captcha.errors?.invalidcaptcha) {
                {{ 'customer.captcha.invalid' | translate }}
              }
            </div>
          }
          @if (captcha.touched && captcha.valid) {
            <div id="success-cf-captcha" role="alert" aria-live="polite" class="alert alert-success captcha-success cbw-captcha-success">
              {{ 'customer.captcha.valid' | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
