@if (appoinrmentServicesByCat != null) {
  <div class="accordion mt-4" id="appointment-services-accordion">
    <div>
      <app-cw-title class="cbw-select-service-title" title="{{ serviceLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}" />
    </div>
    @if (partner.enable_services_search === 1) {
      <div class="row mt-2">
        <div class="col-lg-12">
          <input
            [(ngModel)]="searchServiceQuery"
            name="searchServiceQuery"
            id="searchServiceQuery"
            type="text"
            class="form-control cbw-input-field"
            (ngModelChange)="onSearchServiceQueryChanged()"
          />
          <div>
            <small class="calio-text-muted">
              {{ 'appointments.step1.search_services_helper_text' | translate }}
            </small>
          </div>
        </div>
      </div>
    }

    <ngb-accordion #accordion="ngbAccordion" [activeIds]="activePanels">
      @for (aServiceGroup of (appoinrmentServicesByCat | categoryFilter: searchServiceQuery); track aServiceGroup; let i = $index) {
        <ngb-panel cardClass="calio-custom-accordion" id="static-{{ i }}">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between custom-accordion-header" (click)="accordion.toggle('static-' + i)">
              <div class="pe-3">
                <app-cw-multi-lang-title [isWordBreakEnabled]="false" [item]="aServiceGroup?.appointmentServiceCategory" lang="{{ lang }}" />
              </div>
              <div>
                @if (opened) {
                  <fa-icon class="icon" icon="chevron-up" />
                } @else {
                  <fa-icon class="icon" icon="chevron-down" />
                }
              </div>
            </div>
          </ng-template>

          <ng-template ngbPanelContent>
            <form class="form-group service-group" id="checkboxForm" name="appCheckboxForm" #appCheckboxForm="ngForm">
              @for (aService of aServiceGroup.aServicesOfCat; track aService; let ind = $index) {
                <div class="accordion-row d-flex">
                  <app-cw-checkbox-radio-button
                    class="w-100"
                    [customId]="aService.id"
                    name="aServices"
                    [value]="aService.name"
                    [checked]="appointmentState.services.indexOf(aService.id) > -1"
                    [isCheckbox]="partner.allow_multiple_services_bookings === 1"
                    labelClass="w-100 justify-content-between pt-1"
                    (selectionChange)="onServiceChange($event.event, $event.id, aService)"
                    [isMobile]="isMobile"
                  >
                    @if (!isMobile) {
                      <div class="service-title pe-3">
                        <div class="words-break">
                          <app-cw-multi-lang-title
                            titleClass="cw-custom-title"
                            badgeLabel="{{ 'internal_label' | translate }}"
                            [item]="aService"
                            [lang]="lang"
                            [showBadge]="aService.is_internal === 1"
                          />
                        </div>
                        @if (partner.show_inline_description === 1) {
                          @if (aService.description && aService.description.trim() != '' && aService.description != 'null' && aService.description != null) {
                            <div class="cbw-service-description pe-2">
                              {{ aService | translation: 'description': lang }}
                            </div>
                          }
                        }
                      </div>
                      <div class="cbw-service-duration">
                        <span>
                          @if ((aService.duration | replaceComma | toHour) > 0) {
                            <span>
                              {{ aService.duration | replaceComma | toHour }}{{ 'common.durationHour' | translate }}
                            </span>
                          }
                          @if ((aService.duration | replaceComma | toMinute) > 0) {
                            <span>
                              {{ aService.duration | replaceComma | toMinute}}{{ 'common.durationMinute' | translate }}
                            </span>
                          }
                        </span>
                      </div>
                      <div class="cbw-service-price">
                        @if (aService.price > 0) {
                          <div>
                            <span class="cbw-service-price">
                              @if (aService.is_price_from_activated) {
                                <span>
                                  {{ 'common.price_from_label' | translate }}
                                </span>
                              }
                              {{ aService.price | calioCurrency: partner['currency'] }}
                            </span>
                          </div>
                          @if (aService?.tax?.rate > 0) {
                            <div class="cbw-taxes">
                              {{ 'tax_info.taxes' | translate: {percentage: aService?.tax?.rate} }}
                            </div>
                          }
                        }
                        @if (aService.price === 0) {
                          {{ 'free' | translate }}
                        }
                      </div>
                      @if (partner.show_inline_description === 0 && aService.description && aService.description.trim() != '' && aService.description != 'null' && aService.description != null) {
                        <div class="cbw-service-desc">
                          @if (partner.show_inline_description === 0) {
                            @if (aService.description && aService.description.trim() != '' && aService.description != 'null' && aService.description != null) {
                              @if (aService.is_multi_language === 1) {
                                <app-cw-question-mark [id]="aService.id" [description]="aService | translation: 'description': lang" />
                              }
                              @if (aService.is_multi_language === 0) {
                                <app-cw-question-mark [id]="aService.id" [description]="aService.description" />
                              }
                            }
                          }
                        </div>
                      }
                    }

                    @if (isMobile) {
                      <div class="d-flex justify-content-between pb-2">
                        <div class="ps-3">
                          <div class="">
                            <app-cw-multi-lang-title
                              [titleClass]="'cw-custom-mobile-title'"
                              [item]="aService"
                              [lang]="lang"
                              [showBadge]="aService.is_internal === 1"
                              [badgeLabel]="'internal_label' | translate"
                              >
                            </app-cw-multi-lang-title>
                          </div>
                          @if (partner.show_inline_description === 1) {
                            @if (aService.description && aService.description.trim() != '' && aService.description != 'null' && aService.description != null) {
                              @if (aService.is_multi_language === 1) {
                                <div class="cbw-service-description pe-2"
                                  >
                                  {{ aService | translation: 'description': lang }}
                                </div>
                              }
                              @if (aService.is_multi_language === 0) {
                                <div class="cbw-service-description pe-2"
                                  >
                                  {{ aService.description }}
                                </div>
                              }
                            }
                          }
                        </div>
                      </div>
                      <div class="mobile-text-section d-flex px-3">
                        <div class="cbw-service-duration-mobile">
                          <span class="">
                            @if ((aService.duration | replaceComma | toHour) > 0) {
                              <span>
                                {{ aService.duration | replaceComma | toHour }}{{ 'common.durationHour' | translate }}
                              </span>
                            }
                            @if ((aService.duration | replaceComma | toMinute) > 0) {
                              <span>
                                {{ aService.duration | replaceComma | toMinute}}{{ 'common.durationMinute' | translate }}
                              </span>
                            }
                          </span>
                        </div>
                        @if (((aService.duration && aService.price > 0) || (aService.duration && aService.price == 0))) {
                          <div class="cbw-pipeline px-2">|</div>
                        }
                        <div class="mobile-text-section cbw-service-price-mobile">
                          @if (aService.price > 0) {
                            <div>
                              <span>
                                @if (aService.is_price_from_activated) {
                                  <span>
                                    {{ 'common.price_from_label' | translate }}
                                  </span>
                                }
                                {{ aService.price | calioCurrency: partner['currency'] }}
                              </span>
                            </div>
                          }
                          @if (aService.price === 0) {
                            {{ 'free' | translate }}
                          }
                        </div>
                      </div>
                    }
                  </app-cw-checkbox-radio-button>

                  @if ((showShortcutButton && !isMobile) || (isMobile && showShortcutButton && appointmentState.services.indexOf(aService.id) > -1)) {
                    <div class="cbw-service-shortcut-next-button">
                      @if (appointmentState.services.indexOf(aService.id) > -1) {
                        <div class="next-arrow-container" (click)="shortcutNavigateToEvent.emit()">
                          <span>
                            <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-circle-right" />
                          </span>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </form>
          </ng-template>
        </ngb-panel>
      }
    </ngb-accordion>
  </div>
} @else {
  @if (!noAppointments) {
    <app-loader [loaderType]="appointmentConstant.WORKER_LOADER" />
  }
}
