import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Params } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { DATE_ISO_FORMAT, DATE_TIME_ISO_FORMAT, TIME_FORMAT } from '../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { GTM_EVENTS, LOCAL_STORAGE_CONSTANTS, PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS, PARENT_TO_CHILD_IFRAME_EVENTS, PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME, WIDGET_CONSTANTS, WINDOW_LISTENER_CONSTANTS } from '../../../constants/widget-constants';
import { CustomFieldOptions, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { AppointmentReservationBody } from '../../../db-models/appointment-reservation.model';
import { AppointmentServiceCategoryDao, AppointmentServiceDao } from '../../../db-models/appointment-service-dao';
import { CouponDbModel } from '../../../db-models/coupon-db.model';
import { AppointmentsSlotsDao } from '../../../db-models/free-appointment-dao';
import { PayPalOrderDetailModel, PayPalSettingsDbModel, VerifyPaymentStatusModel } from '../../../db-models/partner-setting.model';
import { StoresDao } from '../../../db-models/stores-dao';
import { UserDao } from '../../../db-models/user-data-dao';
import { WidgetAppointmentGroupModel } from '../../../db-models/widget-appointment-group.dao';
import { AppointmentAnalyticsDao, CustomBookingMessageTemplate, WidgetBookingInfoDao, WorkerDao } from '../../../db-models/widget-conf-dao';
import { AppointmentBookItem, CartItem } from '../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../models/global';
import { SaferpayPaymentDetailsDbModel } from '../../../models/saferpay.model';
import { AppointmentState, ConferenceState, UserState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomerPrefillDataModel } from '../../../models/widget-conf';
import { AppointmentCartService } from '../../../services/appointment-cart.service';
import { AppointmentService } from '../../../services/appointment.service';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { PaymentService } from '../../../services/payment.service';
import { UtilService } from '../../../services/util.service';
import { WidgetUtilService } from '../../../services/widget-util.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { FinalpageComponent } from '../../../shared/components/finalpage/finalpage.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { MatIconComponent } from '../../../shared/components/mat-icon/mat-icon.component';
import { NewAlertComponent } from '../../../shared/components/new-alert/new-alert.component';
import { CalioMeetingTemplatePipe } from '../../../shared/pipes/calio-meeting-template.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { CalendarPickerComponent } from '../../common/calendar-picker/calendar-picker.component';
import { CwPaymentMethodsComponent } from '../../common/cw-payment-methods/cw-payment-methods.component';
import { PersonalDataFormComponent } from '../../common/personal-data-form/personal-data-form.component';
import { QualificationQuestionComponent } from '../../common/qualification-question/qualification-question.component';
import { CwCardHeaderComponent } from '../../common/theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../../common/theme/cw-new-card/cw-new-card.component';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { AppointmentCartOverviewComponent } from './appointment-cart-overview/appointment-cart-overview.component';
import { AppointmentCustomerProfilesComponent } from './appointment-customer-profiles/appointment-customer-profiles.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { AppointmentNavigationBarComponent } from './appointment-navigation-bar/appointment-navigation-bar.component';
import { AppointmentWorkerCardComponent } from './appointment-worker-card/appointment-worker-card.component';

const components = [QualificationQuestionComponent, FinalpageComponent, ButtonComponent, LoaderComponent, AlertComponent, AppointmentNavigationBarComponent, NewAlertComponent, AppointmentCustomerProfilesComponent, AppointmentListComponent, AppointmentWorkerCardComponent, CalendarPickerComponent, AppointmentCardComponent, CwNewCardComponent, CwCardHeaderComponent, PersonalDataFormComponent, CwPaymentMethodsComponent, AppointmentCartOverviewComponent, MatIconComponent];
const modules = [FormsModule, TranslateModule, NgSelectModule];
const pipes = [DatePipe, TranslationPipe, TrustHtmlPipe, CalioMeetingTemplatePipe];
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, ...modules, ...components, ...pipes]
})
export class AppointmentComponent implements OnInit, OnDestroy {

  @Input() partner: Partner;
  @Input() globals: GlobalObjects;
  @Input() isClicked: boolean;
  @Input() lang: string;
  @Input() isFrame: boolean;
  @Input() widgetBookingInfo: WidgetBookingInfoDao;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() widgetConf: any;
  @Input() selectedStoreId: number;
  @Input() selectedStoreZipCode: number;
  @Input() selectedStoreName: string;
  @Input() selectedWorkerId: number;
  @Input() selectedAppointmentServiceIds: string[];
  @Input() selectedAppointmentServiceCategoryIds: string[];
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() selectedJsonCustomerDetail: CustomerPrefillDataModel;
  @Input() calendarPreselectedDate: string;
  @Input() dateContext: string;
  @Input() dateContextStart: string;
  @Input() dateContextEnd: string;
  @Input() bookerWorkerId: number;
  @Input() utmSource: string;
  @Input() utmMedium: string;
  @Input() utmCampaign: string;
  @Input() utmContent: string;
  @Input() utmTerm: string;
  @Input() meeting_type_id: string;
  @Input() customer_notification_preference: string;
  @Input() isBookingDisabled = false;
  @Input() foundedWorker: WorkerDao;
  @Input() internalComment: string;
  @Input() langSwitcher = true;
  @Input() conferenceState: ConferenceState;
  @Input() debug: string;
  @Input() token: string;
  @Input() hideStoreSelection = false;

  @Output() bookingSuccessEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('appointmentsSection') appointmentsSectionRef: ElementRef;
  @ViewChild('appointmentForm') appointmentForm: NgForm;

  private appointmentService = inject(AppointmentService);
  private cartService = inject(AppointmentCartService);
  private cdr = inject(ChangeDetectorRef);
  private customEventService = inject(CustomEventService);
  private deviceDetectorService = inject(DeviceDetectorService);
  private elementRef = inject(ElementRef);
  private formService = inject(FormsService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private helperService = inject(HelperService);
  private localStorageService = inject(LocalStorageService);
  private partnerService = inject(PartnerService);
  private paymentService = inject(PaymentService);
  private translateService = inject(TranslateService);
  private utilService = inject(UtilService);
  private widgetUtilService = inject(WidgetUtilService);

  protected readonly parentToChildIframeEventActions = PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS;
  protected readonly parentToChildIframeEvents = PARENT_TO_CHILD_IFRAME_EVENTS;
  protected readonly parentToChildIframeValidationControlName = PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME;
  protected readonly dateIsoFormat = DATE_ISO_FORMAT;
  protected readonly dateTimeIsoFormat = DATE_TIME_ISO_FORMAT;
  protected readonly timeFormat = TIME_FORMAT;
  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;
  protected readonly widgetConstant = WIDGET_CONSTANTS;
  protected readonly environment = environment;
  protected readonly svgIcons = [
    { name: 'person-f', path: 'assets/images/material/person_fillded.svg' },
    { name: 'event-f', path: 'assets/images/material/event_filled.svg' },
    { name: 'edit', path: 'assets/images/material/edit.svg' },
  ];
  readonly slotGhostElementsCount = new Array<number>(4);

  protected viewMode = WIDGET_CONSTANTS.APPOINTMENT;
  cartLength: number;
  customerSelected: object = null;
  stores: StoresDao[] = [];
  keepWorkerId = 0;
  isMobile: boolean;
  disableBookingButton = false;
  booked = false; // Call final page with message if true
  duration: number;
  formReseted = false; // Do we show message that form is reseted
  appoinrmentServicesAvailable: AppointmentServiceDao[];
  appoinrmentServicesByCat: {
    category: string;
    _translations: any;
    is_multi_language: number;
    appointmentServiceCategory: AppointmentServiceCategoryDao;
    aServicesOfCat: AppointmentServiceDao[];
    position: number;
  }[];
  workerAvatarUrl = environment.workerAvatarUrl;
  baseUrl = environment.baseUrl;
  workersIdsPerService: Map<number, number[]> = new Map<number, number[]>();
  allWorkerObjects: Map<number, UserDao> = new Map<number, UserDao>();
  workersAvailable: UserDao[] = [];
  workersAvailableWithAnyoneOption: UserDao[] = [];
  calendarLoaded = false;
  viewDate: Date;
  old_select_calendar: CalendarMonthViewDay = null;
  disablePrev = true;
  days: CalendarMonthViewDay[] = [];
  now = new Date();
  monthChanged = false;
  lastCalendarMonth: number;
  freeAppointments: {
    dayPeriod: string;
    appointments: AppointmentsSlotsDao[];
    showDayPeriodLabel?: boolean;
    widgetGroup: WidgetAppointmentGroupModel;
  }[] = [];
  freeAppointmentsCount = 0;
  lastSelectedappointment: AppointmentsSlotsDao;
  showAds = false;
  cart: CartItem[] = [];
  noServices = false;
  noWorkers = false;
  noAppointments = false;
  appointmentCustomFields: CustomFieldsDao[] = [];
  tempArray: CustomFieldsDao[] = [];
  customFieldValues: {
    [key: number]:
    | {
      type: string;
      value: any;
      option_values: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }
    | any;
  } = {};
  qualificationQuestionsValues: {
    [key: number]:
    | {
      type: string;
      value: any;
      option_values: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }
    | any;
  } = {};
  finalPage: boolean;
  finalPageError: string;
  showEgal: boolean;
  totalCartDuration = 0;
  totalCartPrice = 0;
  alive: boolean;
  courtesyForm: string;
  leadTime: number;
  successMessage: string;
  errorMessage: string;
  priceAfterCouponReduction: number;
  widgetTemplates: CustomBookingMessageTemplate[] = [];
  successFullBookings: {
    landingPageLink: string;
    bookingUUID: string;
    selectedStore: StoresDao;
  }[] = [];
  CUSTOMFIELDS_LS_NAME: string;
  QUALIFICATIONQUESTION_LS_NAME: string;
  appointmentState: AppointmentState = {
    customerProfileId: null,
    store: null,
    categories: [],
    services: [],
    relatedServices: [],
    worker: null,
    date: null,
    termins: null,
    day: null,
    default_worker_id: null,
    leadGeneratorPostcode: null,
    default_worker: null
  };
  userState: UserState = {
    title: null,
    forename: null,
    lastName: null,
    gender: null,
    eMail: null,
    phone: null,
    mobile: null,
    comment: null,
    customerId: null,
    customerUuid: null,
    street: null,
    zip: null,
    city: null,
    country_id: null,
    country: null,
    customer_notification_preference: null,
    meeting_type_id: null,
    company: null,
    internal_comment: null,
  };
  brokerState: UserState = {
    title: null,
    forename: null,
    lastName: null,
    gender: null,
    eMail: null,
    phone: null,
    mobile: null,
    comment: null,
    customerId: null,
    customerUuid: null,
    street: null,
    zip: null,
    city: null,
    country_id: null,
    country: null,
    customer_notification_preference: null,
    meeting_type_id: null,
    company: null,
    internal_comment: null,
  };
  isServicesTabDisabled = false;
  paypalSetting: PayPalSettingsDbModel;
  stripePaymentMethodId: string;
  calendarSlotsCountList: { date: Date; slotsCount: number }[] = [];
  coupon: CouponDbModel;
  finalCouponDiscount = 0;
  disableDatePagePreviousButton = false;
  paymentType: string;
  couponDisableConfirmButton = false;
  bookingErrorMessage: string | string[];
  selectedStoreAvailable = false;
  isStripeEnabled = false;
  isServiceOnlinePaymentEnabled = 0;
  isOnlinePaymentMandatory = false;
  isBexioEnabled = false;
  isStoreEnabled = false;
  isLexOfficePaymentTypeEnabled = false;
  isPaypalEnabled = false;
  isSaferpayEnabled = false;
  nextButtonTemplate: CustomBookingMessageTemplate;
  previousButtonTemplate: CustomBookingMessageTemplate;
  bookButtonTemplate: CustomBookingMessageTemplate;
  dateLabelTemplate: CustomBookingMessageTemplate;
  noBookableDatesTemplate: CustomBookingMessageTemplate;
  yesSearchButtonTemplate: CustomBookingMessageTemplate;
  manageAppointmentNowTemplate: CustomBookingMessageTemplate;
  downloadBookingDetailTemplate: CustomBookingMessageTemplate;
  selectDayInCalendarMsgTemplate: CustomBookingMessageTemplate;
  widgetAnyoneLabelTemplate: CustomBookingMessageTemplate;
  widgetAnyoneDescTemplate: CustomBookingMessageTemplate;
  summaryPagePersonalInfoHeading: CustomBookingMessageTemplate;
  summaryPageCartDetailsHeading: CustomBookingMessageTemplate;
  showBookableDateNotAvailableIssue = false;
  automaticBookableDateProcessIsEnabled = false;
  automationBookingDatesLimitReached = false;
  selectedWorker: UserDao;
  hideResourceStep = false;
  widgetGroups: WidgetAppointmentGroupModel[] = [];
  childCustomFieldsLoadedSubscription: Subscription;
  removeCustomFieldsSubscription: Subscription;
  /**
   * 0 ==> don't show anything
   * 1 ==> show ghost element
   * 2 ==> show appointments
   * */
  showAppointmentSlotGhostElement = 0;
  appointmentSlotGhostElements: number[] = [1, 2, 3, 4, 5, 6];
  paypalOrderDetail: PayPalOrderDetailModel;
  saferpayPaymentDetails: SaferpayPaymentDetailsDbModel;
  isInvalidCoupon = false;
  hideBookingButton = false;
  isCustomFieldsLoaded = false;
  private STATE_LS_NAME;
  private CART_LS_NAME;
  USER_LS_NAME;
  CALENDAR_SLOTS_COUNT_LS_NAME;
  isUserMadeFirstInteraction = false;
  showSaferpayButtonLink = false;
  showFullscreenOverlay = false;
  showWorkerIsNotBookableError = false;
  calendarDate = false;
  backButtonClicked = false;
  hardCodedSelectedAppointmentServiceIds: string[];
  hardCodedSelectedAppointmentServiceCategoryIds: string[];
  qualificationQuestions: CustomFieldsDao[] = [];
  hasQualificationQuestions = false;
  hasQualificationQuestionsAnswered = false;
  availableSlotsArray: AppointmentsSlotsDao[] = [];
  showAppointmentSlots = false;
  getDaysWiseFreeSlots$: any;
  showWorkersListOnDateStep = false;
  summaryDetails: Params;
  showSummaryDetails = false;

  constructor() {
    this.viewDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastCalendarMonth = this.viewDate.getMonth() + 1;
    this.updateCalendarPrevState(this.viewDate);
    this.alive = true;

    this.translateService.onLangChange.subscribe(language => {
      this.lang = language.lang;
      this.createSuccessMessage(language.lang);
      this.createErrorMessage();
    });

    this.helperService.registerSvgIconsList(this.svgIcons);
  }

  ngOnInit() {
    // Check does partner has qualification  questions only if service is not predefined
    if (!this.selectedAppointmentServiceIds) {
      this.hasPartnerQualificationQuestions();
    }


    // Keeping backup of selectedAppointmentServiceIds to use it while pre-selecting appointment service on store change
    this.hardCodedSelectedAppointmentServiceIds = this.selectedAppointmentServiceIds;
    this.hardCodedSelectedAppointmentServiceCategoryIds = this.selectedAppointmentServiceCategoryIds;

    // Filtering and validating variables specially for webc when attirbute is added as "" so ideally it should not be considered
    // but it is being considered as "" so causing issue in comparisons.
    this.selectedWorkerId = this.selectedWorkerId?.toString() === '' ? undefined : Number(this.selectedWorkerId);
    this.selectedStoreZipCode = this.selectedWorkerId?.toString() === '' ? undefined : Number(this.selectedStoreZipCode);
    this.bookerWorkerId = this.selectedWorkerId?.toString() === '' ? undefined : Number(this.bookerWorkerId);

    this.isMobile = this.deviceDetectorService.isMobile();

    // Set local storage name for form state
    this.setLocalstorageNames();
    this.initFormState();
    this.showEgal = this.partner.showEgalWer;

    if (this.widgetUtilService.getWidgetConf().subscriptionTypeId === 1) {
      this.showAds = true;
    }
    this.courtesyForm = this.widgetUtilService.getWidgetConf().courtesyForm;
    this.leadTime = this.widgetUtilService.getWidgetConf().appointmentLeadTime;
    this.getWidgetTemplates();

    this.customEventService.hideBookingButtonEvent.subscribe(result => this.hideBookingButton = result.hide);

    if (this.partner?.is_customer_profiles_feature_enabled !== 1) {
      this.fetchAvailableStores();
    } else {
      if (this.partner?.customer_profiles?.length === 1) {
        this.appointmentState.customerProfileId =
          this.partner?.customer_profiles[0].id;
        this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
      } else {
        this.viewMode = APPOINTMENT_CONSTANT.CUSTOMER_TYPE;
      }
    }

    this.childCustomFieldsLoadedSubscription =
      this.customEventService.childCustomFieldsLoadedEvent.subscribe({
        next: (result: CustomFieldsDao[]) => {
          this.tempArray = this.tempArray.concat(result);
        }
      });

    this.removeCustomFieldsSubscription =
      this.customEventService.removeChildCustomFieldsEvent.subscribe({
        next: (ids: number[]) => {
          this.tempArray = this.tempArray.filter((item: CustomFieldsDao) => {
            return ids.indexOf(item.id) === -1;
          });
        }
      });

    this.listenFromParentiFrame();
  }

  private hasPartnerQualificationQuestions(): void {
    this.formService.getPartnerQualificationQuestions().subscribe({
      next: (questions) => {
        if (questions?.length) {
          this.resetQualificationQuestionValues(questions);
          this.qualificationQuestions = questions;
          LoggerService.log('[Qualification questions] Yes, partner has qualification questions');
          this.hasQualificationQuestions = true;
        } else {
          this.qualificationQuestions = [];
          this.hasQualificationQuestions = false;
        }
        this.hasQualificationQuestionsAnswered = false;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  listenFromParentiFrame(): void {
    window.addEventListener('message', (event: any) => {
      if (document.referrer) {
        const referrer = new URL(document.referrer);
        if (
          [`${referrer.protocol}//${referrer.hostname}`, referrer.origin].includes(event.origin)
        ) {
          if (
            event.data?.action &&
            event.data?.eventName &&
            event.data.eventName === this.parentToChildIframeEvents.NAVIGATION
          ) {
            LoggerService.log('[message received from parent to child for navigation]', event?.data);
            this.handleParentToChildNavigation(event);
          }
        } else {
          LoggerService.warn('[Receiving message from non-whitelisted origin]', event.origin, referrer.origin);
        }
      }
    });
  }

  handleParentToChildNavigation(event: any): void {
    if (Object.values(this.parentToChildIframeEventActions).includes(event.data.action)) {
      switch (this.viewMode) {
        case this.appointmentConstant.DATE:
          if (event.data.action === this.parentToChildIframeEventActions.PREVIOUS && !this.disableDatePagePreviousButton) {
            this.backButtonClicked = true;
            this.navigateTo(this.appointmentConstant.WORKER);
          } else if (event.data.action === this.parentToChildIframeEventActions.NEXT) {
            if (this.cart.length > 0) {
              this.navigateTo(this.appointmentConstant.PERSONAL_INFO);
            } else if (this.appointmentState.date === null) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [this.parentToChildIframeValidationControlName.DATE, this.parentToChildIframeValidationControlName.SLOT]
              });
            } else if (this.cart === null || this.cart?.length === 0) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [this.parentToChildIframeValidationControlName.SLOT]
              });
            }
          }
          break;
        case this.appointmentConstant.PERSONAL_INFO:
          if (event.data.action === this.parentToChildIframeEventActions.PREVIOUS) {
            this.navigateTo(this.appointmentConstant.DATE);
          } else if (
            event.data.action === this.parentToChildIframeEventActions.BOOK
          ) {
            if (!this.isValidBooking()) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [this.parentToChildIframeValidationControlName.PERSONAL_FORM]
              });
            } else if (!this.hideBookingButton) {
              if (this.showSaferpayButtonLink) {
                this.payUsingSaferpay();
              } else if (!this.disableBookingButton) {
                this.book();
              }
            }
          }
          break;
        default:
          this.customEventService.iFrameNavigationFromParent.emit(event.data.action);
          break;
      }
    }
  }

  setupInitialTemplates(): void {
    this.nextButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NEXT);
    this.nextButtonTemplate && (this.nextButtonTemplate.is_multi_language = 1);

    this.previousButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_PREVIOUS);
    this.previousButtonTemplate && (this.previousButtonTemplate.is_multi_language = 1);

    this.bookButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_BOOK_BUTTON);
    this.bookButtonTemplate && (this.bookButtonTemplate.is_multi_language = 1);

    this.dateLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_DATE_LABEL);
    this.dateLabelTemplate && (this.dateLabelTemplate.is_multi_language = 1);

    this.noBookableDatesTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NO_BOOKABLE_DATES_LABEL);
    this.noBookableDatesTemplate && (this.noBookableDatesTemplate.is_multi_language = 1);

    this.yesSearchButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_YES_SEARCH_BUTTON_LABEL);
    this.yesSearchButtonTemplate && (this.yesSearchButtonTemplate.is_multi_language = 1);

    this.manageAppointmentNowTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_MANAGE_BOOKING_LABEL);
    this.manageAppointmentNowTemplate && (this.manageAppointmentNowTemplate.is_multi_language = 1);

    this.downloadBookingDetailTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_DOWNLOAD_APPOINTMENT_ICS_FILE);
    this.downloadBookingDetailTemplate && (this.downloadBookingDetailTemplate.is_multi_language = 1);

    this.selectDayInCalendarMsgTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_CHOOSE_DATE_HINT);
    this.selectDayInCalendarMsgTemplate && (this.selectDayInCalendarMsgTemplate.is_multi_language = 1);

    this.widgetAnyoneLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_ANYONE_LABEL);
    this.widgetAnyoneLabelTemplate && (this.widgetAnyoneLabelTemplate.is_multi_language = 1);

    this.widgetAnyoneDescTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_ANYONE_DESC_LABEL);
    this.widgetAnyoneDescTemplate && (this.widgetAnyoneDescTemplate.is_multi_language = 1);

    this.summaryPagePersonalInfoHeading = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.SUMMARY_PAGE_PERSONAL_INFO_HEADING
    );
    this.summaryPagePersonalInfoHeading && (this.summaryPagePersonalInfoHeading.is_multi_language = 1);

    this.summaryPageCartDetailsHeading = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.SUMMARY_PAGE_CART_DETAILS_HEADING
    );
    this.summaryPageCartDetailsHeading && (this.summaryPageCartDetailsHeading.is_multi_language = 1);
  }

  createSuccessMessage(lang: string): void {
    const appointmentBookingInfoTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_BOOKING_INFO_APPOINTMENT);
    appointmentBookingInfoTemplate && (appointmentBookingInfoTemplate.is_multi_language = 1);
    this.successMessage = this.utilService.getBookingInfoMessage(appointmentBookingInfoTemplate, lang);

    const translateConstant = (this.courtesyForm === 'Du' ? 'appointments.finalpage.successMessageDu' : 'appointments.finalpage.successMessageSie');

    if (!this.successMessage) {
      this.translateService.get(translateConstant, { leadTime: this.leadTime }).subscribe(res => {
        this.successMessage = res;
        this.customEventService.finalSuccessMessageChangedEvent.emit(this.successMessage);
      });
    } else {
      this.customEventService.finalSuccessMessageChangedEvent.emit(this.successMessage);
    }
  }

  createErrorMessage(): void {
    if (this.courtesyForm === 'Du') {
      this.errorMessage = this.translateService.instant('appointments.finalpage.errorMessageDu')
    } else {
      this.errorMessage = this.translateService.instant('appointments.finalpage.errorMessageSie')
    }
  }

  ngOnDestroy() {
    // unsubscribe from all subscriptions
    this.alive = false;
    if (this.childCustomFieldsLoadedSubscription) {
      this.childCustomFieldsLoadedSubscription.unsubscribe();
    }
    if (this.removeCustomFieldsSubscription) {
      this.removeCustomFieldsSubscription.unsubscribe();
    }
  }

  // Set local storage name for form state
  setLocalstorageNames(): void {
    this.STATE_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_STATE_LS + this.partner.bookingName;
    this.CART_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_CART_LS + this.partner.bookingName;
    this.USER_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_GLOBAL_USER_LS + this.partner.bookingName;
    this.CUSTOMFIELDS_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_CUSTOMFIELDS_LS_NAME + this.partner.bookingName;
    this.QUALIFICATIONQUESTION_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_QUALIFICATIONQUESTION_LS_NAME + this.partner.bookingName;
    this.CALENDAR_SLOTS_COUNT_LS_NAME = APPOINTMENT_CONSTANT.APPOINTMENT_CALENDAR_SLOTS_COUNT_LS_NAME + this.partner.bookingName;
  }

  // Fetch available stores
  fetchAvailableStores(): void {
    if (!this.appointmentState.store) {
      if (this.selectedStoreId) {
        this.appointmentState.store = this.selectedStoreId;
      }
    }

    if (
      ((this.partner.enable_store_postcode_feature === 1 && this.widgetConf?.show_stores_as_dropdown === 1) ||
        this.partner.automatic_store_zip_search === 1) &&
      !this.appointmentState.store &&
      ((this.globals?.isInternal && this.partner?.internal_widget_store_search_list === 0) || !this.globals?.isInternal)
    ) {
      this.stores = [];
      this.appoinrmentServicesByCat = [];
    } else {
      let appointmentServiceId: number;
      if (
        this.selectedAppointmentServiceIds &&
        this.selectedAppointmentServiceIds.length === 1
      ) {
        appointmentServiceId = Number(this.selectedAppointmentServiceIds[0]);
      } else if (
        this.selectedAppointmentServiceIds &&
        this.selectedAppointmentServiceIds.length > 1
      ) {
        appointmentServiceId = Number(this.selectedAppointmentServiceIds);
      }

      const storeParams: {
        appointment_service_ids: number,
        worker_id?: number
      } = {
        appointment_service_ids: appointmentServiceId,
      };

      if (this.selectedWorkerId !== undefined) {
        storeParams.worker_id = Number(this.selectedWorkerId);
      }

      this.formService.getStores(storeParams).pipe(takeWhile(() => this.alive)).subscribe({
        next: stores => {
          if (stores?.length) {
            if (!this.globals.isInternal) {
              this.stores = stores.filter(store => store.has_only_internal_appointment_service === 0);
            } else {
              this.stores = stores;
            }
          } else {
            this.stores = [];
            if (storeParams.worker_id !== undefined) {
              this.showWorkerIsNotBookableError = true;
            }
          }
          this.setupStoreSelection(this.stores);
        },
        error: (err) => LoggerService.error(err.message)
      });
    }
  }

  setupStoreSelection(stores: StoresDao[]): void {
    if (this.selectedStoreId) {
      const selectedStore = stores.find(store => Number(store.id) === Number(this.selectedStoreId));

      if (selectedStore) {
        this.selectedStoreAvailable = true;
        this.appointmentState.store = selectedStore.id;
        LoggerService.log(
          `CALENSO_BOOKING_WIDGET_INFO_2: Setting store to ${selectedStore.id} because store was preselected using ID.`
        );
      } else {
        LoggerService.warn(
          'CALENSO_BOOKING_WIDGET_ERROR_2: Selected store ID cannot be found in the list of stores. Ignoring the selected store ID.'
        );
        this.selectedStoreAvailable = false;
      }
    } else if (this.selectedStoreZipCode) {
      const zipStore: StoresDao = stores.find((store: StoresDao) => {
        return ((Number(store.zip) === Number(this.selectedStoreZipCode)) ? true : false);
      });
      if (zipStore) {
        this.selectedStoreAvailable = true;
        this.selectedStoreId = zipStore.id;
        this.appointmentState.store = zipStore.id;
        LoggerService.log(`CALENSO_BOOKING_WIDGET_INFO_2: Setting store to ${zipStore.id} because store was preselected using store zip.`);
      } else {
        LoggerService.warn('CALENSO_BOOKING_WIDGET_ERROR_2: Selected store ZIP cannot be found in the list of stores. Ignoring the selected store ZIP.');
        this.selectedStoreAvailable = false;
      }
    } else if (this.selectedStoreName) {
      let selectedStore = stores.find(store => {
        if (store.name && this.selectedStoreName) {

          if (store.name.toLowerCase() === this.selectedStoreName.toLowerCase()) {
            return true;
          }

          if (store.is_multi_language === 1) {
            this.widgetConstant.LANGUAGES.forEach(language => {
              if (store?._translations[language.locale].name?.toLowerCase() === this.selectedStoreName.toLowerCase()) {
                return true;
              }
            });
            return false;
          }
        }
        return false;
      });

      if (this.foundedWorker && selectedStore) {
        if (this.foundedWorker.store_id !== selectedStore.id) {
          LoggerService.warn('CALENSO_BOOKING_WIDGET_INFO_1: Selected store name and Worker store id is not matching. Resetting store id to worker store id.'
          );
          selectedStore = stores.find((store: StoresDao) => {
            return Number(store.id) === Number(this.foundedWorker.store_id);
          });
        }
      }

      if (selectedStore) {
        this.selectedStoreAvailable = true;
        this.selectedStoreId = selectedStore.id;
        this.appointmentState.store = selectedStore.id;
        LoggerService.log(`CALENSO_BOOKING_WIDGET_INFO_2: Setting store to ${selectedStore.id} because store was preselected using store name.`);
      } else {
        LoggerService.warn('CALENSO_BOOKING_WIDGET_ERROR_2: Selected store Name cannot be found in the list of stores. Ignoring the selected store Name.');
        this.selectedStoreAvailable = false;
      }
    } else {
      this.selectedStoreAvailable = false;
    }

    // Set selected to first element if not set by local storage
    if (this.appointmentState.store == null) {
      if (
        this.partner.enable_store_postcode_feature !== 1 ||
        (this.partner.enable_store_postcode_feature === 1 && !this.globals.isInternal)
      ) {
        const conditionBlock3 = (
          stores?.length > 1 &&
          (this.globals?.isInternal && this.partner?.internal_widget_store_search_list === 1)
        ) || (
            stores?.length > 1 &&
            !this.globals?.isInternal &&
            this.partner?.enable_store_postcode_feature !== 1 &&
            this.partner?.automatic_store_zip_search !== 1 &&
            this.widgetConf?.show_stores_as_dropdown === 1
          );

        if (
          !conditionBlock3 &&
          this.partner.enable_store_postcode_feature !== 1
        ) {
          this.appointmentState.store = stores[0]['id'];
        }

        LoggerService.log(`CALENSO_BOOKING_WIDGET_INFO_3: Setting store to ${stores[0]['id']} because store is not preselected yet.`);
      }

      LoggerService.log(`CALENSO_BOOKING_WIDGET_INFO_4: this.appointmentState.store `, this.appointmentState.store);
    }

    if (this.appointmentState.store) {
      this.onStoreSelected(this.appointmentState.store);
    } else {
      if (!this.hasQualificationQuestions) {
        this.appoinrmentServicesByCat = [];
        this.appointmentState.services = [];
      }
    }
  }

  bookAppointmentAgain() {
    this.hasPartnerQualificationQuestions();
    this.appointmentService.gtmStartTriggered = false;
    this.bookingErrorMessage = undefined;
    this.successFullBookings = [];
    this.initFormState();
    if (this.stores.length) {
      this.setupStoreSelection(this.stores);
    } else {
      this.fetchAvailableStores();
    }
    if (this.partner.is_customer_profiles_feature_enabled === 1) {
      if (this.partner?.customer_profiles?.length === 1) {
        this.appointmentState.customerProfileId =
          this.partner?.customer_profiles[0].id;
        this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
      } else {
        this.viewMode = APPOINTMENT_CONSTANT.CUSTOMER_TYPE;
      }
    } else {
      this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
    }
  }

  onStoreSelected(store_id: number): void {
    this.appoinrmentServicesByCat = [];
    if (
      this.appointmentState?.services?.length > 0 &&
      (this.partner.enable_store_postcode_feature === 1 ||
        this.partner.automatic_store_zip_search === 1) &&
      ((this.globals?.isInternal &&
        this.partner?.internal_widget_store_search_list === 0) ||
        !this.globals?.isInternal)
    ) {
      // Don't reset appointment state services
    } else {
      if (!this.hasQualificationQuestions) {
        this.appointmentState.services = [];
      }
    }

    this.allWorkerObjects = new Map<number, UserDao>();
    this.getWidgetAppointmentGroups(store_id);
    this.setAppointmentServices();

    if (Number(this.partner.is_round_robin_feature_enabled) === 1) {
      LoggerService.warn('[Debug] Hiding resource step because of is_round_robin_feature_enabled setting');
      this.hideResourceStep = true;
    } else if (
      Number(this.partner.widget_hide_resource_step_for_default_workers) === 1 &&
      this.appointmentState.default_worker_id
    ) {
      LoggerService.warn('[Debug] Hiding resource step because of widget_hide_resource_step_for_default_workers setting');
      this.hideResourceStep = true;
    } else if (Number(this.partner.is_widget_postcode_worker_random_selection_enabled) === 1) {
      LoggerService.warn('[Debug] Hiding resource step because of is_widget_postcode_worker_random_selection_enabled setting');
      this.hideResourceStep = true;
    } else {
      this.hideResourceStep = false;
    }
  }

  resetSelectedAppointmentServiceIds(): void {
    this.selectedAppointmentServiceIds = this.hardCodedSelectedAppointmentServiceIds;
  }

  resetSelectedAppointmentServiceCategoryIds(): void {
    this.selectedAppointmentServiceCategoryIds = this.hardCodedSelectedAppointmentServiceCategoryIds;
  }

  // Set appointment services based on store ID
  setAppointmentServices(): void {
    this.noServices = false;
    this.noWorkers = false;
    if (!this.appointmentState.store) {
      return;
    }

    const payload = {
      store_id: this.appointmentState.store,
      worker_id: Number(this.selectedWorkerId),
      appointment_services_uuids: this.appointmentService.appointment_services_uuids
    };
    this.formService.getAppointmentServices(payload)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (aServices) => {
          if (!aServices) {
            aServices = [];
          }

          this.resetSelectedAppointmentServiceIds();
          this.resetSelectedAppointmentServiceCategoryIds();

          if (!this.globals.isInternal) {
            aServices = aServices.filter(service => service.is_internal !== 1);
          }

          if (this.conferenceState?.uuid) {
            this.hideResourceStep = true;
          }

          const categorizedMap = new Map<number, AppointmentServiceDao[]>();
          const categorizedAService: {
            category: string;
            _translations: any;
            is_multi_language: number;
            aServicesOfCat: AppointmentServiceDao[];
            appointmentServiceCategory: AppointmentServiceCategoryDao;
            position: number;
          }[] = [];
          let serviceWorkersArray: number[];
          this.appoinrmentServicesAvailable = aServices;
          for (const aService of aServices) {
            categorizedMap.set(aService.appointment_service_category.id, []);
            serviceWorkersArray = [];
            for (const services_worker of aService.services_workers) {
              const workerId = services_worker.worker_id;
              serviceWorkersArray.push(workerId);
              // Add worker in all workers array if not exist AND belongs to curent store
              if (
                services_worker.worker != null &&
                !(workerId in this.allWorkerObjects) &&
                services_worker.worker.store_id === this.appointmentState.store &&
                services_worker.worker.bookable
              ) {
                this.allWorkerObjects.set(workerId, services_worker.worker);
              }
            }
            // Set values in workers per service map
            this.workersIdsPerService.set(aService.id, serviceWorkersArray);
          }

          for (const aService of aServices) {
            // Set values for categorized a services
            categorizedMap
              .get(aService.appointment_service_category.id)
              .push(aService);
          }

          categorizedMap.forEach(
            (aServicesOfCat: AppointmentServiceDao[], id: number) => {
              if (aServicesOfCat && aServicesOfCat.length > 0) {
                const appointmentServiceTemp: AppointmentServiceDao =
                  aServicesOfCat[0];
                if (appointmentServiceTemp.is_multi_language === 0) {
                  categorizedAService.push({
                    category:
                      appointmentServiceTemp.appointment_service_category.name,
                    aServicesOfCat: aServicesOfCat,
                    _translations: [],
                    is_multi_language: 0,
                    position:
                      appointmentServiceTemp.appointment_service_category
                        .position,
                    appointmentServiceCategory:
                      appointmentServiceTemp.appointment_service_category,
                  });
                } else {
                  categorizedAService.push({
                    category:
                      appointmentServiceTemp.appointment_service_category.name,
                    aServicesOfCat: aServicesOfCat,
                    _translations: appointmentServiceTemp._translations,
                    is_multi_language: 1,
                    position:
                      appointmentServiceTemp.appointment_service_category
                        .position,
                    appointmentServiceCategory:
                      appointmentServiceTemp.appointment_service_category,
                  });
                }
              }
            }
          );

          this.appoinrmentServicesByCat = this.helperService.sortByNumber(
            categorizedAService,
            'position'
          );

          // preselect services
          const unavailableServiceIds: string[] = [];
          const unavailableAppointmentServiceCategoryIds: string[] = [];

          if (this.selectedAppointmentServiceIds?.length > 0) {
            for (const id of this.selectedAppointmentServiceIds) {
              const selectedService = aServices.find(aService => Number(aService.id) === Number(id));
              if (selectedService) {
                this.appointmentState.services.push(selectedService.id);
              } else {
                LoggerService.warn(`selected service id is not available. Removing ${id} from the selected services array.`);
                unavailableServiceIds.push(id);
              }
            }

            this.selectedAppointmentServiceIds =
              this.selectedAppointmentServiceIds.filter((item: string) => {
                return unavailableServiceIds.indexOf(item) === -1;
              });

            if (this.selectedAppointmentServiceIds?.length == 0) {
              this.selectedAppointmentServiceIds = [aServices[0].id + ''];
              this.appointmentState.services.push(aServices[0].id);
              LoggerService.warn(`hard coded service id is not available. So pre-selecting first service.`);
            }
          }

          if (this.selectedAppointmentServiceCategoryIds?.length > 0) {
            for (const id of this.selectedAppointmentServiceCategoryIds) {
              if (id) {
                if (!this.appointmentState.categories) {
                  this.appointmentState.categories = [];
                }
                this.appointmentState.categories.push(Number(id));
              } else {
                LoggerService.warn(
                  `selected service id is not available. Removing ${id} from the selected services array.`
                );
                unavailableAppointmentServiceCategoryIds.push(id);
              }
            }

            this.selectedAppointmentServiceCategoryIds = this.selectedAppointmentServiceCategoryIds.filter((item: string) => {
              return unavailableAppointmentServiceCategoryIds.indexOf(item) === -1;
            });
          }

          this.afterAppointmentServiceToggled();

          if (this.hasQualificationQuestions && this.hasQualificationQuestionsAnswered) {
            this.appointmentService.resetAppointmentCategoryWiseListEvent.emit();
          }

          if (this.appointmentState.services.length) {
            this.updateAvailableWorkers();
          } else {
            this.noServices = true;
          }

          // If no bookable workers available clear local storage
          if (!this.allWorkerObjects.size) {
            this.resetForm();
            this.noWorkers = true;
            this.formReseted = false;
          }

          // Preselecting worker
          if (
            (this.selectedWorkerId || Number(this.selectedWorkerId) === 0) &&
            this.selectedAppointmentServiceIds?.length > 0
          ) {
            LoggerService.log('[Debug] WorkerID and ServiceID both are present so pre-selecting a worker');
            this.preselectWorker();
          }

          this.customEventService.servicesLoadedEvent.emit();
        },
        error: (error: HttpErrorResponse) => {
          this.customEventService.servicesLoadedEvent.emit();
          LoggerService.error(error);
        }
      });
  }

  preselectWorker() {
    // identify whether action is automatic or manual
    let isFirstTime = true;
    if (this.appointmentState?.date !== null) {
      isFirstTime = false;
    }

    if (this.workersAvailable.length === 0) {
      this.appointmentState.worker = 0;
      this.onWorkerSelected();
      this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
    } else if (this.workersAvailable.length === 1) {
      this.partner.hide_resource_step = 1;
      this.appointmentState.worker = this.workersAvailable[0]['id'];
      this.onWorkerSelected();
      if (this.backButtonClicked) {
        this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
      } else {
        this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
      }
    } else {
      if (this.partner.is_round_robin_feature_enabled === 1) {
        LoggerService.warn('[Debug] Selecting random worker according to the round robin');
        if (this.workersAvailable.length > 0) {
          const worker: any = this.helperService.get_random(
            this.workersAvailable
          );
          this.selectWorker(worker.id);
          this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
        } else {
          this.selectWorker(0);
          this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
        }
      } else if (this.selectedWorkerId) {
        const selectedWorker: UserDao = this.workersAvailable.find(
          (worker: UserDao) => {
            return Number(worker.id) === Number(this.selectedWorkerId);
          }
        );

        if (selectedWorker) {
          this.partner.hide_resource_step = 1;
          this.appointmentState.worker = selectedWorker.id;
          this.onWorkerSelected();
          this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
        }
      } else if (Number(this.selectedWorkerId) === 0) {
        this.partner.hide_resource_step = 1;
        this.appointmentState.worker = 0;
        this.onWorkerSelected();
        this.navigateTo(APPOINTMENT_CONSTANT.DATE, isFirstTime);
      }
    }
  }

  onSingleServiceChangeEvent(serviceId: number) {
    this.appointmentState.services = [serviceId];
    this.afterAppointmentServiceToggled();
  }

  onNewStoresEvent(stores: StoresDao[]) {
    this.stores = stores;
  }

  onServiceChange(eventData: any): void {
    if (eventData.event.target.checked) {
      if (this.partner.allow_multiple_services_bookings === 1) {
        this.appointmentState.services.push(eventData.serviceId);
      } else {
        this.appointmentState.services = [eventData.serviceId];
      }
    } else {
      this.appointmentState.services.splice(this.appointmentState.services.indexOf(eventData.serviceId), 1);

      this.localStorageService.getItem(this.CART_LS_NAME, (savedCartState: any) => {
        if (savedCartState) {
          let updatedCartList: any = savedCartState.filter(
            (cartState: any) => {
              return !cartState.servicesIds.includes(eventData.serviceId);
            }
          );
          if (eventData.relatedServiceId) {
            updatedCartList = savedCartState.filter((cartState: any) => {
              return !cartState.servicesIds.includes(
                eventData.relatedServiceId
              );
            });
          }
          this.cart = updatedCartList;
          this.utilService.updateLocalStorage(
            this.CART_LS_NAME,
            updatedCartList
          );

          if (updatedCartList.length === 0) {
            this.calendarSlotsCountList = [];
          } else if (updatedCartList.length > 0) {
            const updatedCalendarSlotsCountList: {
              date: Date;
              slotsCount: number;
            }[] = [];

            for (const updatedCart of updatedCartList) {
              const foundedCalendarSlotsCountList: {
                date: Date;
                slotsCount: number;
              }[] = this.calendarSlotsCountList.filter(
                (calendarSlot: { date: Date; slotsCount: number }) => {
                  return (
                    updatedCart.date.toString() ===
                    calendarSlot.date.toString()
                  );
                }
              );

              updatedCalendarSlotsCountList.concat(
                foundedCalendarSlotsCountList
              );
            }
            this.calendarSlotsCountList = updatedCalendarSlotsCountList;
          }
        }
      });
    }

    this.afterAppointmentServiceToggled();
  }

  afterAppointmentServiceToggled() {
    this.updateAvailableWorkers();
    // this.updateAppointmentCustomFields();

    this.cdr.detectChanges();

    // If there is just one worker available then preselect that worker as worker tab will be hidden
    if (this.workersAvailable?.length === 1) {
      this.appointmentState.worker = this.workersAvailable[0]['id'];
      this.onWorkerSelected();
    }
  }

  onWorkerSelected(refreshCalendar = false): void {
    this.freeAppointments = [];

    if (this.workersAvailable && this.workersAvailable.length > 0) {
      const foundedWorker = this.workersAvailable.find(worker => worker.id === this.appointmentState.worker);
      this.selectedWorker = foundedWorker || undefined;
    }

    this.calendarLoaded = false;

    this.resetCalendarData();
    refreshCalendar && this.customEventService.refreshCalendarEvent.emit();
  }

  autoLoadBookableDates() {
    const maxDate = moment().add(this.partner.future_booking_threshold_appointments, 'days').toDate();
    const minDate = moment().startOf('month').toDate();
    this.automaticBookableDateProcessIsEnabled = true;
    const isNextPressed = this.customEventService.eventCalendarIsNextPressed;
    const isPreviousPressed = this.customEventService.eventCalendarIsPreviousPressed;

    const currMonthDate = new Date(
      this.viewDate.getFullYear(),
      ([true, undefined].includes(isNextPressed)
        ? this.viewDate.getMonth() + 1
        : this.viewDate.getMonth() - 1
      ),
      1
    );

    if (currMonthDate >= minDate && currMonthDate < maxDate) {
      LoggerService.log(`[Debug] Booking date is not available. Jump to ${isPreviousPressed ? 'previous' : 'next'} month.`);

      this.viewDate = new Date(
        this.viewDate.getFullYear(),
        ([true, undefined].includes(isNextPressed)
          ? this.viewDate.getMonth() + 1
          : this.viewDate.getMonth() - 1
        ),
        1
      );
      this.monthChanged = true;
      this.updateCalendarPrevState(this.viewDate);
      this.calendarLoaded = false;
    } else {
      this.calendarLoaded = true;
      this.automationBookingDatesLimitReached = true;

      // reset flags of next and previous calendar button
      LoggerService.log('[Reset flags of next and previous calendar button]');
      this.customEventService.eventCalendarIsNextPressed = undefined;
      this.customEventService.eventCalendarIsPreviousPressed = undefined;
    }
  }

  resetCalendarData(): void {
    this.monthChanged = false;
    this.lastCalendarMonth = undefined;
  }

  // The method called when you go to the calendar page
  markExceptionDays({ body }: { body: CalendarMonthViewDay[] }) {
    // Workaround: prevent executing twice;
    // Bug: when calendar month is changed this function is executed twice. Once with previous month days set,
    // and second time with current month day set
    // Solution: keep last month number and execute this just if body date is not equal to month number of previous month

    if (this.monthChanged && this.lastCalendarMonth === body[10].date.getMonth()) {
      LoggerService.warn('Not loading calendar');
      return false;
    } else {
      // Unsubscribe previous request in case still in progress
      this.getDaysWiseFreeSlots$?.unsubscribe();
      this.calendarLoaded = false;
      this.showAppointmentSlots = false;

      this.lastCalendarMonth = body[10].date.getMonth();
      let dayOfMonth: string;
      const maxDate = moment().add(this.partner.future_booking_threshold_appointments, 'days').toDate();
      let startMomentObj: moment.Moment;
      let endMomentObj: moment.Moment;

      if (moment(body[10].date).isSame(new Date(), 'month')) {
        dayOfMonth = moment(new Date()).format(this.dateIsoFormat);
      } else {
        dayOfMonth = moment(body[10].date).startOf('month').format(this.dateIsoFormat);
      }

      if (this.dateContext && this.dateContextStart && this.dateContextEnd) {
        startMomentObj = moment(this.dateContext, this.dateIsoFormat).subtract(this.dateContextStart, 'days');
        endMomentObj = moment(this.dateContext, this.dateIsoFormat).add(this.dateContextEnd, 'days');
        const endOfMonthMomentObj = moment(body[10].date).endOf('month');
        if (endMomentObj.isBefore(endOfMonthMomentObj)) {
          this.customEventService.disableCalendarNextButtonEvent.emit(true);
        }
      }

      this.getDaysWiseFreeSlots$ = this.formService.getDaysWiseFreeSlots(
        this.conferenceState?.uuid === undefined ? (
          (this.selectedWorkerId && Number(this.selectedWorkerId) > 0) ? Number(this.selectedWorkerId) : (this.appointmentState.worker ? this.appointmentState.worker : 0)
        ) : 0,
        this.appointmentState.store,
        this.appointmentState.services.toString(),
        dayOfMonth,
        this.globals.isInternal,
        (this.conferenceState?.uuid ? this.conferenceState.uuid : undefined),
        this.debug,
        this.token
      ).pipe(takeWhile(() => this.alive)).subscribe({
        next: availableSlots => {
          this.getDaysWiseFreeSlots$.unsubscribe();
          this.calendarDate = true;
          this.availableSlotsArray = [];
          let bookingDateIsAvailable = true;
          if (availableSlots && !('errors' in availableSlots)) {
            // preparing array that has day wise availabilites
            availableSlots.available_minutes_blocks?.forEach(daysWiseSlots => {
              daysWiseSlots.workers_minutes_blocks?.forEach(workerMinutesBlock => {
                workerMinutesBlock.minutes_blocks.forEach(availabilities => {
                  availabilities.appointments_slots?.forEach(slots => {
                    const hasAnyoneFeatureEnabled = (this.selectedWorkerId && Number(this.selectedWorkerId) > 0) ? Number(this.selectedWorkerId) : (this.appointmentState.worker ? this.appointmentState.worker : 0);
                    let workerUuids = Array<string>();
                    if (hasAnyoneFeatureEnabled === 0) {
                      workerUuids = [workerMinutesBlock.worker.uuid];
                    } else {
                      workerUuids = workerMinutesBlock.workers.map(worker => worker.uuid);
                    }

                    !this.availableSlotsArray[daysWiseSlots.date] && (this.availableSlotsArray[daysWiseSlots.date] = []);
                    this.availableSlotsArray[daysWiseSlots.date].push({
                      start: slots.appointment_start,
                      end: slots.appointment_end,
                      worker: workerMinutesBlock.worker,
                      workerUuids,
                      store: workerMinutesBlock.store,
                      startHour: parseInt(slots.appointment_start.split(' ')[1].split(':')[0], 10),
                      short_start: slots.appointment_short_start,
                      location: slots.location
                    });
                  });
                });
              });
            });
          }

          bookingDateIsAvailable = (Object.keys(this.availableSlotsArray)?.length > 0);

          body.forEach(date => {
            const formatedDate = this.utilService.dateToStr(date.date);
            let dateDisabled = false;
            (this.days.length < 7) && this.days.push(date);

            if (
              !this.availableSlotsArray[formatedDate] || // date is not present in available dates array
              date.isPast || // if date is in past
              date.date > maxDate // date is bigger than max date
            ) {
              dateDisabled = true;
            }

            if (!dateDisabled && startMomentObj && endMomentObj) {
              if (moment(date.date).isBetween(startMomentObj, endMomentObj, undefined, '[]')) {
                if (!moment(date.date).isSameOrAfter(new Date(), 'day')) {
                  dateDisabled = true;
                }
              } else {
                dateDisabled = true;
              }
            }

            if (!dateDisabled && !this.dateContext && this.calendarPreselectedDate) {
              if ((date.date < moment(this.calendarPreselectedDate).toDate()) || (date.date > moment(this.calendarPreselectedDate).toDate())) {
                dateDisabled = true;
              }
            }

            if (dateDisabled) {
              date.cssClass = 'cal-disabled';
              date.isWeekend = true;
            }

            // date context is present and current date is same then preselect date
            if (!dateDisabled && this.appointmentState.date !== null && date.date.toDateString() === this.appointmentState.date.toDateString()) {
              this.dayClicked({ day: date });
            }
          });

          if (!bookingDateIsAvailable) {
            this.showBookableDateNotAvailableIssue = true;
            if (Number(this.partner.disable_automatic_search) !== 1) {
              this.automaticBookableDateProcessIsEnabled = true;
            }
            if (this.automaticBookableDateProcessIsEnabled) {
              this.autoLoadBookableDates();
            }
          } else {
            this.showBookableDateNotAvailableIssue = false;
            this.automaticBookableDateProcessIsEnabled = false;

            // reset flags of next and previous calendar button
            LoggerService.log('[Reset flags of next and previous calendar button]');
            this.customEventService.eventCalendarIsNextPressed = undefined;
            this.customEventService.eventCalendarIsPreviousPressed = undefined;
          }

          this.calendarLoaded = true;
        },
        error: (error) => {
          this.calendarLoaded = false;
          this.getDaysWiseFreeSlots$?.unsubscribe();
          LoggerService.error(error);
        }
      });
    }
  }

  // Before calendar render

  // On next prev month
  viewDateChanged(event: { viewDate: Date; isNext: boolean }): void {
    this.customEventService.eventCalendarIsNextPressed = event.isNext;
    this.customEventService.eventCalendarIsPreviousPressed = !event.isNext;
    this.viewDate = event.viewDate;
    this.automaticBookableDateProcessIsEnabled = false;
    this.monthChanged = true;
    this.updateCalendarPrevState(event.viewDate);
    this.calendarLoaded = false;
    this.automationBookingDatesLimitReached = false;
  }

  // Get slots when calendar day clicked
  dayClicked({ day }: { day: CalendarMonthViewDay }) {
    const formatedDate = this.utilService.dateToStr(day.date);

    if (day.isWeekend || day.isPast || day.date === this.appointmentState.date || !this.availableSlotsArray[formatedDate]) {
      LoggerService.warn('[Debug] Selected date is not available due to may it is weekend or in past or disabled or already selected');
      return false;
    } else {
      this.appointmentState.day = day;
      this.appointmentState.date = day.date;
      this.freeAppointments = [];
      this.showAppointmentSlotGhostElement = 1;
      this.noAppointments = false;

      day.cssClass = 'cal-selected';
      day.badgeTotal = 0;

      this.old_select_calendar?.badgeTotal === 0 && (this.old_select_calendar.cssClass = '');

      const cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;

      if (!cartSupported) {
        this.cart = [];
        this.CALENDAR_SLOTS_COUNT_LS_NAME = [];
        this.calendarSlotsCountList = [];
        if (this.old_select_calendar) {
          this.old_select_calendar.cssClass = '';
          this.old_select_calendar.badgeTotal = 0;
        }
      }

      this.old_select_calendar = day;

      this.getFreeAppointments(formatedDate);
    }
  }

  getFreeAppointments(date: string) {
    let freeAppointments: AppointmentsSlotsDao[] = this.availableSlotsArray[date];
    this.freeAppointmentsCount = freeAppointments.length;
    !freeAppointments?.length && (this.noAppointments = true);

    freeAppointments = freeAppointments.sort((a, b) => Number(a.short_start) - Number(b.short_start));

    let tempFreeAppointments: {
      dayPeriod: string;
      appointments: AppointmentsSlotsDao[];
      showDayPeriodLabel?: boolean;
      widgetGroup: WidgetAppointmentGroupModel;
      loadMore: boolean
    }[] = [];

    if (this.widgetGroups?.length) {
      this.widgetGroups?.forEach(widgetGroup => {
        tempFreeAppointments.push({
          dayPeriod: widgetGroup.name,
          appointments: [],
          showDayPeriodLabel: false,
          widgetGroup: widgetGroup,
          loadMore: false
        });
      });
    }

    tempFreeAppointments.push({
      dayPeriod: undefined,
      appointments: [],
      showDayPeriodLabel: false,
      widgetGroup: undefined,
      loadMore: false
    });

    if (this.widgetGroups?.length) {
      const notGroupedAppointments: AppointmentsSlotsDao[] = [];
      for (let i = 0; freeAppointments.length > i; i++) {
        for (const freeAppointmentGroup of tempFreeAppointments) {
          if (freeAppointmentGroup.widgetGroup) {
            const freeAppointmentStart = moment(moment().format(this.dateIsoFormat) + ' ' + moment(freeAppointments[i].start).format(this.timeFormat), this.dateTimeIsoFormat).toDate();
            const freeAppointmentEnd = moment(moment().format(this.dateIsoFormat) + ' ' + moment(freeAppointments[i].end).format(this.timeFormat), this.dateTimeIsoFormat).toDate();

            let widgetGroupStart = moment(freeAppointmentGroup.widgetGroup.start, this.dateTimeIsoFormat).toDate();
            let widgetGroupEnd = moment(freeAppointmentGroup.widgetGroup.end, this.dateTimeIsoFormat).toDate();
            widgetGroupStart = moment(moment().format(this.dateIsoFormat) + ' ' + moment(widgetGroupStart).format(this.timeFormat), this.dateTimeIsoFormat).toDate();
            widgetGroupEnd = moment(moment().format(this.dateIsoFormat) + ' ' + moment(widgetGroupEnd).format(this.timeFormat), this.dateTimeIsoFormat).toDate();

            if (moment(widgetGroupStart).isAfter(moment(widgetGroupEnd))) {
              widgetGroupEnd = moment(widgetGroupEnd).add(1, 'day').toDate();
              if (
                (
                  moment(freeAppointmentStart).isSameOrAfter(moment(widgetGroupStart)) ||
                  moment(freeAppointmentStart).add(1, 'day').isBetween(moment(widgetGroupStart), moment(widgetGroupEnd), undefined, '[]')
                ) &&
                moment(freeAppointmentEnd).isSameOrBefore(moment(widgetGroupEnd))
              ) {
                freeAppointments[i].isGrouped = true;
                freeAppointmentGroup.appointments.push(freeAppointments[i]);
                freeAppointmentGroup.showDayPeriodLabel = true;
                break;
              }
            } else {
              if (
                moment(freeAppointmentStart).isSameOrAfter(moment(widgetGroupStart)) &&
                moment(freeAppointmentStart).isBefore(moment(widgetGroupEnd))
              ) {
                freeAppointments[i].isGrouped = true;
                freeAppointmentGroup.appointments.push(freeAppointments[i]);
                freeAppointmentGroup.showDayPeriodLabel = true;
                break;
              }
            }
          }
        }

        if (!freeAppointments[i].isGrouped) {
          notGroupedAppointments.push(freeAppointments[i]);
        }
      }

      if (notGroupedAppointments.length) {
        tempFreeAppointments[tempFreeAppointments.length - 1].showDayPeriodLabel = true;
        tempFreeAppointments[tempFreeAppointments.length - 1].appointments = Object.assign([], notGroupedAppointments);
      }
    } else {
      for (let i = 0; freeAppointments.length > i; i++) {
        tempFreeAppointments[tempFreeAppointments.length - 1].showDayPeriodLabel = true;
        tempFreeAppointments[tempFreeAppointments.length - 1].appointments.push(freeAppointments[i]);
      }
    }

    let appointmentOrderIndex = 0;
    tempFreeAppointments?.forEach(tempFreeAppointment => {
      tempFreeAppointment.appointments = tempFreeAppointment.appointments ? tempFreeAppointment.appointments : [];
      appointmentOrderIndex > 9 && (tempFreeAppointment.showDayPeriodLabel = false);
      tempFreeAppointment?.appointments?.forEach(appointment => {
        appointment.appointmentOrderIndex = appointmentOrderIndex++;
      });
    });

    tempFreeAppointments = tempFreeAppointments.filter(item => item?.appointments?.length > 0);
    this.freeAppointments = tempFreeAppointments;
    this.noAppointments = (!freeAppointments || freeAppointments.length === 0);
    setTimeout(() => {
      this.showAppointmentSlots = true;
      this.showAppointmentSlotGhostElement = 2;
      this.appointmentService.resetSlotSettingsEvent.emit()
    }, 500);
  }

  /** Cart functions */
  updateCart(eventData: {
    event: any;
    appointment: AppointmentsSlotsDao;
    ignoreRelatedServiceId: boolean;
    ignoreAppointmentStateServices: boolean;
    relatedServiceId: number;
    relatedAppointmentCardId: number;
    refreshFormAndCartData: boolean;
  }): void {
    const cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;

    this.lastSelectedappointment = eventData.appointment;
    if (eventData.event.target.checked) {
      let services: number[] = this.appointmentState.services;
      if (
        eventData.ignoreAppointmentStateServices &&
        eventData.relatedServiceId
      ) {
        services = [eventData.relatedServiceId];
      }
      // add item to cart
      if (!cartSupported) {
        this.addToCart(
          eventData.appointment,
          false,
          eventData.ignoreRelatedServiceId,
          services,
          eventData.relatedAppointmentCardId,
          eventData.refreshFormAndCartData
        );
      } else {
        this.addToCart(
          eventData.appointment,
          true,
          eventData.ignoreRelatedServiceId,
          services,
          eventData.relatedAppointmentCardId,
          eventData.refreshFormAndCartData
        );
      }
    } else {
      // remove selected item from cart
      this.removeFromCart(
        this.appointmentService.createCartItemId(
          eventData.appointment.worker.id,
          this.appointmentState.date,
          Number(eventData.appointment.short_start),
        ),
        false,
        this.appointmentConstant.CALENDAR
      );
    }
  }

  addToCart(
    appointment: AppointmentsSlotsDao,
    overwrite: boolean,
    ignoreRelatedServiceId: boolean,
    services: number[],
    relatedAppointmentCardId: number,
    refreshFormAndCartData: boolean
  ): void {
    const appointmentStateDate: Date = this.appointmentState.date;
    this.cartService.addToCart(
      appointment,
      this.appointmentState,
      this.appoinrmentServicesAvailable,
      this.CART_LS_NAME,
      this.cart,
      overwrite,
      ignoreRelatedServiceId,
      services,
      relatedAppointmentCardId,
      (cart: CartItem[]) => {
        this.cart = cart;
        this.totalCartDuration = this.cartService.totalCartDuration;
        this.totalCartPrice = this.cartService.totalCartPrice;
        if (!overwrite) {
          this.calendarSlotsCountList = [];
        }

        if (this.calendarSlotsCountList.length === 0) {
          this.calendarSlotsCountList = [
            { date: appointmentStateDate, slotsCount: 1 },
          ];
        } else {
          let calendarSlotsCount: {
            date: Date;
            slotsCount: number;
          } = this.calendarSlotsCountList.find(
            (calendarSlot: { date: Date; slotsCount: number }) => {
              return (
                appointmentStateDate.toString() === calendarSlot.date.toString()
              );
            }
          );

          if (calendarSlotsCount) {
            calendarSlotsCount.slotsCount++;
          } else {
            calendarSlotsCount = { date: appointmentStateDate, slotsCount: 1 };
            this.calendarSlotsCountList.push(calendarSlotsCount);
          }
        }

        if (refreshFormAndCartData) {
          this.calculateCoupon();

          this.customEventService.cartItemsChangeEvent.emit({
            cart: this.cart,
            type: WIDGET_CONSTANTS.APPOINTMENT,
          });

          this.updateAppointmentCustomFields();
        }

        // Reserve appointment from here only when appointment added to cart is releated appointment
        if (relatedAppointmentCardId && refreshFormAndCartData) {
          this.reserveAppointments();
        }
      }
    );
  }

  removeFromCart(cartItemId: string, reloadCustomFields?: boolean, mode: string = APPOINTMENT_CONSTANT.SUMMARY): void {
    const appointmentStateDate: Date = this.appointmentState.date;
    this.cartService.removeFromCart(
      cartItemId,
      this.CART_LS_NAME,
      this.cart,
      (cart: CartItem[]) => {
        // Check if appointment reservation setting is enabled then delete reservation when slot
        // is remove from cart
        if (Number(this.partner.is_appointment_reservation_enabled) === 1 && mode === this.appointmentConstant.SUMMARY) {
          const appointment = this.cart.find(appointment => appointment.cartItemId === cartItemId);
          this.removeReservedAppointment(appointment);
        }

        this.cart = cart;
        this.totalCartDuration = this.cartService.totalCartDuration;
        this.totalCartPrice = this.cartService.totalCartPrice;

        if (this.calendarSlotsCountList.length >= 0) {
          const calendarSlotsCount = this.calendarSlotsCountList.find(
            calendarSlot => appointmentStateDate.toString() === calendarSlot.date.toString()
          );

          if (calendarSlotsCount && calendarSlotsCount.slotsCount > 1) {
            calendarSlotsCount.slotsCount--;
          } else {
            this.calendarSlotsCountList = this.calendarSlotsCountList.filter(
              calendarSlot => appointmentStateDate.toString() !== calendarSlot.date.toString()
            );
            this.appointmentState.day.cssClass = '';
            this.appointmentState.day.badgeTotal = 0;
          }
        }

        this.calculateCoupon();

        this.customEventService.cartItemsChangeEvent.emit({
          cart: this.cart,
          type: WIDGET_CONSTANTS.APPOINTMENT,
        });

        if (this.cart.length === 0) {
          this.navigateTo(APPOINTMENT_CONSTANT.DATE)
        }

        reloadCustomFields && this.updateAppointmentCustomFields();
      }
    );
  }

  private removeReservedAppointment(appointment: CartItem): void {
    const appointmentBody: AppointmentReservationBody[] = [];
    appointment.servicesIds.forEach(service => {
      appointmentBody.push({
        appointment_service_id: service,
        worker_id: appointment.workerId,
        start_time: appointment.startTime,
        end_time: appointment.endTime
      });
    });

    this.formService.removeReservedAppointment(appointmentBody).subscribe({
      error: (error: HttpErrorResponse) => {
        LoggerService.warn('[Debug] Failed to remove a reserved appointment');
        LoggerService.error(error);
      }
    });
  }

  // Booking action
  protected validatePersonalFormAndNavigateToSummaryStep(): void {
    // Reset personalFormValid status
    this.formService.personalFormValid = false;

    if (this.appointmentForm.invalid) {
      this.handlePersonalFormInvalidState();
    } else {
      // Reset personalFormValid status
      this.formService.personalFormValid = true;

      this.navigateTo(APPOINTMENT_CONSTANT.SUMMARY);
    }
  }

  private handlePersonalFormInvalidState(): void {
    LoggerService.warn('[Validation] Form Validation failed');

    const errorSummary = [];
    Object.keys(this.appointmentForm.controls)
      .filter(controlKey => this.appointmentForm.controls[controlKey].invalid)
      .forEach(controlKey => errorSummary[controlKey] = this.appointmentForm.controls[controlKey].errors);
    LoggerService.warn(errorSummary);

    this.markFormGroupTouched(this.appointmentForm);
    this.isStripeBookButtonValidated();
    this.widgetUtilService.focusInvalidElements(this.elementRef);
  }

  book(): void {
    // Reset personalFormValid status to true
    this.formService.personalFormValid = false;

    if (this.isValidBooking()) {
    }
  }

  isValidBooking(): boolean {
    this.widgetUtilService.customerProfile = undefined;
    if (this.formService.imageUploaded === false) {
      LoggerService.warn('[Processing] Image is uploading...');
      this.markFormGroupTouched(this.appointmentForm);
      this.isStripeBookButtonValidated();
      return false;
    }

    if (this.appointmentForm.invalid) {
      this.handlePersonalFormInvalidState();
      return false;
    }

    // Setting personalFormValid status to true
    this.formService.personalFormValid = true;

    if (this.bookValidated() && this.isStripeBookButtonValidated()) {
      this.doBooking();
      return true;
    } else {
      return false;
    }
  }

  doBooking(): void {
    this.disableBookingButton = true;
    this.helperService.bookingLoader.emit(true);
    this.bookingErrorMessage = undefined;
    const bookingItems: AppointmentBookItem[] = [];
    this.customFieldValues = { ...this.customFieldValues, ...this.qualificationQuestionsValues };

    // Extract custom field values
    let fieldIds = Object.keys(this.customFieldValues);
    const qualificationQuestionIds = Object.keys(this.qualificationQuestionsValues).map(key => Number(key));
    let rawCustomFieldIds: number[] = this.tempArray.map((i) => i.id);
    rawCustomFieldIds = [...rawCustomFieldIds, ...qualificationQuestionIds];

    if (rawCustomFieldIds?.length > 0) {
      fieldIds = fieldIds.filter((id: any) => {
        return rawCustomFieldIds.indexOf(Number(id)) > -1;
      });
    }

    const customFieldValues: {
      custom_field_id: number;
      type: string;
      value?: any;
      file_ids?: any;
      option_values?: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }[] = [];

    for (let i = 0; i < fieldIds.length; i++) {
      if (this.customFieldValues[fieldIds[i]]?.type === 'select') {
        if (Array.isArray(this.customFieldValues[fieldIds[i]]?.value)) {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: this.customFieldValues[fieldIds[i]]?.value,
          });
        } else {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: [this.customFieldValues[fieldIds[i]]?.value],
          });
        }
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'card-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'card-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'image-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'image-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (
        this.customFieldValues[fieldIds[i]]?.type === 'number-select'
      ) {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'number-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
          option_values: this.customFieldValues[fieldIds[i]]?.option_values,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'file') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'file',
          file_ids: Object.assign(
            [],
            this.customFieldValues[fieldIds[i]]?.file_ids
          ),
        });
      } else {
        let tempCustomFieldsDao: CustomFieldsDao;
        if (this.tempArray?.length > 0) {
          tempCustomFieldsDao = this.tempArray.find((item) => {
            return Number(item.id) === Number(fieldIds[i]);
          });
        }
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: tempCustomFieldsDao ? tempCustomFieldsDao?.type : '',
          value: this.customFieldValues[fieldIds[i]]?.toString(),
        });
      }
    }

    for (const i in this.cart) {
      if (this.cart.hasOwnProperty(i)) {
        const bookingData: AppointmentBookItem = {
          store_id: this.cart[i].storeId,
          store_name: this.cart[i].storeName,
          partnerId: this.partner['id'],
          services: this.cart[i]['servicesIds'].join(),
          referenceServices: this.cart[i]['referenceServices'],
          worker_id: this.cart[i]['workerId'],
          worker_email: this.cart[i].workerEmail,
          worker_uuids: this.cart[i].workerUuids,
          resource_name: this.cart[i].workerName,
          start: this.cart[i]['startTime'],
          end: this.cart[i]['endTime'],
          ...(this.cart[i]['location'] ? { location: this.cart[i]['location'] } : {}),
          title: this.userState.title,
          prename: this.userState.forename,
          gender: this.userState.gender,
          lastname: this.userState.lastName,
          email: this.userState.eMail,
          phone: this.userState.phone,
          mobile: this.userState.mobile,
          comment: this.userState.comment,
          customer_id: this.userState.customerId,
          customer_uuid: this.userState.customerUuid,
          country: this.userState.country_id,
          company_name: (this.userState?.company || undefined),
          internal_comment: (this.userState?.internal_comment || undefined),
          captcha: (this.userState?.captcha_challenge || undefined),
          token: (this.appointmentState?.captcha?.uuid || undefined),
          customer_notification_preference: this.userState.customer_notification_preference,
          meeting_type_id: this.userState.meeting_type_id,
          customFieldValues: customFieldValues,
          user_browser_language: this.globals.user_browser_language,
          user_selected_language: this.globals.user_selected_language,
          coupon_code: this.coupon && !this.isInvalidCoupon ? this.coupon.code : null,
          payment_type: this.paymentType,
          booker_worker_id: this.bookerWorkerId,
          utm_source: ((this.utmSource && this.utmSource !== 'null') ? this.utmSource : undefined),
          utm_content: ((this.utmContent && this.utmContent !== 'null') ? this.utmContent : undefined),
          utm_medium: ((this.utmMedium && this.utmMedium !== 'null') ? this.utmMedium : undefined),
          utm_campaign: ((this.utmCampaign && this.utmCampaign !== 'null') ? this.utmCampaign : undefined),
          utm_term: ((this.utmTerm && this.utmTerm !== 'null') ? this.utmTerm : undefined),
          is_internal: this.globals?.isInternal ? true : false,
          ...(this.partner?.show_address_on_widget !== 0 ? {
            street: this.userState.street,
            zip: this.userState.zip,
            city: this.userState.city
           } : {}),
        };

        if (this.paymentType === 'paypal') {
          bookingData.paypal_order_id = this.paypalOrderDetail.paypal_order_id;
          bookingData.paypal_payer_id = this.paypalOrderDetail.paypal_payer_id;
          bookingData.paypal_status = this.paypalOrderDetail.paypal_status;
          bookingData.paypal_raw_data = this.paypalOrderDetail.paypal_raw_data;
          bookingData.paypal_capture_id =
            this.paypalOrderDetail.paypal_capture_id;
        } else if (this.paymentType === 'saferpay') {
          bookingData.saferpay_transaction_id = this.saferpayPaymentDetails.transaction_id;
          bookingData.saferpay_payment_method = this.saferpayPaymentDetails.payment_method;
        } else if (this.paymentType === 'creditcard') {
          bookingData.stripe_card_token = this.stripePaymentMethodId ? this.stripePaymentMethodId : null;
        }

        if (this.partner.is_customer_profiles_feature_enabled === 1) {
          bookingData.broker_prename = this.brokerState.forename;
          bookingData.broker_lastname = this.brokerState.lastName;
          bookingData.broker_email = this.brokerState.eMail;
          bookingData.broker_company = this.brokerState.company;
          bookingData.broker_phone = this.brokerState.phone;
          bookingData.broker_mobile = this.brokerState.mobile;
          bookingData.broker_street = this.brokerState.street;
          bookingData.broker_zip = this.brokerState.zip;
          bookingData.broker_city = this.brokerState.city;
        }

        // Aadditional fields realted to conference
        if (this.conferenceState?.uuid !== undefined) {
          bookingData.booking_link_uuid = this.conferenceState.uuid;
        }

        bookingItems.push(bookingData);
      }
    }

    if (this.isBookingDisabled) {
      LoggerService.error(bookingItems);
      this.disableBookingButton = false;
      this.helperService.bookingLoader.emit(false);
      return;
    }

    this.formService
      .bookAppointments(bookingItems)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (result: {
          success?: boolean;
          errors?: { message: string }[];
          bookingErrors?: { code: number; message: string }[],
          appointments?: any[];
        }) => {
          if (result.errors && result.errors[0] && result.errors[0].message) {
            this.bookingErrorMessage = result.errors[0].message;
            this.disableBookingButton = false;
            this.helperService.bookingLoader.emit(false);
            return;
          } else if (result.bookingErrors && result.bookingErrors[0] && result.bookingErrors[0].message) {
            this.finalPage = false;
            this.bookingErrorMessage = result.bookingErrors.map(error => error.message);
          }

          if (result.success) {

            this.brokerState = {};

            if (result?.appointments?.length > 0) {
              for (const item of result?.appointments) {
                this.successFullBookings.push({
                  landingPageLink:
                    environment?.calioDashboardBaseUrl +
                    '/appointments/' +
                    item?.uuid,
                  bookingUUID: item?.uuid,
                  selectedStore: item?.worker?.store,
                });
              }
            }

            // send booking success to event to parent window
            this.googleAnalyticsService.emitEventToParent(WINDOW_LISTENER_CONSTANTS.APPOINTMENT_BOOKING_DONE, result?.appointments);
            this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING_STEP_SUCCESS);

            if (this.globals.isInternal === false) {
              this.utilService.updateLocalStorage(this.CUSTOMFIELDS_LS_NAME, this.customFieldValues);
              this.userState.meeting_type_id = undefined;
              this.userState.captcha_challenge = undefined;
              this.utilService.updateLocalStorage(this.USER_LS_NAME, Object.assign({}, this.userState));
              if (this.partner.is_customer_profiles_feature_enabled === 1) {
                this.brokerState.meeting_type_id = undefined;
              }
            } else {
              this.userState = {};
              this.customFieldValues = {};
              this.selectedJsonCustomerDetail = undefined;
              this.localStorageService.removeItem(this.CUSTOMFIELDS_LS_NAME, () => void 0);
              this.localStorageService.removeItem(this.USER_LS_NAME, () => void 0);
            }

            this.setInitInternalComment();
            this.bookingSuccessEvent.emit();

            // Reset personalFormValid status
            this.formService.personalFormValid = false;

            this.finalPage = true;

            // clear cart from ls and object
            this.localStorageService.removeItem(this.STATE_LS_NAME, () => void 0);
            this.localStorageService.removeItem(this.CART_LS_NAME, () => void 0);

            this.appointmentState = {
              store: null,
              services: [],
              relatedServices: [],
              worker: null,
              date: null,
              termins: null,
              day: null,
              leadGeneratorPostcode: null,
            };

            this.cart = [];
            this.calendarSlotsCountList = [];
          } else {
            // Redirect to final page with error message
            this.finalPage = false;
          }
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;
          this.booked = true;
          this.disableBookingButton = false;
          this.isInvalidCoupon = false;
          this.helperService.bookingLoader.emit(false);
          this.appointmentService.gtmStartTriggered = false;

          if (this.partnerService.hasFeature('is_external_success_page_forwarding_enabled')) {
            if (this.partner.is_redirect_after_booking_enabled && this.partner.successful_booking_redirect_url && !this.globals.isInternal) {
              this.helperService.redirectToUrl(this.partner.successful_booking_redirect_url);
            }
          }
          this.localStorageService.setItem(LOCAL_STORAGE_CONSTANTS.LAST_PAYMENT_METHOD, this.paymentType, () => {
            LoggerService.log('LAST_PAYMENT_METHOD saved', this.paymentType);
          });
        },
        error: (error) => {
          LoggerService.error(error);
          if (error.status === 500) {
            this.translateService
              .get('common.noDateError')
              .pipe(takeWhile(() => this.alive))
              .subscribe({
                next: (translation) => {
                  this.finalPageError = translation;
                  this.finalPage = false;
                  this.booked = true;
                  this.disableBookingButton = false;
                  this.helperService.bookingLoader.emit(false);
                }
              });
          } else {
            this.finalPageError = error.message;
            this.finalPage = false;
            this.booked = true;
            this.disableBookingButton = false;
            this.helperService.bookingLoader.emit(false);
          }

          this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING_STEP_FAILED);
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;
          this.isInvalidCoupon = false;
        }
      });
  }

  // Change view and update local storage
  navigateTo(target: string, isFirstTime?: boolean): void {
    if (
      !isFirstTime &&
      this.appointmentsSectionRef
    ) {
      this.appointmentsSectionRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }

    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.automaticBookableDateProcessIsEnabled = false;
    this.showBookableDateNotAvailableIssue = false;
    this.automationBookingDatesLimitReached = false;

    if (target !== APPOINTMENT_CONSTANT.DATE) {
      this.calendarLoaded = false;
    }

    // trigger start event on first user interaction
    this.appointmentService.triggerApoointmentStartEvent();

    if (!this.isUserMadeFirstInteraction) {
      this.isUserMadeFirstInteraction = true;

      if (this.partner?.is_customer_profiles_feature_enabled !== 1) {
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_STORES_AND_SERVICES,
          this.prepareAnalyticsPayload()
        );
      } else {
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_PROFILES,
          this.prepareAnalyticsPayload()
        );
      }
    }

    switch (target) {
      case APPOINTMENT_CONSTANT.CUSTOMER_TYPE:
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_PROFILES,
          this.prepareAnalyticsPayload()
        );
        break;

      case APPOINTMENT_CONSTANT.APPOINTMENT:
        if (this.viewMode !== APPOINTMENT_CONSTANT.APPOINTMENT && this.hasQualificationQuestions) {
          this.appointmentService.resetmappedServiceIds();
          this.hasQualificationQuestionsAnswered = false;
        }

        if (this.stores?.length === 0) {
          this.fetchAvailableStores();
        }
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_STORES_AND_SERVICES,
          this.prepareAnalyticsPayload()
        );
        break;

      case APPOINTMENT_CONSTANT.WORKER:
        // To reset view of date step ghost element must be set to 0
        if (this.viewMode === APPOINTMENT_CONSTANT.DATE) {
          this.showAppointmentSlotGhostElement = 0;
        }

        // In case of conference, skip worker selection and move to appointment step
        if (this.conferenceState?.uuid) {
          this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
          return;
        }

        // In case is_widget_postcode_worker_random_selection_enabled setting is enabled then worker selection
        // will be either based on default worker or random base so worker step will alwasy skipped and never show
        if (
          Number(this.widgetUtilService.widgetConf.is_widget_postcode_worker_random_selection_enabled) === 1 &&
          this.viewMode === APPOINTMENT_CONSTANT.DATE
        ) {
          this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
          return;
        }

        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_RESOURCES,
          this.prepareAnalyticsPayload()
        );
        if (Number(this.partner.is_round_robin_feature_enabled) === 1) {
          LoggerService.warn('[Debug] Going to the date according to round robin screen 0');
          if (this.workersAvailable.length > 0) {
            const worker: any = this.helperService.get_random(
              this.workersAvailable
            );
            this.selectWorker(worker.id);
            if (this.viewMode === APPOINTMENT_CONSTANT.DATE) {
              this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
            } else {
              this.navigateTo(APPOINTMENT_CONSTANT.DATE);
            }
          } else {
            LoggerService.warn('[Debug] Workers are not available in the round robin approach thats why selecting any worker.');
            this.selectWorker(0);
            if (this.viewMode === APPOINTMENT_CONSTANT.DATE) {
              this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
            } else {
              this.navigateTo(APPOINTMENT_CONSTANT.DATE);
            }
          }
          return;
        }

        if (Number(this.partner.widget_hide_resource_step_for_default_workers) === 1 && this.appointmentState.default_worker_id) {
          this.selectWorker(this.appointmentState.default_worker_id);
          this.navigateTo(APPOINTMENT_CONSTANT.DATE);
          return;
        }

        if (this.selectedWorkerId || Number(this.selectedWorkerId) === 0) {
          this.preselectWorker();
          return;
        }

        if (Number(this.partner.hide_resource_step) === 1) {
          this.appointmentState.worker = 0;
          this.onWorkerSelected();
          if (this.viewMode === APPOINTMENT_CONSTANT.DATE) {
            this.navigateTo(APPOINTMENT_CONSTANT.APPOINTMENT);
          } else {
            this.navigateTo(APPOINTMENT_CONSTANT.DATE);
          }

          return;
        }

        if (
          this.workersAvailable.length < 2 &&
          this.appointmentState.services.length > 0
        ) {
          if (this.viewMode === APPOINTMENT_CONSTANT.APPOINTMENT) {
            target = APPOINTMENT_CONSTANT.DATE;
            this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING_STEP_AVAILABLE_APPOINTMENTS, this.prepareAnalyticsPayload());
          } else {
            target = APPOINTMENT_CONSTANT.APPOINTMENT;
            this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING_STEP_STORES_AND_SERVICES, this.prepareAnalyticsPayload());
          }
        }
        if (!isFirstTime) {
          this.scrollToAppointmentComponent();
        }
        break;

      case APPOINTMENT_CONSTANT.DATE:
        // not possible if worker or service not selected
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.APPOINTMENT_BOOKING_STEP_AVAILABLE_APPOINTMENTS,
          this.prepareAnalyticsPayload()
        );
        this.freeAppointmentsCount = 0;
        if (Number(this.partner.hide_back_on_bookingsite) === 1) {
          this.appointmentService.disableFirstTwoButtonsEvent.emit();
          this.disableDatePagePreviousButton = true;
        }

        if (
          this.conferenceState?.uuid === undefined &&
          this.appointmentState.worker == null ||
          this.appointmentState.services.length === 0
        ) {
          return;
        }

        this.monthChanged = false;
        this.scrollToAppointmentComponent();
        break;

      case APPOINTMENT_CONSTANT.PERSONAL_INFO:
        this.reserveAppointments();
        this.updateAppointmentCustomFields();
        this.scrollToAppointmentComponent();
        this.resetDataBeforeLoadingSummaryPage();
        this.verifyOnlinePaymentStatus();
        this.bookingErrorMessage = undefined;

        const gtmEventName = Number(this.partner?.split_personal_form_and_summary_in_widget) === 1
          ? GTM_EVENTS.APPOINTMENT_BOOKING_STEP_PERSONAL_INFO
          : GTM_EVENTS.APPOINTMENT_BOOKING_STEP_SUMMARY;

        this.googleAnalyticsService.emitBookingEvent(gtmEventName, this.prepareAnalyticsPayload());
        break;

      case APPOINTMENT_CONSTANT.SUMMARY:
        // Setting personalFormValid set
        this.formService.personalFormValid = true;
        this.bookingErrorMessage = undefined;

        this.showSummaryDetails = false;
        this.summaryDetails = {
          title: this.userState.title || undefined,
          prename: this.userState.forename || undefined,
          gender: this.userState.gender || undefined,
          lastname: this.userState.lastName || undefined,
          email: this.userState.eMail || undefined,
          phone: this.userState.phone || undefined,
          mobile: this.userState.mobile || undefined,
          comment: this.userState.comment || undefined,
          company_name: (this.userState?.company || undefined),
          country: (this.userState?.country || undefined),
          internal_comment: (this.userState?.internal_comment || undefined),
          ...(this.partner?.show_address_on_widget !== 0 ? {
            street: this.userState.street,
            zip: this.userState.zip,
            city: this.userState.city
          } : {}),
          customFieldValues: {}
        };

        // Generate options and values to display
        this.getCustomerCustomFieldSelectValue();
        break;
    }

    // Manage current and previous page state
    this.customEventService.currentPage = target;
    this.customEventService.previousPage = this.viewMode;

    this.viewMode = target;
    this.formReseted = false;
    this.utilService.updateLocalStorage(this.STATE_LS_NAME, this.appointmentState);
    this.backButtonClicked = false;
  }

  private reserveAppointments(): void {
    if (Number(this.partner.is_appointment_reservation_enabled) === 1) {
      const appointments: AppointmentReservationBody[] = [];

      if (this.conferenceState?.uuid !== undefined) {
        appointments.push({
          booking_link_uuid: this.conferenceState.uuid,
          start_time: this.cart[0].startTime,
          end_time: this.cart[0].endTime
        });
      } else {
        this.cart.forEach(appointment => {
          appointment.servicesIds.forEach(service => {
            const appointmentData: AppointmentReservationBody = {
              appointment_service_id: service,
              worker_id: appointment.workerId,
              start_time: appointment.startTime,
              end_time: appointment.endTime,
            };

            if (appointment?.relatedAppointmentCardId && appointment?.parentServiceId) {
              appointmentData.related_service_id = appointment?.parentServiceId;
            }

            appointments.push(appointmentData);
          });
        });
      }

      this.formService.reserveAppointments(appointments)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: reservation => {
            if (!reservation?.success) {
              LoggerService.warn("[Debug] Failed to reserve slot");
              LoggerService.error(reservation.errors);
            }
          },
          error: (error) => {
            LoggerService.warn("[Debug] Failed to reserve slot");
            LoggerService.error(error);
          }
        });
    }
  }

  // button event to clear local storage
  resetForm(): void {
    this.localStorageService.clear(() => {
      // Clear form inputs
      this.appointmentState = {
        customerProfileId: this.appointmentState.customerProfileId,
        store: this.appointmentState.store,
        services: [],
        relatedServices: [],
        worker: null,
        date: null,
        termins: null,
        day: null,
        leadGeneratorPostcode: null,
      };

      this.userState = {
        forename: null,
        lastName: null,
        gender: null,
        eMail: null,
        phone: null,
        mobile: null,
        comment: null,
        customerId: null,
        customerUuid: null,
        street: null,
        zip: null,
        city: null,
        company: null,
        internal_comment: null,
      };

      this.brokerState = {
        forename: null,
        lastName: null,
        gender: null,
        eMail: null,
        phone: null,
        mobile: null,
        comment: null,
        customerId: null,
        customerUuid: null,
        street: null,
        zip: null,
        city: null,
        company: null,
        internal_comment: null,
      };

      // Clear cart and custom fields
      this.cart = [];

      // Custom field and Custom field values
      this.customFieldValues = {};
      this.appointmentCustomFields = [];
      this.tempArray = [];

      // Go back to first tab
      this.viewMode = APPOINTMENT_CONSTANT.APPOINTMENT;

      // Show success message
      this.formReseted = true;
      this.calendarSlotsCountList = [];

      this.setInitInternalComment();
    });
  }

  // Checkif browser width is mobile sized
  @HostListener('window:resize', ['$event'])
  checkIfMobile(event) {
    this.isMobile = this.deviceDetectorService.isMobile();
  }

  // To be used as wrapper for 'do nothing'
  ignore(): boolean {
    return false;
  }

  updateUserState(newUserState: UserState): void {
    this.userState = newUserState;
  }

  updateCustomerSelected(newCustomerSelected: object): void {
    this.customerSelected = newCustomerSelected;
  }

  // just add (click)="showMessage()" where needed
  showMessage(): void {
    this.isClicked = !this.isClicked;
  }

  onWorkerSubmit(workerForm) {
    return workerForm;
  }

  // Check if all prerequests are good on order to enable book button
  bookValidated(): boolean {
    if (this.cart.length > 0) {
      if (Number(this.partner.is_captcha_feature_enabled) === 1 && !this.userState.captcha_challenge) {
        LoggerService.warn('[Validation failed] Captcha is required');
        return false;
      }

      if (this.globals?.isInternal) {
        // Check if customer is selected
        if (this.customerSelected) {
          return true;
        } else {
          if (this.userState?.forename && this.userState?.lastName) {
            if (this.partner?.needPhoneNumber && this.partner?.widget_phone_required && !this.userState?.phone) {
              LoggerService.warn('[Validation failed] Phone number is required');
              return false;
            }

            if (this.partner?.needMobileNumber && this.partner?.widget_mobile_required && !this.userState?.mobile) {
              LoggerService.warn('[Validation failed] Mobile number is required');
              return false;
            }

            if (this.partner?.internal_widget_email_shown === 1) {
              if (
                this.partner?.internal_widget_email_required === 1 &&
                this.userState.eMail
              ) {
                return true;
              }
            }
            return true;
          }
          return false;
        }
      } else {
        return true;
      }
    } else {
      LoggerService.warn('[Validation failed] Cart is empty');
      return false;
    }
  }

  selectWorker(workerId: number, refreshCalendar = false): void {
    this.appointmentState.worker = Number(workerId);
    this.onWorkerSelected(refreshCalendar);
  }

  disabledServiceTab(isDisabled: boolean): void {
    this.isServicesTabDisabled = isDisabled;
  }

  setInitStateLSName(): void {
    // Read local storage to set form state
    this.localStorageService.getItem(this.STATE_LS_NAME, (savedFormState) => {
      if (savedFormState) {
        if (savedFormState?.customerProfileId) {
          this.appointmentState.customerProfileId =
            savedFormState?.customerProfileId;
        }
        if (savedFormState?.store) {
          this.appointmentState.store = savedFormState?.store;
        }
        if (savedFormState?.services) {
          this.appointmentState.services = savedFormState?.services;
        }
        if (savedFormState?.worker) {
          this.appointmentState.worker = savedFormState?.worker;
        }
        if (savedFormState?.date) {
          this.appointmentState.date = savedFormState?.date;
        }
        if (savedFormState?.termins) {
          this.appointmentState.termins = savedFormState?.termins;
        }
        if (savedFormState?.day) {
          this.appointmentState.day = savedFormState?.day;
        }
        if (savedFormState?.default_worker_id) {
          this.appointmentState.default_worker_id =
            savedFormState?.default_worker_id;
        }
        if (savedFormState?.leadGeneratorPostcode) {
          this.appointmentState.leadGeneratorPostcode =
            savedFormState?.leadGeneratorPostcode;
        }
        if (savedFormState?.default_worker) {
          this.appointmentState.default_worker = savedFormState?.default_worker;
        }
        if (savedFormState?.storeDefaultWorkerIds) {
          this.appointmentState.storeDefaultWorkerIds = savedFormState?.storeDefaultWorkerIds;
        }

        // If selected date from localstorage is in past ignore it
        if (this.appointmentState.date < this.now) {
          this.appointmentState.date = null;
        }

        if (this.appointmentState.worker != null) {
          this.onWorkerSelected();
        }

        if (this.appointmentState.date) {
          // Set calendar default state to preselected month
          this.viewDate = this.appointmentState.date;
          this.lastCalendarMonth = this.viewDate.getMonth() + 1;

          this.updateCalendarPrevState(this.viewDate);
        }
      }
    });
  }

  verifyOnlinePaymentStatus() {
    this.partnerService.verifyOnlinePaymentStatus().subscribe({
      next: (result: VerifyPaymentStatusModel) => {
        if (result) {
          this.isServiceOnlinePaymentEnabled = Number(result.isServiceOnlinePaymentEnabled);
          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.stripeEnabled
          ) {
            this.isStripeEnabled = true;
            this.helperService.loadStripeJs();
          }

          if (result.isOnlinePaymentMandatory === 1) {
            this.isOnlinePaymentMandatory = true;
          }

          if (
            result.isBexioAppointmentServicePaymentEnabled === 1 &&
            result.bexioEnabled
          ) {
            this.isBexioEnabled = true;
          }

          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.saferpayEnabled
          ) {
            this.isSaferpayEnabled = true;
          }

          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.paypalEnabled
          ) {
            this.isPaypalEnabled = true;
            this.paypalSetting = new PayPalSettingsDbModel();
            this.paypalSetting.access_token = result.paypalAccessToken;
            this.paypalSetting.client_id = result.paypalClientId;
            // this.helperService.loadPaypalJs(this.paypalSetting.client_id);
          }

          if (result.onlinePaymentShowOnSitePaymentType === 1) {
            this.isStoreEnabled = true;
          }

          this.isLexOfficePaymentTypeEnabled = (result.lexOfficeEnabled === true);
        }
        this.paymentType = undefined;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  onChangePaymentType(event: string) {
    this.paymentType = event;
    this.stripePaymentMethodId = null;
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;

    // manage book and saferpay payment link visibility based on payment selection
    if (this.paymentType === 'saferpay') {
      this.showSaferpayButtonLink = true;
    } else {
      this.showSaferpayButtonLink = false;
    }
  }

  // Check if all prerequests are good on order to enable book button
  isStripeBookButtonValidated(): boolean {
    if (
      this.totalCartPrice === 0 ||
      this.totalCartPrice === -1 ||
      this.priceAfterCouponReduction <= 0
    ) {
      this.paymentType = 'store';
      return true;
    } else {
      if (
        !this.isBexioEnabled &&
        !this.isStripeEnabled &&
        !this.isPaypalEnabled &&
        !this.isSaferpayEnabled &&
        !this.isLexOfficePaymentTypeEnabled
      ) {
        // TODO Add comment online payment is not active
        this.paymentType = 'store';
        return true;
      } else {
        if (this.isOnlinePaymentMandatory) {
          if (
            (this.stripePaymentMethodId && this.paymentType === 'creditcard') ||
            this.paymentType === 'invoice' ||
            (this.paypalOrderDetail && this.paymentType === 'paypal') ||
            (this.saferpayPaymentDetails && this.paymentType === 'saferpay')
          ) {
            return true;
          } else {
            // TODO it should catch not valid stripe credit cards or payment type is store / null
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        } else {
          if (this.paymentType === 'creditcard') {
            if (this.stripePaymentMethodId) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'invoice') {
            return true;
          } else if (this.paymentType === 'store') {
            return true;
          } else if (this.paymentType === 'lex_office_invoice') {
            return true;
          } else if (this.paymentType === 'saferpay') {
            if (this.saferpayPaymentDetails) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'paypal') {
            if (this.paypalOrderDetail) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else {
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        }
      }
    }
  }

  onStripeSuccessfulEvent(event: string) {
    this.stripePaymentMethodId = event;
  }

  onResetStripeTokenEvent(event: any) {
    this.stripePaymentMethodId = null;
  }

  onPaypalSuccessfulEvent(event: PayPalOrderDetailModel) {
    this.paypalOrderDetail = event;
    this.book();
  }

  onResetPaypalEvent(event: any): void {
    this.paypalOrderDetail = null;
  }

  onSaferpaySuccessfulEvent(paymentDetails: SaferpayPaymentDetailsDbModel) {
    this.saferpayPaymentDetails = paymentDetails;
    this.showFullscreenOverlay = false;
    this.showSaferpayButtonLink = false;
    this.book();
  }

  onResetSaferpayEvent(errorMessage: string): void {
    this.bookingErrorMessage = errorMessage;
    this.disableBookingButton = false;
    this.helperService.bookingLoader.emit(false);
    this.saferpayPaymentDetails = undefined;
    this.showFullscreenOverlay = false;
  }

  hideBookingAgainButton(): boolean {
    if (this.finalPage) {
      if (this.partner.hide_book_additional_appointment_button === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onCouponSuccessEvent(event: CouponDbModel): void {
    this.coupon = event;
    this.couponDisableConfirmButton = false;
    this.isInvalidCoupon = false;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;
    this.customEventService.couponChangeEvent.emit(this.finalCouponDiscount);

    // Case when payment type is saferpay and total cart price is 0 or -1 or price after coupon reduction is 0 or -1 then hide saferpay payment button and show actual book button
    if (
      this.paymentType === 'saferpay' &&
      (this.totalCartPrice === 0 || this.totalCartPrice === -1 || this.priceAfterCouponReduction <= 0)
    ) {
      this.showSaferpayButtonLink = false;
    }
  }

  calculateCoupon(): void {
    if (this.coupon && this.coupon.is_fixed_amount === 1) {
      this.finalCouponDiscount = Number(this.coupon.fixed_amount);
    }

    if (this.coupon && this.coupon.is_percentage === 1) {
      this.finalCouponDiscount = Number(
        ((Number(this.totalCartPrice) / 100) * this.coupon.percentage).toFixed(
          2
        )
      );
    }

    this.priceAfterCouponReduction = Number(this.totalCartPrice) - Number(this.finalCouponDiscount);
  }

  onCouponFailedEvent(event: {
    disableBookingButton: boolean;
    invalidCoupon: boolean;
  }) {
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.priceAfterCouponReduction = undefined;
    this.couponDisableConfirmButton = event.disableBookingButton;
    this.isInvalidCoupon = event.invalidCoupon;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;
    this.paymentType === 'saferpay' && (this.showSaferpayButtonLink = true);
  }

  getWidgetTemplates() {
    this.partnerService.getWidgetTemplates().subscribe({
      next: templates => {
        this.widgetTemplates = templates;
        this.setupInitialTemplates();
        this.createSuccessMessage(this.lang);
        this.createErrorMessage();
      },
      error: (error: HttpErrorResponse) => LoggerService.error('Error is ', error)
    });
  }

  getWidgetAppointmentGroups(store_id: number): void {
    this.formService.getStoreWiseWidgetAppointmentGroups(store_id).subscribe({
      next: (result: WidgetAppointmentGroupModel[] | { errors: any }) => {
        let widgetGroups: WidgetAppointmentGroupModel[] = [];

        if (!result || (<{ errors: any }>result).errors) {
          widgetGroups = [];
          this.widgetGroups = [];
          return;
        }

        widgetGroups = <WidgetAppointmentGroupModel[]>result;

        for (const widgetGroup of widgetGroups) {
          widgetGroup.startHour = parseInt(
            (<string>widgetGroup['start']).split('T')[1].split(':')[0],
            10
          );
        }

        widgetGroups = widgetGroups.sort((a, b) => {
          return a.startHour - b.startHour;
        });

        this.widgetGroups = widgetGroups;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  configureCustomerLinkEventHandler() {
    if (
      this.appointmentState.leadGeneratorPostcode &&
      this.appointmentState.default_worker_id &&
      this.appointmentState?.default_worker?.is_lead_generator === 1
    ) {
      this.openCreateCustomerLink({
        postcode: this.appointmentState.leadGeneratorPostcode,
        workerId: this.appointmentState.default_worker_id,
      });
    } else {
      this.configureWorkerAndOpenCreateCustomerLink();
    }
  }

  openCreateCustomerLink(data: { postcode: string; workerId: number }) {
    if (data.postcode && data.workerId) {
      let url: string = String(
        `${environment.calioDashboardBaseUrl}/app/customers/create?zip=${data.postcode}`
      );
      url = url + `&worker=${data.workerId}&customer_type=lead`;
      url = url + `&fields=prename,lastname,language,street,zip,city,country,email,mobile,notification_type,customer_type`;
      url = url + `&mandatory=prename,lastname,street,zip,city,mobile,email`;
      window.parent.location.href = url;
    } else {
      LoggerService.warn('CALENSO_WIDGET_WARN: Postcode and worker is mandatory');
    }
  }

  configureWorkerAndOpenCreateCustomerLink() {
    const body: {
      is_lead_generator?: {
        value: string;
        operator: string;
      };
    } = {
      is_lead_generator: {
        value: '1',
        operator: '=',
      },
    };
    this.formService.filterWorkers(body).subscribe({
      next: (result: UserDao[]) => {
        if (result && result.length > 0) {
          this.openCreateCustomerLink({
            postcode: this.appointmentState.leadGeneratorPostcode,
            workerId: result[0].id,
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private markFormGroupTouched(form: NgForm) {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  // make intersection of all available workers based on selected services
  private updateAvailableWorkers(): void {
    this.keepWorkerId = 0;
    this.workersAvailable = [];
    if (this.appointmentState.services.length > 0) {
      // Initialize it to first
      let wIntersection = this.workersIdsPerService.get(
        this.appointmentState.services[0]
      );

      for (let i = 1; i < this.appointmentState.services.length; i++) {
        wIntersection = this.intersect(
          wIntersection,
          this.workersIdsPerService.get(this.appointmentState.services[i])
        );
      }

      // Set current available workers with whole objects
      if (wIntersection?.length) {
        wIntersection.forEach((workerId) => {
          const tempWorker: UserDao = this.allWorkerObjects.get(workerId);
          if (tempWorker != null && tempWorker?.is_directly_bookable) {
            if (this.partner.is_round_robin_feature_enabled === 1) {
              if (tempWorker?.is_round_robin_enabled === 1) {
                this.workersAvailable.push(tempWorker);
              }
            } else {
              this.workersAvailable.push(this.allWorkerObjects.get(workerId));
            }
          }

          if (this.appointmentState.worker === workerId) {
            this.keepWorkerId++;
          }
        });

        if (this.keepWorkerId === 0 && this.appointmentState.worker !== 0) {
          this.appointmentState.worker = null;
        }
      }

      if (this.workersAvailable?.length && this.partner.show_worker_selection_on_date_step) {
        // Creating dummy object for anyone option
        const anyoneOption = new UserDao();
        anyoneOption.id = 0;

        // Manually preparing worker select options with anyone option on top
        this.workersAvailableWithAnyoneOption = [
          anyoneOption,
          ...this.workersAvailable
        ]
      } else {
        this.workersAvailableWithAnyoneOption = this.workersAvailable;
      }

    }
  }

  // To change state of previous button in calendar
  private updateCalendarPrevState(viewDate: Date): void {
    viewDate.getMonth() <= this.now.getMonth() &&
      viewDate.getFullYear() <= this.now.getFullYear()
      ? (this.disablePrev = true)
      : (this.disablePrev = false);
  }

  private updateAppointmentCustomFields() {
    let appointmentServices: number[] = [];
    this.cart.forEach(service => appointmentServices = appointmentServices.concat(service.servicesIds));
    appointmentServices = [...new Set(appointmentServices)];
    if (appointmentServices.length > 0) {
      this.isCustomFieldsLoaded = false;
      this.tempArray = [];
      // this.customFieldValues = {};
      this.formService
        .getAppointmentCustomFields(appointmentServices)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (appointmentCustomFields: CustomFieldsDao[]) => {
            if (!appointmentCustomFields) {
              appointmentCustomFields = [];
            }

            // Filter qualification questions
            appointmentCustomFields = appointmentCustomFields.filter(field => field.is_qualification_question === 0);

            for (const appointmentCustomField of appointmentCustomFields) {
              this.tempArray.push(appointmentCustomField);
            }

            const trimmedArray: CustomFieldsDao[] = [];
            const values: number[] = [];

            for (let y = 0, len = Object.keys(this.tempArray).length; y < len; y++) {
              const value = this.tempArray[y]['id'];
              if (values.indexOf(value) === -1 && !this.tempArray[y]['parent_custom_field_id']) {
                trimmedArray.push(this.tempArray[y]);

                // Add default option if it is select field
                if (
                  this.tempArray[y].type === 'select' ||
                  this.tempArray[y].type === 'card-select' ||
                  this.tempArray[y].type === 'image-select'
                ) {
                  if (
                    this.tempArray[y].custom_field_options.length &&
                    this.tempArray[y].custom_field_options[0]['id'] != null &&
                    this.tempArray[y].type !== 'card-select' &&
                    this.tempArray[y].type !== 'image-select' &&
                    this.tempArray[y].type !== 'select'
                  ) {
                    const tempCustomFieldOptions = new CustomFieldOptions();
                    tempCustomFieldOptions.id = null;
                    this.tempArray[y].custom_field_options.unshift(tempCustomFieldOptions);
                  }
                  if (!this.customFieldValues[this.tempArray[y].id]) {
                    if (this.tempArray[y].is_multiple_select === 1) {
                      const temp: string =
                        this.selectedJsonCustomFields &&
                          this.selectedJsonCustomFields[this.tempArray[y].id]
                          ? this.selectedJsonCustomFields[this.tempArray[y].id]
                          : undefined;
                      let finalValue: any[] = temp ? temp.split(',') : [];
                      if (finalValue?.length > 0) {
                        finalValue = finalValue.map((item) => Number(item));
                      }
                      this.customFieldValues[this.tempArray[y].id] = {
                        value: finalValue,
                        type: this.tempArray[y].type,
                      };
                    } else {
                      if (this.tempArray[y].type === 'select') {
                        if (
                          this.selectedJsonCustomFields &&
                          this.selectedJsonCustomFields[this.tempArray[y].id]
                        ) {
                          this.customFieldValues[this.tempArray[y].id] = {
                            value: Number(
                              this.selectedJsonCustomFields[this.tempArray[y].id]
                            ),
                            type: this.tempArray[y].type,
                          };
                        } else if (
                          this.customFieldValues[this.tempArray[y].id]
                        ) {
                          // Do nothing
                        } else {
                          this.customFieldValues[this.tempArray[y].id] = {
                            value: '',
                            type: this.tempArray[y].type,
                          };
                        }
                      } else if (
                        this.tempArray[y].type === 'card-select' ||
                        this.tempArray[y].type === 'image-select'
                      ) {
                        if (
                          this.selectedJsonCustomFields &&
                          this.selectedJsonCustomFields[this.tempArray[y].id]
                        ) {
                          this.customFieldValues[this.tempArray[y].id] = {
                            value: [
                              Number(
                                this.selectedJsonCustomFields[
                                this.tempArray[y].id
                                ]
                              ),
                            ],
                            type: this.tempArray[y].type,
                          };
                        } else if (
                          this.customFieldValues[this.tempArray[y].id]
                        ) {
                          // Do nothing
                        } else {
                          this.customFieldValues[this.tempArray[y].id] = {
                            value: [],
                            type: this.tempArray[y].type,
                          };
                        }
                      }
                    }
                  }
                } else if (this.tempArray[y].type === 'number-select') {
                  const optionIds: number[] = this.tempArray[
                    y
                  ].custom_field_options.map(
                    (item: CustomFieldOptions) => item.id
                  );
                  const optionValues: {
                    custom_field_option_id: number;
                    custom_field_option_value: any;
                  }[] = this.tempArray[y].custom_field_options.map(
                    (item: CustomFieldOptions) => {
                      item.option_value = item.number_default;
                      return {
                        custom_field_option_id: item.id,
                        custom_field_option_value: item.number_default,
                      };
                    }
                  );

                  this.customFieldValues[this.tempArray[y].id] = {
                    value: optionIds,
                    option_values: optionValues,
                    type: this.tempArray[y].type,
                  };
                } else if (this.tempArray[y].type === 'file') {
                  this.customFieldValues[this.tempArray[y].id] = {
                    type: 'file',
                    file_ids:
                      this.customFieldValues[this.tempArray[y].id]?.file_ids
                        ?.length > 0
                        ? this.customFieldValues[this.tempArray[y].id]?.file_ids
                        : [],
                  };
                } else {
                  if (
                    this.tempArray[y].type === 'text' ||
                    this.tempArray[y].type === 'textarea' ||
                    this.tempArray[y].type === 'radio' ||
                    this.tempArray[y].type === 'checkbox' ||
                    this.tempArray[y].type === 'date'
                  ) {
                    if (
                      this.selectedJsonCustomFields &&
                      this.selectedJsonCustomFields[this.tempArray[y].id]
                    ) {
                      this.customFieldValues[this.tempArray[y].id] =
                        this.selectedJsonCustomFields[this.tempArray[y].id];
                    } else {
                      this.customFieldValues[this.tempArray[y].id] = this
                        .customFieldValues[this.tempArray[y].id]
                        ? this.customFieldValues[this.tempArray[y].id]
                        : undefined;
                    }
                  }
                }
                values.push(value);
              } else {
                LoggerService.warn(
                  `CALENSO_WIDGET: Custom field ${this.tempArray[y]['id']} is ignored!`
                );
              }
            }

            // sorting of custom fields by position
            trimmedArray.sort(function (a, b) {
              return a.position - b.position;
            });

            this.appointmentCustomFields = trimmedArray;
            this.isCustomFieldsLoaded = true;
          },
          error: (err: HttpErrorResponse) => {
            LoggerService.error(err);
            this.isCustomFieldsLoaded = true;
          }
        });
    }
  }

  getCustomerCustomFieldSelectValue(): void {
    this.appointmentCustomFields
      .filter(customField => customField.is_hidden === 0)
      .forEach(customField => {
        this.getCustomerCustomFieldDependentValues(customField);
        if (customField.customFieldDependencies?.length) {
          customField.customFieldDependencies.forEach(dependentCustomField => {
            this.getCustomerCustomFieldDependentValues(dependentCustomField);
          });
        }
      });

    this.showSummaryDetails = true;
  }

  getCustomerCustomFieldDependentValues(customField: CustomFieldsDao): void {
    const customFieldsValue = { ...this.customFieldValues, ...this.qualificationQuestionsValues };

    let value = customFieldsValue[customField.id]?.value || customFieldsValue[customField.id];
    if (['select', 'radio', 'image-select', 'card-select'].includes(customField.type)) {
      value = Array.isArray(value) ? value : [value];
      const options = customField.custom_field_options.filter(option => value?.includes(option.id));
      options && (this.summaryDetails.customFieldValues[customField.id] = { options });
    } else if (customField.type === 'number-select') {
      const options = [];
      customField.custom_field_options.forEach(option => {
        const value = customFieldsValue[customField.id].option_values?.find((value: { custom_field_option_id: number; custom_field_option_value: number; }) => value.custom_field_option_id === option.id);
        options.push({ option, value: (value?.custom_field_option_value || 0) });
      });
      this.summaryDetails.customFieldValues[customField.id] = { options };
    } else if (!['file', 'information'].includes(customField.type)) {
      this.summaryDetails.customFieldValues[customField.id] = { value };
    }
  }

  /** Helpers */

  // Helper function for find available workers
  private intersect(a, b) {
    let t;
    if (b.length > a.length) {
      (t = b), (b = a), (a = t); // indexOf to loop over shorter
    }
    return a.filter(function (e) {
      return b.indexOf(e) > -1;
    });
  }

  // Init form state and cart based on local storage values
  private initFormState(): void {
    this.setInitStateLSName();
    this.setInitCartLSName();
    this.setInitUserLSName();
    this.setInitCustomFieldsLSName();
    this.setInitInternalComment();
  }

  private setInitInternalComment(): void {
    // prefill internal_comment if present
    if (this.internalComment) {
      Object.assign(this.userState, {
        internal_comment: this.internalComment
      });
    }
  }

  private setInitCartLSName(): void {
    this.localStorageService.getItem(this.CART_LS_NAME, (savedCartState) => {
      if (savedCartState) {
        this.cart = savedCartState;
      }
    });
  }

  private setInitUserLSName(): void {
    this.localStorageService.getItem(this.USER_LS_NAME, (savedUserState) => {
      if (savedUserState) {
        this.userState = savedUserState;
      }
    });
  }

  private setInitCustomFieldsLSName(): void {
    this.localStorageService.getItem(this.CUSTOMFIELDS_LS_NAME, (savedCustomFieldsState) => {
      if (savedCustomFieldsState) {
        this.customFieldValues = savedCustomFieldsState;
      }
    });
  }

  private scrollToAppointmentComponent(): void {
    if (this.widgetConf?.scroll_to_element_feature_is_enabled === 1) {
      const element = document.getElementById('appointment-component');
      element.scrollIntoView();
    }
  }

  private resetDataBeforeLoadingSummaryPage() {
    this.isStripeEnabled = false;
    this.isOnlinePaymentMandatory = false;
    this.isBexioEnabled = false;
    this.isStoreEnabled = false;
    this.isLexOfficePaymentTypeEnabled = false;
    this.isPaypalEnabled = false;
    this.isSaferpayEnabled = false;
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.priceAfterCouponReduction = undefined;
    this.stripePaymentMethodId = undefined;
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;
  }

  payUsingSaferpay(): void {
    if (this.appointmentForm.valid && this.formService.imageUploaded === true) {
      this.showFullscreenOverlay = true;
      this.paymentService.initiateSaferpaySubject.next(true);
    } else {
      this.markFormGroupTouched(this.appointmentForm);
      this.isStripeBookButtonValidated();
    }
  }

  closeSaferpayOverlay(): void {
    this.showFullscreenOverlay = false;
    this.onResetSaferpayEvent('');
    this.paymentService.handleSaferpayBackgroundTriggerManually.next(true);
  }

  prepareAnalyticsPayload(): AppointmentAnalyticsDao {
    const payload = new AppointmentAnalyticsDao();
    const store: { [key: string]: string | number } = {};
    const worker: { [key: string]: string | number } = {};
    const servicesName = [];

    // get store details is present
    if (this.appointmentState?.store && this.stores?.length > 0) {
      const foundStore = this.stores.find(_store => _store.id === this.appointmentState.store);
      store.id = this.appointmentState.store;
      store.name = foundStore?.name || undefined;
    }

    // get worker details is present
    if (this.appointmentState?.worker && this.workersAvailable?.length > 0) {
      const foundWorker = this.workersAvailable.find(_worker => _worker.id === this.appointmentState.worker);
      worker.id = this.appointmentState.worker;
      worker.email = (foundWorker?.email || undefined);
      worker.name = (foundWorker?.resource_name || undefined);
    }

    // prepare service name string
    if (this.appointmentState?.services && this.appoinrmentServicesAvailable?.length > 0) {
      this.appoinrmentServicesAvailable.forEach(service => {
        if (this.appointmentState.services.includes(Number(service.id))) {
          servicesName.push(service.name);
        }
      });
    }

    // prepare final payload object
    if (this.appointmentState?.services) {
      payload.appointment_service_id = this.appointmentState.services.join(',');
    }

    if (servicesName.length) {
      payload.appointment_service_name = servicesName.join(',');
    }

    if (worker?.id) {
      payload.worker_id = worker.id;
      payload.resource_name = worker?.name;
      payload.worker_email = worker?.email;
    }

    if (store?.id) {
      payload.store_id = store.id;
      payload.store_name = store?.name;
    }

    return payload;
  }

  resetQualificationQuestionValues(questions: CustomFieldsDao[]): void {
    this.localStorageService.removeItem(this.QUALIFICATIONQUESTION_LS_NAME, () => void 0);
    this.qualificationQuestionsValues = {};

    questions.forEach(question => {
      if (['select', 'card-select', 'image-select'].includes(question.type)) {
        this.qualificationQuestionsValues[question.id] = {
          value: question.is_multiple_select ? [] : '',
          type: question.type
        };
      } else if (question.type === 'number-select') {
        const optionIds = question.custom_field_options.map(option => Number(option.id));
        const optionValues: {
          custom_field_option_id: number;
          custom_field_option_value: any;
        }[] = question.custom_field_options.map(option => {
          option.option_value = option.number_default;
          return {
            custom_field_option_id: option.id,
            custom_field_option_value: option.number_default,
          };
        });

        this.qualificationQuestionsValues[question.id] = {
          value: optionIds,
          option_values: optionValues,
          type: question.type
        };
      } else if (question.type === 'file') {
        this.qualificationQuestionsValues[question.id] = {
          file_ids: [],
          type: question.type
        };
      } else {
        this.qualificationQuestionsValues[question.id] = '';
      }
    });
  }

  submitQualificationQuestions(isSubmitted: boolean): void {
    if (this.selectedAppointmentServiceIds?.length) {
      return;
    }

    if (isSubmitted) {
      if (this.appointmentService.mappedServiceIds.length) {
        if (this.appointmentService.mappedServiceIds.length === 1) {
          this.appointmentState.services = [this.appointmentService.mappedServiceIds[0]];

          const storeParams: {
            appointment_service_ids: number[],
            worker_id?: number
          } = {
            appointment_service_ids: [this.appointmentService.mappedServiceIds[0]],
          };

          if (this.selectedWorkerId) {
            storeParams.worker_id = Number(this.selectedWorkerId);
          }

          this.formService.getStores(storeParams).pipe(takeWhile(() => this.alive)).subscribe({
            next: stores => {
              if (stores?.length) {
                if (!this.globals.isInternal) {
                  this.stores = stores.filter(store => store.has_only_internal_appointment_service === 0);
                } else {
                  this.stores = stores;
                }
              } else {
                this.stores = [];
                if (storeParams?.worker_id !== undefined) {
                  this.showWorkerIsNotBookableError = true;
                }
              }

              this.afterAppointmentServiceToggled();
              this.setupStoreSelection(this.stores);

              // In case single service is operated at more than single store do not jump to worker step instead
              // let user to stay on first step and emit event to reset the appointment category wise list based
              // on selected answers mapped services
              if (this.stores?.length > 1) {
                this.appointmentService.resetAppointmentCategoryWiseListEvent.emit();
              } else {
                // Navigate user to Worker step when selected answers has only single mapped service
                this.navigateTo(this.appointmentConstant.WORKER, false);
              }

              this.hasQualificationQuestionsAnswered = true;
            },
            error: (err) => LoggerService.error(err.message)
          });
        } else {
          // Emitting event to reset the appointment category wise list based on selected answers mapped services
          this.appointmentService.resetAppointmentCategoryWiseListEvent.emit();
          this.hasQualificationQuestionsAnswered = true;
        }
      } else {
        this.hasQualificationQuestionsAnswered = true;
      }
    } else {
      this.hasQualificationQuestionsAnswered = true;
    }
  }

  showWorkersListOnDateStepFn(): void {
    if (Number(this.partner.show_worker_selection_on_date_step) === 1) {
      this.showWorkersListOnDateStep = !this.showWorkersListOnDateStep;
    }
  }

  customWorkerSearchFn(query: string, worker: WorkerDao): boolean {
    const subTitle = Number(worker?.is_resource) === 1 ? worker?.description : worker?.job_title;
    const searchData = `${worker?.resource_name} (${subTitle})`;
    return searchData?.toLowerCase().includes(query?.toLowerCase());
  }
}
