import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EVENT_CONSTANT } from '../../../constants/event-constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { EVENT_PAGINATION_LIMIT, GTM_EVENTS, WIDGET_CONSTANTS, WINDOW_LISTENER_CONSTANTS } from '../../../constants/widget-constants';
import { CustomFieldOptions, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { CouponDbModel } from '../../../db-models/coupon-db.model';
import { EventDao, EventPaginationDao } from '../../../db-models/event-dao';
import { EventDateDao } from '../../../db-models/event-date-dao';
import { PayPalOrderDetailModel, PayPalSettingsDbModel } from '../../../db-models/partner-setting.model';
import { CustomBookingMessageTemplate, EventsAnalyticsDao, WidgetBookingInfoDao } from '../../../db-models/widget-conf-dao';
import { EventBookItem, EventCartItem, GuestModel } from '../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../models/global';
import { SaferpayPaymentDetailsDbModel } from '../../../models/saferpay.model';
import { EventState, UserState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomerPrefillDataModel } from '../../../models/widget-conf';
import { CustomEventService } from '../../../services/custom-event.service';
import { EventCartService } from '../../../services/event-cart.service';
import { FormsService } from '../../../services/forms.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { PaymentService } from '../../../services/payment.service';
import { UtilService } from '../../../services/util.service';
import { WidgetUtilService } from '../../../services/widget-util.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { FinalpageComponent } from '../../../shared/components/finalpage/finalpage.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { CalendarPickerComponent } from '../../common/calendar-picker/calendar-picker.component';
import { CwPaymentMethodsComponent } from '../../common/cw-payment-methods/cw-payment-methods.component';
import { PersonalDataFormComponent } from '../../common/personal-data-form/personal-data-form.component';
import { CwCardHeaderComponent } from '../../common/theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../../common/theme/cw-new-card/cw-new-card.component';
import { EventAgendaViewComponent } from './event-agenda-view/event-agenda-view.component';
import { EventCardComponent } from './event-card/event-card.component';
import { EventCartOverviewComponent } from './event-cart-overview/event-cart-overview.component';
import { EventNavigationBarComponent } from './event-navigation-bar/event-navigation-bar.component';
import { EventSlotComponent } from './event-slot/event-slot.component';

const component = [AlertComponent, FinalpageComponent, ButtonComponent, LoaderComponent, EventNavigationBarComponent, EventCardComponent, CalendarPickerComponent, EventSlotComponent, CwNewCardComponent, CwCardHeaderComponent, PersonalDataFormComponent, CwPaymentMethodsComponent, EventCartOverviewComponent, EventAgendaViewComponent];
const module = [FormsModule, TranslateModule];
const pipe = [DatePipe, TranslationPipe, TrustHtmlPipe];

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  standalone: true,
  imports: [...component, ...module, ...pipe],
})
export class EventComponent implements OnInit {

  @Input() shownMsg = [];
  @Input() hiddenMsg = [];
  @Input() partner: Partner;
  @Input() lang: string;
  @Input() globals: GlobalObjects;
  @Input() widgetBookingInfo: WidgetBookingInfoDao;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() widgetConf: any;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() utmSource: string;
  @Input() utmMedium: string;
  @Input() utmCampaign: string;
  @Input() utmContent: string;
  @Input() utmTerm: string;
  @Input() meeting_type_id: string;
  @Input() customer_notification_preference: string;
  @Input() uiMode: string;
  @Input() hideGridSwitcher = 'false';
  @Input() langSwitcher = true;
  @Input() selectedJsonCustomerDetail: CustomerPrefillDataModel;
  @Input() set filterStores(value: string[]) {
    // Check if the value is an array & feature is enabled; if not, assign an empty array
    this.eventFilterStores = (Array.isArray(value) && Number(this.partner?.is_group_appointment_store_feature_enabled)) ? value : [];
  }
  @Input() set filterCategories(value: string[]) {
    // Check if the value is an array & feature is enabled; if not, assign an empty array
    this.eventFilterCategories = (Array.isArray(value) && Number(this.partner?.is_group_appointment_category_feature_enabled)) ? value : [];
  }

  private localStorageService = inject(LocalStorageService);
  private formsService = inject(FormsService);
  private eventCartService = inject(EventCartService);
  private utilService = inject(UtilService);
  private widgetUtilService = inject(WidgetUtilService);
  private translateService = inject(TranslateService);
  private partnerService = inject(PartnerService);
  private helperService = inject(HelperService);
  private customEventService = inject(CustomEventService);
  private paymentService = inject(PaymentService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private destroyRef = inject(DestroyRef);
  private elementRef = inject(ElementRef);

  readonly widgetConstant = WIDGET_CONSTANTS;
  readonly templateContent = TRANSLATION_TEMPLATES;
  protected maxAgendaViewDate = this.utilService.dateToStr(new Date(new Date().setFullYear(new Date().getFullYear() + 5)));
  protected currentDate = this.utilService.dateToStr(new Date());
  protected eventFilterStores: string[] = [];
  protected eventFilterCategories: string[] = [];
  private hasSecretEvents = false;

  paginationData: EventPaginationDao = {
    limit: EVENT_PAGINATION_LIMIT,
    offset: 0,
    totalCounts: 0,
    page: 0,
    hideLoadMoreButton: true,
    isLoading: false
  };
  viewDate: Date;
  old_select_calendar: CalendarMonthViewDay = null;
  disablePrev = true;
  days: CalendarMonthViewDay[] = [];
  calendarLoaded = false;
  terminLoaded = 1;
  noEvents = false;
  bookingStarted = false;
  booked = false; // Call final page with message if true
  isCustomFieldsLoaded = false;
  customFieldValues: {
    [key: number]:
    | {
      type: string;
      value: any;
      option_values: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }
    | any;
  } = {};
  appointmentSlotGhostElements = [1, 2, 3, 4, 5, 6];
  tempArray: CustomFieldsDao[] = [];
  finalPage: boolean;
  cart: EventCartItem[] = [];
  bookedItems: EventCartItem[] = [];
  customerSelected: object;
  STATE_LS_NAME;
  CART_LS_NAME;
  USER_LS_NAME;
  FIRST_FREE_DATE;
  CUSTOMFIELDS_LS_NAME;
  BOOKED_ITEMS_LS_NAME;
  eventConstants = EVENT_CONSTANT;
  events: EventDao[];
  eventOnDate: EventDateDao[] = [];
  eventOnYear: EventDateDao[] = [];
  calendarEvents: CalendarMonthViewDay[] = [];
  environment: any;
  eventCustomFields: CustomFieldsDao[] = [];
  baseUrl = environment.baseUrl;
  partnerName;
  fullPartnerName;
  formReseted = false;
  viewMode = EVENT_CONSTANT.EVENTS;
  noFreeDates: boolean;
  noFreeSlots: boolean;
  firstFreeDate;
  excludeWeekDays = [0, 1, 2, 3, 4, 5, 6];
  showAds = false;
  successMessage: string;
  courtesyForm: string;
  leadTime: number;
  totalTaxPrice: number;
  isWaitingListBookingMessageEnabled = false;
  showBookingLimitReachedMessageOnFinalPage = false;
  eventState: EventState = {
    eventId: null,
    date: null,
    additional_guests_enabled: 0,
    number_allowed_additional_guests: 0,
    is_multi_day: 0,
    has_one_slot: 0,
    slots: [],
    day: null,
    foundedSlot: null,
  };
  userState: UserState = {
    forename: null,
    lastName: null,
    gender: null,
    eMail: null,
    phone: null,
    mobile: null,
    customerId: null,
    customerUuid: null,
    street: null,
    zip: null,
    city: null,
    meeting_type_id: null,
    customer_notification_preference: null,
  };
  additionalGuests: GuestModel[] = [];
  availableEventDates: string[] = [];
  availableEventDatesCount: number;
  stripePaymentMethodId: string;
  totalCartDuration = 0;
  totalCartPrice = 0;
  noActiveEvents = false;
  noFilterEvents = false;
  screenType = 'GRID';
  paymentType: string;
  coupon: CouponDbModel;
  finalCouponDiscount = 0;
  couponDisableConfirmButton = false;
  isInvalidCoupon = false;
  bookingErrorMessage: string | string[];
  priceAfterCouponReduction: number;
  paypalSetting: PayPalSettingsDbModel;
  isStripeEnabled = false;
  isOnlinePaymentMandatory = false;
  isEventOnlinePaymentEnabled = 0;
  isBexioEnabled = false;
  isStoreEnabled = false;
  isLexOfficePaymentTypeEnabled = false;
  isPaypalEnabled = false;
  isSaferpayEnabled = false;
  nextButtonTemplate: CustomBookingMessageTemplate;
  previousButtonTemplate: CustomBookingMessageTemplate;
  bookButtonTemplate: CustomBookingMessageTemplate;
  dateLabelTemplate: CustomBookingMessageTemplate;
  showBookableDateNotAvailableIssue = false;
  automaticBookableDateProcessIsEnabled = false;
  automationBookingDatesLimitReached = false;
  hideSlotStep = false;
  widgetTemplates: CustomBookingMessageTemplate[] = [];
  calendarSlotsCountList: { date: Date; slotsCount: number }[] = [];
  showEventsListing = true;
  paypalOrderDetail: PayPalOrderDetailModel;
  saferpayPaymentDetails: SaferpayPaymentDetailsDbModel;
  hideBookingButton = false;
  disableBookingButton = false;
  successFullBookings: {
    landingPageLink: string;
    bookingUUID: string;
  }[] = [];
  manageAppointmentNowTemplate: CustomBookingMessageTemplate;
  downloadBookingDetailTemplate: CustomBookingMessageTemplate;
  widgetSuccessPageEventBookingLimitReachedTemplate: CustomBookingMessageTemplate;
  showSaferpayButtonLink = false;
  showFullscreenOverlay = false;
  calendarDate = false;
  agendaViewSelectedDate = this.utilService.dateToStr(new Date());

  @ViewChild('eventsSection') eventsSectionRef: ElementRef;
  @ViewChild('eventForm') eventForm: NgForm;

  constructor() {
    this.translateService.onLangChange.subscribe(language => {
      this.lang = language.lang;
      this.createSuccessMessage(language.lang);
    });
  }

  ngOnInit() {
    // set showEventsListing flag to false to prevent event listing flickering
    // when widget is configured for specific event
    if (this.globals?.eventId !== null && this.events?.length) {
      this.showEventsListing = false;
    }

    const date = new Date();
    this.viewDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.availableEventDates = [];

    // Set local storage fields
    this.setLocalStorageVariables();

    this.noFreeDates = true;
    this.hiddenMsg[0] = false;
    this.partnerName = this.partner.bookingName;
    this.fullPartnerName = this.partner.name;
    this.environment = environment;
    this.widgetUtilService.getWidgetConf().subscriptionTypeId === 1 && (this.showAds = true);
    this.courtesyForm = this.widgetUtilService.getWidgetConf().courtesyForm;
    this.leadTime = this.widgetUtilService.getWidgetConf().eventLeadTime;

    this.getWidgetTemplates();

    this.customEventService.hideBookingButtonEvent.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((buttonState: { hide: boolean }) => this.hideBookingButton = buttonState.hide);

    this.customEventService.childCustomFieldsLoadedEvent.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(customFieldsLoaded =>
        this.tempArray = this.tempArray.concat(customFieldsLoaded)
      );
    this.customEventService.removeChildCustomFieldsEvent.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(ids => {
        this.tempArray = this.tempArray.filter(customField => ids.indexOf(customField.id) === -1)
      });

    if (this.uiMode) {
      if (this.uiMode === 'list') {
        this.screenType = 'LIST';
      } else if (this.uiMode === 'grid') {
        this.screenType = 'GRID';
      } else {
        this.screenType = 'LIST';
      }
    }
  }

  resetLandingPageLink(): void {
    this.bookingErrorMessage = undefined;
    this.successFullBookings = [];
  }

  createSuccessMessage(lang: string): void {
    const eventBookingInfoTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_BOOKING_INFO_EVENT
    );
    eventBookingInfoTemplate && (eventBookingInfoTemplate.is_multi_language = 1);
    this.successMessage = this.utilService.getBookingInfoMessage(eventBookingInfoTemplate, lang);

    if (!this.successMessage) {
      const courtesyFormConstant = this.courtesyForm === 'Du' ? 'events.finalpage.successMessageDu' : 'events.finalpage.successMessageSie';
      this.translateService.get(courtesyFormConstant, { leadTime: this.leadTime })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(res =>
          this.successMessage = res
        );
    }
    this.customEventService.finalSuccessMessageChangedEvent.emit(this.successMessage);
  }

  setLocalStorageVariables(): void {
    this.STATE_LS_NAME = EVENT_CONSTANT.EVENT_STATE_LS + this.partner.bookingName;
    this.CART_LS_NAME = EVENT_CONSTANT.EVENT_CART_LS + this.partner.bookingName;
    this.USER_LS_NAME = EVENT_CONSTANT.EVENT_GLOBAL_USER_LS + this.partner.bookingName;
    this.FIRST_FREE_DATE = EVENT_CONSTANT.FIRST_FREE_DATE + this.partner.bookingName;
    this.CUSTOMFIELDS_LS_NAME = EVENT_CONSTANT.EVENT_CUSTOM_FIELDS_LS + this.partner.bookingName;
    this.BOOKED_ITEMS_LS_NAME = EVENT_CONSTANT.EVENT_BOOKING_ITEMS_LS + this.partner.bookingName;
  }

  loadEvents(): void {
    this.noActiveEvents = this.noFilterEvents = this.noEvents = false;
    this.formsService.getEvents(this.paginationData.limit, this.paginationData.offset, this.eventFilterStores, this.eventFilterCategories)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: eventsData => {
          this.paginationData.totalCounts = eventsData?.total_count || 0;
          this.hasSecretEvents = eventsData?.has_secret || false;
          this.paginationData.hideLoadMoreButton = this.paginationData.totalCounts <= (Number(this.paginationData.limit) * (Number(this.paginationData.page) + 1))

          if (this.paginationData.page === 0) {
            this.events = eventsData?.events || [];

            if (this.globals?.eventId) {
              const globalEventFound = this.events?.find(event => Number(event.id) === Number(this.globals.eventId) || event.uuid === this.globals.eventId);
              if (!globalEventFound) {
                this.formsService.getEventByIdOrUuid(this.globals.eventId)
                  .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    finalize(() => this.handleWidgetStates())
                  )
                  .subscribe({
                    next: eventDetails => eventDetails?.status && this.events.push(eventDetails),
                    error: (error: HttpErrorResponse) => LoggerService.error(error)
                  });
              } else {
                this.handleWidgetStates();
              }
            } else {
              this.handleWidgetStates();
            }
          } else {
            if (eventsData?.events) {
              // If global event is presnet then filter it out from response as we have already loaded it
              let newlyLoadedEvents: EventDao[] = [];
              if (this.globals?.eventId) {
                newlyLoadedEvents = eventsData.events?.filter(event => Number(event.id) === Number(this.globals.eventId) || event.uuid === this.globals.eventId);
              } else {
                newlyLoadedEvents = eventsData.events;
              }

              this.events = [...this.events, ...newlyLoadedEvents] as EventDao[];
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleWidgetStates();
          LoggerService.error(error);
        }
      });
  }

  eventFilterChangeEvents(params: [[], []]): void {
    this.eventFilterStores = params[0];
    this.eventFilterCategories = params[1];
    this.paginationData = {
      limit: EVENT_PAGINATION_LIMIT,
      offset: 0,
      totalCounts: 0,
      page: 0,
      hideLoadMoreButton: true,
      isLoading: false
    };
    this.loadEvents();
  }

  loadMoreEvents(paginationData: EventPaginationDao): void {
    this.paginationData = paginationData;
    this.loadEvents();
  }

  handleWidgetStates(): void {
    if (this.events.length) {
      !this.uiMode && (this.screenType = 'LIST');
      this.readLocalStorage();
    } else {
      if (this.eventFilterCategories.length || this.eventFilterStores.length) {
        this.hasSecretEvents ? (this.noActiveEvents = true) : (this.noFilterEvents = true);
      } else if (this.hasSecretEvents) {
        this.noActiveEvents = true;
      } else {
        this.noEvents = true;
      }
    }
  }

  autoLoadBookableDates() {
    const maxDate = moment().add(this.partner.future_booking_threshold_events, 'days').toDate();
    const minDate = moment().startOf('month').toDate();
    this.automaticBookableDateProcessIsEnabled = true;
    const isNextPressed = this.customEventService.eventCalendarIsNextPressed;
    const isPreviousPressed = this.customEventService.eventCalendarIsPreviousPressed;

    LoggerService.log('[calendar][button state][next]', isNextPressed);
    LoggerService.log('[calendar][button state][previous]', isPreviousPressed);

    const currMonthDate = new Date(
      this.viewDate.getFullYear(),
      ([true, undefined].includes(isNextPressed) ? this.viewDate.getMonth() + 1 : this.viewDate.getMonth() - 1),
      1
    );

    if (currMonthDate >= minDate && currMonthDate < maxDate) {
      this.viewDate = new Date(
        this.viewDate.getFullYear(),
        ([true, undefined].includes(isNextPressed) ? this.viewDate.getMonth() + 1 : this.viewDate.getMonth() - 1),
        1
      );

      const now = new Date();
      this.viewDate.getMonth() <= now.getMonth() &&
        this.viewDate.getFullYear() <= now.getFullYear() ? (this.disablePrev = true) : (this.disablePrev = false);
      this.eventOnDate = [];
      this.calendarLoaded = false;
    } else {
      this.calendarLoaded = true;
      this.automationBookingDatesLimitReached = true;
      this.calendarDate = true;

      // reset flags of next and previous calendar button
      LoggerService.log('[Reset flags of next and previous calendar button]');
      this.customEventService.eventCalendarIsNextPressed = undefined;
      this.customEventService.eventCalendarIsPreviousPressed = undefined;
    }
  }

  // Before calendar render
  markScheduleForEvent({ body }: { body: CalendarMonthViewDay[] }): void {
    this.calendarEvents.length = 0;

    body.forEach((day) => {
      this.days.push(day);
      day.cssClass = 'cal-disabled';
      day.isWeekend = true;
    });

    const dayOfMonth = this.utilService.dateToStr(
      new Date(body[10].date.getFullYear(), body[10].date.getMonth(), 1)
    );
    const maxDate: Date = moment()
      .add(this.partner.future_booking_threshold_events, 'days')
      .toDate();

    this.formsService
      .getEventSlotsByEventIdAndDate(this.eventState.eventId, dayOfMonth)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: eventSlots => {
          const dateById = eventSlots.slotDates as string[];
          let slotIsSelected = false;
          if (!dateById || dateById.length === 0) {
            this.calendarLoaded = true;
            this.showBookableDateNotAvailableIssue = true;
            LoggerService.log('[disable_automatic_search]', this.partner.disable_automatic_search);
            if (Number(this.partner.disable_automatic_search) !== 1) {
              this.automaticBookableDateProcessIsEnabled = true;
            }
            if (this.automaticBookableDateProcessIsEnabled) {
              LoggerService.log('[autoLoadBookableDates]', 'auto checking for events');
              this.autoLoadBookableDates();
            }
            return;
          } else {
            this.automaticBookableDateProcessIsEnabled = false;
            this.showBookableDateNotAvailableIssue = false;

            // reset flags of next and previous calendar button
            LoggerService.log('[Reset flags of next and previous calendar button]');
            this.customEventService.eventCalendarIsNextPressed = undefined;
            this.customEventService.eventCalendarIsPreviousPressed = undefined;
          }
          this.availableEventDates = dateById;
          this.availableEventDatesCount = eventSlots.slotsCount;

          let selectedDate: CalendarMonthViewDay<any>;
          this.calendarLoaded = false;

          body.forEach((date) => {
            const fDate = this.utilService.dateToStr(date.date);
            for (let i = 0; i < Object.keys(dateById).length; i++) {
              if (dateById[i] === fDate) {
                date.isWeekend = false;
                date.cssClass = date.cssClass.replace('cal-disabled', '');
                this.calendarEvents.push(date);
                if (dateById.length === 1) {
                  selectedDate = date;
                }
              }

              if (i === Object.keys(dateById).length - 1) {
                this.calendarLoaded = true;
                this.calendarDate = true;
              }
            }

            if (
              this.eventState.date != null &&
              date.date.toDateString() ===
              this.eventState.date.toDateString() &&
              !slotIsSelected
            ) {
              slotIsSelected = true;
              date.cssClass = 'cal-selected';
              this.eventClicked({ day: date });
            }

            if (selectedDate && !slotIsSelected) {
              slotIsSelected = true;
              selectedDate.cssClass = 'cal-selected';
              this.eventClicked({ day: selectedDate });
            }
            if (date.date > maxDate) {
              date.cssClass = 'cal-disabled';
              date.isWeekend = true;
            }
          });

          if (!slotIsSelected && dateById?.length > 0) {
            body.forEach((date) => {
              const fDate = this.utilService.dateToStr(date.date);
              if (dateById[0] === fDate && new Date(dateById[0]) <= maxDate) {
                date.isWeekend = false;
                this.calendarEvents.push(date);
                selectedDate = date;
                slotIsSelected = true;
                date.cssClass = 'cal-selected';
                this.eventClicked({ day: date });
              }
            });
          }

          this.calendarLoaded = true;
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  // On next prev month
  viewDateChanged(event: { viewDate: Date; isNext: boolean }): void {
    this.customEventService.eventCalendarIsNextPressed = event.isNext;
    this.customEventService.eventCalendarIsPreviousPressed = !event.isNext;
    this.automaticBookableDateProcessIsEnabled = false;
    this.showBookableDateNotAvailableIssue = false;
    this.automationBookingDatesLimitReached = false;
    this.terminLoaded = 0;
    this.calendarLoaded = false;
    const now = new Date();
    event.viewDate.getMonth() <= now.getMonth() &&
      event.viewDate.getFullYear() <= now.getFullYear()
      ? (this.disablePrev = true)
      : (this.disablePrev = false);
    this.eventOnDate = [];

    this.viewDate = event.viewDate;
    this.eventState.date = null;
  }

  eventClicked({ day }: { day: CalendarMonthViewDay }) {
    this.terminLoaded = 0;

    if (day.isWeekend || day.date === this.eventState.date) {
      this.terminLoaded = 1;
      return false;
    } else {
      const fDate = this.utilService.dateToStr(day.date);
      this.eventOnDate = [];
      this.eventState.date = day.date;
      this.eventState.day = day;

      day.cssClass = 'cal-selected';
      day.badgeTotal = 0;

      if (this.old_select_calendar != null) {
        this.old_select_calendar.cssClass = '';
      }

      this.old_select_calendar = day;
      this.formsService
        .getSlots(this.eventState.eventId, fDate)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: eventOnDate => {
            if (!eventOnDate) {
              eventOnDate = [];
            }
            for (const item of eventOnDate) {
              if (item != null) {
                this.eventOnDate.push(item);
              }
            }
            this.bookIfOnlyOneEventIsAvailable(eventOnDate);
            this.terminLoaded = 1;
          },
          error: (err) => LoggerService.error(err.message)
        });
    }
  }

  bookIfOnlyOneEventIsAvailable(eventOnDate: EventDateDao[]): void {
    if (eventOnDate?.length === 1) {
      if (this.availableEventDatesCount === 1 || (this.availableEventDates.length === 1 && this.partner.is_widget_event_single_slot_preselect_enabled === 1)) {
        this.totalCartDuration = Number(0);
        this.totalCartPrice = Number(0);
        this.cart = [];
        const cartSupported =
          this.widgetUtilService.getWidgetConf().cartSupported;
        if (!this.displayRegularData(eventOnDate[0])) {
          if (this.displayWaitingData(eventOnDate[0])) {
            eventOnDate[0].isWaitingList = true;
          }
        }
        if (!cartSupported) {
          this.addToCart(eventOnDate[0], false);
        } else {
          this.addToCart(eventOnDate[0], true);
        }
      }
    }
  }

  onAutoSelectSlotAndJumpToSummaryEvent() {
    this.hideSlotStep = true;
    const cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;
    const eventInfo = this.eventState.foundedSlot;
    this.eventCartService.addToCart(
      eventInfo,
      this.eventState,
      this.cart,
      this.CART_LS_NAME,
      this.events,
      cartSupported,
      (cart: EventCartItem[]) => {
        this.cart = cart;
        this.totalCartDuration = this.eventCartService.totalCartDuration;
        this.totalCartPrice = this.eventCartService.totalCartPrice;
        this.calculateCoupon();
        this.navigateTo(EVENT_CONSTANT.SUMMARY, true);
      }
    );
  }

  // Cart functions
  updateCart(eventData: any): void {
    if (eventData.event.target.checked) {
      const cartSupported = this.widgetUtilService.getWidgetConf().cartSupported;
      this.addToCart(eventData.eventInfo, (cartSupported ? true : false));
    } else {
      this.removeFromCart(eventData.eventInfo['id']);
    }
  }

  addToCart(eventInfo: EventDateDao, overwrite: boolean): void {
    const eventStateDate = this.eventState.date;

    this.eventCartService.addToCart(
      eventInfo,
      this.eventState,
      this.cart,
      this.CART_LS_NAME,
      this.events,
      overwrite,
      (cart: EventCartItem[]) => {
        this.cart = cart;
        this.totalCartDuration = this.eventCartService.totalCartDuration;
        this.totalCartPrice = this.eventCartService.totalCartPrice;

        if (!overwrite) {
          this.calendarSlotsCountList = [];
        }

        if (this.calendarSlotsCountList.length === 0) {
          this.calendarSlotsCountList = [{ date: eventStateDate, slotsCount: 1 }];
        } else {
          let calendarSlotsCount = this.calendarSlotsCountList.find(calendarSlot => eventStateDate?.toString() === calendarSlot.date?.toString());

          if (calendarSlotsCount) {
            calendarSlotsCount.slotsCount++;
          } else {
            calendarSlotsCount = { date: eventStateDate, slotsCount: 1 };
            this.calendarSlotsCountList.push(calendarSlotsCount);
          }

          this.calculateCoupon();
        }
      }
    );
  }

  removeFromCart(cartItemId: number): void {
    const eventStateDate: Date = this.eventState.date;
    this.eventCartService.removeFromCart(
      cartItemId,
      this.CART_LS_NAME,
      this.cart,
      (cart: EventCartItem[]) => {
        this.cart = cart;
        this.totalCartDuration = this.eventCartService.totalCartDuration;
        this.totalCartPrice = this.eventCartService.totalCartPrice;
        if (this.calendarSlotsCountList.length >= 0) {
          const calendarSlotsCount: {
            date: Date;
            slotsCount: number;
          } = this.calendarSlotsCountList.find(
            (calendarSlot: { date: Date; slotsCount: number }) => {
              return (
                eventStateDate.toString() === calendarSlot.date.toString()
              );
            }
          );

          if (calendarSlotsCount && calendarSlotsCount.slotsCount > 1) {
            calendarSlotsCount.slotsCount--;
          } else {
            this.calendarSlotsCountList = this.calendarSlotsCountList.filter(
              (calendarSlot: { date: Date; slotsCount: number }) => {
                return (
                  eventStateDate.toString() !==
                  calendarSlot.date.toString()
                );
              }
            );
            this.eventState.day.cssClass = '';
            this.eventState.day.badgeTotal = 0;
          }
        }
        this.calculateCoupon();
        this.customEventService.cartItemsChangeEvent.emit({
          eventCart: this.cart,
          type: WIDGET_CONSTANTS.EVENT,
        });
      }
    );
  }

  // Change view and update local storage
  navigateTo(target: string, isFirstTime?: boolean): void {
    // to prevent event listing flickering when
    // widget is configured for specific event
    if (!isFirstTime) {
      this.showEventsListing = true;
    }

    if (
      !isFirstTime &&
      this.eventsSectionRef
    ) {
      this.eventsSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    this.automaticBookableDateProcessIsEnabled = false;
    this.showBookableDateNotAvailableIssue = false;
    this.automationBookingDatesLimitReached = false;
    this.coupon = undefined;
    this.finalCouponDiscount = 0;

    this.calendarLoaded = false;
    if (!isFirstTime) {
      this.scrollToEventComponent();
    }

    // trigger start event on first user interaction
    this.customEventService.triggerGroupApoointmentStartEvent();

    if (target === 'events') {
      this.globals.eventId = null;
      this.eventState = {
        eventId: null,
        date: null,
        additional_guests_enabled: 0,
        number_allowed_additional_guests: 0,
        is_multi_day: 0,
        has_one_slot: 0,
        slots: [],
        day: null,
        foundedSlot: null,
      };
      this.utilService.updateLocalStorage(
        this.STATE_LS_NAME,
        this.eventState
      );
      this.viewMode = target;
      this.googleAnalyticsService.emitBookingEvent(
        GTM_EVENTS.EVENT_BOOKING_STEP_SELECT_EVENT,
        this.prepareAnalyticsPayload()
      );
    } else if (target === 'date') {
      if (this.eventState.is_agenda_view_enabled === 1) {
        this.viewMode = target;
        this.handelAgendaView();
      } else {
        this.terminLoaded = 0;
        this.eventOnDate = [];
        const today = new Date();
        const dayOfMonth = this.utilService.dateToStr(
          new Date(today.getFullYear(), today.getMonth(), 1)
        );
        this.formsService
          .getEventSlotsByEventIdAndDate(this.eventState.eventId, dayOfMonth)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: eventSlots => {
              const dateById = eventSlots.slotDates as string[];
              this.availableEventDatesCount = eventSlots.slotsCount;
              if (Object?.keys(dateById)?.length > 0) {
                this.noFreeDates = false;
                this.firstFreeDate = new Date(dateById[0]);
                this.utilService.updateLocalStorage(
                  this.FIRST_FREE_DATE,
                  this.firstFreeDate
                );
                this.viewDate = this.firstFreeDate;
                this.viewDateChanged({ viewDate: this.viewDate, isNext: true });
              } else {
                this.noFreeDates = true;
                this.calendarLoaded = true;
              }
              this.viewMode = target;
              // Fetch custom fields
              this.fetchCustomFields();
              this.formReseted = false;
              this.utilService.updateLocalStorage(
                this.STATE_LS_NAME,
                this.eventState
              );
              this.googleAnalyticsService.emitBookingEvent(
                GTM_EVENTS.EVENT_BOOKING_STEP_AVAILABLE_EVENTS,
                this.prepareAnalyticsPayload()
              );
            },
            error: (err) => LoggerService.error(err.message)
          });
      }
    } else {
      if (target === this.eventConstants.SUMMARY) {
        this.resetDataBeforeLoadingSummaryPage();
        this.verifyOnlinePaymentStatus();
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.EVENT_BOOKING_STEP_SUMMARY,
          this.prepareAnalyticsPayload()
        );
      }
      this.viewMode = target;
      // Fetch custom fields
      this.fetchCustomFields();
      this.formReseted = false;
      this.utilService.updateLocalStorage(this.STATE_LS_NAME, this.eventState);
    }
  }

  fetchCustomFields(): void {
    this.isCustomFieldsLoaded = false;
    this.tempArray = [];
    this.eventCustomFields = [];
    if (!this.eventState.eventId) {
      return;
    }
    this.formsService
      .getEventCustomFields(this.eventState.eventId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: appointmentCustomFields => {
          if (!appointmentCustomFields) {
            appointmentCustomFields = [];
          }
          for (const appointmentCustomField of appointmentCustomFields) {
            this.tempArray.push(appointmentCustomField);
          }

          const trimmedArray: CustomFieldsDao[] = [];
          const values: number[] = [];
          // removing the duplicates
          for (
            let y = 0, len = Object.keys(this.tempArray).length;
            y < len;
            y++
          ) {
            const value: number = this.tempArray[y]['id'];
            if (
              values.indexOf(value) === -1 &&
              !this.tempArray[y]['parent_custom_field_id']
            ) {
              trimmedArray.push(this.tempArray[y]);
              if (
                this.tempArray[y].type === 'select' ||
                this.tempArray[y].type === 'card-select' ||
                this.tempArray[y].type === 'image-select'
              ) {
                // Prevet duplication of default option
                if (
                  this.tempArray[y].custom_field_options.length &&
                  this.tempArray[y].custom_field_options[0]['id'] != null &&
                  this.tempArray[y].type !== 'card-select' &&
                  this.tempArray[y].type !== 'image-select' &&
                  this.tempArray[y].type !== 'select'
                ) {
                  const tempCustomFieldOptions = new CustomFieldOptions();
                  tempCustomFieldOptions.id = null;
                  this.tempArray[y].custom_field_options.unshift(tempCustomFieldOptions);
                }
                if (!this.customFieldValues[this.tempArray[y].id]) {
                  if (this.tempArray[y].is_multiple_select === 1) {
                    const temp: string =
                      this.selectedJsonCustomFields &&
                        this.selectedJsonCustomFields[this.tempArray[y].id]
                        ? this.selectedJsonCustomFields[this.tempArray[y].id]
                        : undefined;
                    let finalValue: any[] = temp ? temp.split(',') : [];
                    if (finalValue?.length > 0) {
                      finalValue = finalValue.map((item) => Number(item));
                    }
                    this.customFieldValues[this.tempArray[y].id] = {
                      value: finalValue,
                      type: this.tempArray[y].type,
                    };
                  } else {
                    if (this.tempArray[y].type === 'select') {
                      if (
                        this.selectedJsonCustomFields &&
                        this.selectedJsonCustomFields[this.tempArray[y].id]
                      ) {
                        this.customFieldValues[this.tempArray[y].id] = Number(
                          this.selectedJsonCustomFields[this.tempArray[y].id]
                        );
                      } else if (this.customFieldValues[this.tempArray[y].id]) {
                        // Do nothing
                      } else {
                        this.customFieldValues[this.tempArray[y].id] = {
                          value: '',
                          type: 'select',
                        };
                      }
                    } else if (
                      this.tempArray[y].type === 'card-select' ||
                      this.tempArray[y].type === 'image-select'
                    ) {
                      if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[this.tempArray[y].id]) {
                        this.customFieldValues[this.tempArray[y].id] = {
                          value: [Number(this.selectedJsonCustomFields[this.tempArray[y].id])],
                          type: this.tempArray[y].type,
                        };
                      } else if (this.customFieldValues[this.tempArray[y].id]) {
                        // Do nothing
                      } else {
                        this.customFieldValues[this.tempArray[y].id] = {
                          value: [],
                          type: this.tempArray[y].type,
                        };
                      }
                    }
                  }
                }
              } else if (this.tempArray[y].type === 'number-select') {
                const optionIds: number[] = this.tempArray[
                  y
                ].custom_field_options.map(
                  (item: CustomFieldOptions) => item.id
                );
                const optionValues: {
                  custom_field_option_id: number;
                  custom_field_option_value: any;
                }[] = this.tempArray[y].custom_field_options.map(
                  (item: CustomFieldOptions) => {
                    item.option_value = item.number_default;
                    return {
                      custom_field_option_id: item.id,
                      custom_field_option_value: item.number_default,
                    };
                  }
                );

                this.customFieldValues[this.tempArray[y].id] = {
                  value: optionIds,
                  option_values: optionValues,
                  type: this.tempArray[y].type,
                };
              } else if (this.tempArray[y].type === 'file') {
                this.customFieldValues[this.tempArray[y].id] = {
                  type: 'file',
                  file_ids: this.customFieldValues[this.tempArray[y].id]?.file_ids?.length > 0 ? this.customFieldValues[this.tempArray[y].id]?.file_ids : [],
                };
              } else {
                if (
                  this.tempArray[y].type === 'text' ||
                  this.tempArray[y].type === 'textarea' ||
                  this.tempArray[y].type === 'radio' ||
                  this.tempArray[y].type === 'checkbox' ||
                  this.tempArray[y].type === 'date'
                ) {
                  if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[this.tempArray[y].id]) {
                    this.customFieldValues[this.tempArray[y].id] = this.selectedJsonCustomFields[this.tempArray[y].id];
                  } else {
                    this.customFieldValues[this.tempArray[y].id] = this.customFieldValues?.[this.tempArray?.[y]?.id] || undefined;
                  }
                }
              }
              values.push(value);
            }
          }
          // sorting of custom fields by position
          trimmedArray.sort(function (a, b) {
            return a.position - b.position;
          });

          this.eventCustomFields = trimmedArray;

          this.isCustomFieldsLoaded = true;
        },
        error: (err: HttpErrorResponse) => {
          LoggerService.error(err);
          this.isCustomFieldsLoaded = true;
        }
      });
  }

  resetForm(): void {
    if (this.partner.hide_back_on_bookingsite === 1) {
    } else {
      this.removeEventState();
    }
    this.removeUserState();
    this.removeCartState();
    this.removeCustomFieldsState();

    // Go back to first tab
    if (this.partner.hide_back_on_bookingsite === 1) {
      this.viewMode = EVENT_CONSTANT.DATE;
    } else {
      this.viewMode = EVENT_CONSTANT.EVENTS;
    }

    // Show success message
    this.formReseted = true;
  }

  removeEventState(): void {
    this.localStorageService.removeItem(this.STATE_LS_NAME, () => {
      // Clear form inputs
      this.eventState = {
        eventId: null,
        date: null,
        additional_guests_enabled: 0,
        number_allowed_additional_guests: 0,
        is_multi_day: 0,
        has_one_slot: 0,
        slots: [],
        day: 0,
        foundedSlot: null,
      };
    });
  }

  removeUserState(): void {
    this.localStorageService.removeItem(this.USER_LS_NAME, () => {
      // Clear form inputs
      this.userState = {
        forename: null,
        lastName: null,
        gender: null,
        eMail: null,
        phone: null,
        mobile: null,
        customerId: null,
        customerUuid: null,
        street: null,
        zip: null,
        city: null,
        meeting_type_id: null,
      };
    });
  }

  removeCartState(): void {
    this.localStorageService.removeItem(this.CART_LS_NAME, () => {
      // Clear form inputs
      this.cart = [];
    });
  }

  removeCustomFieldsState(): void {
    this.localStorageService.removeItem(this.CUSTOMFIELDS_LS_NAME, () => {
      // Clear form inputs
      this.customFieldValues = {};
    });
  }

  readLocalStorage(): void {
    // Read local storage to set form state
    this.readEventState();
    // Read local storage to set user state
    this.readUserState();
    // Read local storage to set cart state
    this.readCartState();
    // Read local storage to set custom fields state
    this.readCustomFieldsState();
    // Read local storage to set booked state
    this.readBookingState();
    // Read local storage to set first free appointment month
    this.readFreeStateState();
  }

  readEventState(): void {
    this.localStorageService.getItem(this.STATE_LS_NAME, (savedFormState: EventState) => {
      if (savedFormState) {
        this.eventState = savedFormState;

        if (this.globals?.eventId) {
          this.eventState.eventId = this.globals?.eventId;
          this.eventState.date = null;
          if (this.checkIfEventHasSlots(this.eventState.eventId)) {
            this.onAutoSelectSlotAndJumpToSummaryEvent();
            return;
          }
        }
        this.updateEventState();
        // if event selected go to another tab
        if (this.eventState.eventId) {
          const today = new Date();
          const dayOfMonth = this.utilService.dateToStr(
            new Date(today.getFullYear(), today.getMonth(), 1)
          );

          this.formsService.getEventSlotsByEventIdAndDate(this.eventState.eventId, dayOfMonth)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: eventSlots => {
                const dateById = eventSlots.slotDates as string[];
                this.availableEventDatesCount = eventSlots.slotsCount;
                if (Object.keys(dateById)?.length > 0) {
                  this.firstFreeDate = new Date(dateById[0]);
                  this.utilService.updateLocalStorage(
                    this.FIRST_FREE_DATE,
                    this.firstFreeDate
                  );
                  this.viewDate = this.firstFreeDate;
                  this.noFreeDates = false;
                  this.viewMode = EVENT_CONSTANT.DATE;
                  this.viewDateChanged({ viewDate: this.viewDate, isNext: true });
                } else {
                  this.noFreeDates = true;
                  this.removeEventState();
                }
              },
              error: (err) => LoggerService.error(err.message)
            });
        }
      } else {
        if (!this.eventState.eventId && this.globals?.eventId) {
          this.eventState.eventId = this.globals?.eventId;
          this.eventState.date = null;
          if (this.checkIfEventHasSlots(this.eventState.eventId)) {
            this.onAutoSelectSlotAndJumpToSummaryEvent();
            return;
          }
          this.updateEventState();
          this.navigateTo(EVENT_CONSTANT.DATE, true);
        }
      }
    });
  }

  checkIfEventHasSlots(eventId: string | number): boolean {
    if (!this.events || this.events?.length === 0) {
      return false;
    }
    const foundedEvent: EventDao = this.events.find((item: EventDao) => {
      if (eventId == item.uuid || eventId == item.id) {
        return true;
      } else {
        return false;
      }
    });
    if (foundedEvent) {
      if (
        foundedEvent.has_one_slot === 1 &&
        foundedEvent.slots &&
        foundedEvent.slots.length > 0
      ) {
        let foundedSlot: EventDateDao;
        for (const slot of foundedEvent.slots) {
          if (slot.start && !moment().isAfter(slot.start)) {
            foundedSlot = Object.assign({}, slot);
            break;
          }
        }
        if (!foundedSlot) {
          return false;
        }
        if (foundedSlot.regular_booking_count < foundedSlot.regular_capacity) {
          this.eventState.foundedSlot = foundedSlot;
          this.eventState.slots = foundedEvent.slots;
          this.eventState.is_multi_day = foundedEvent.is_multi_day;
          this.eventState.additional_guests_enabled = foundedEvent.additional_guests_enabled;
          this.eventState.number_allowed_additional_guests = foundedEvent.number_allowed_additional_guests;
          this.eventState.has_one_slot = 1;
          this.eventState.is_widget_event_guest_email_required = foundedEvent.is_widget_event_guest_email_required;
          this.eventState.is_widget_event_guest_phone_required = foundedEvent.is_widget_event_guest_phone_required;
          this.eventState.is_agenda_view_enabled = foundedEvent.is_agenda_view_enabled;
          return true;
        } else {
          LoggerService.warn('[Debug] Automatic jump is disabled because regular booking count is greater or equal to regular capacity');
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  updateEventState(): void {
    for (const event of this.events) {
      if (event.uuid == this.eventState.eventId || event.id == this.eventState.eventId) {
        this.eventState.additional_guests_enabled = event.additional_guests_enabled;
        this.eventState.number_allowed_additional_guests = event.number_allowed_additional_guests;
        this.eventState.is_multi_day = event.is_multi_day;
        this.eventState.meeting_type_id = event.meeting_type_id;
        this.eventState.meeting_type = event.meeting_type;
        this.eventState.place = event.place;
        this.eventState.is_widget_event_guest_email_required = event.is_widget_event_guest_email_required;
        this.eventState.is_widget_event_guest_phone_required = event.is_widget_event_guest_phone_required;
        this.eventState.is_agenda_view_enabled = Number(event.is_agenda_view_enabled);
      }
    }
  }

  readUserState(): void {
    this.localStorageService.getItem(
      this.USER_LS_NAME,
      (savedUserState: UserState) => {
        if (savedUserState) {
          this.userState = savedUserState;
        }
      }
    );
  }

  readCartState(): void {
    this.localStorageService.getItem(
      this.CART_LS_NAME,
      (savedCartState: EventCartItem[]) => {
        if (savedCartState) {
          this.cart = savedCartState;

          this.eventCartService.recalculateCart(this.cart);
          this.totalCartDuration = this.eventCartService.totalCartDuration;
          this.totalCartPrice = this.eventCartService.totalCartPrice;
        }
      }
    );
  }

  readCustomFieldsState(): void {
    this.localStorageService.getItem(
      this.CUSTOMFIELDS_LS_NAME,
      (savedCustomFieldsState: any) => {
        if (savedCustomFieldsState) {
          this.customFieldValues = savedCustomFieldsState;
        }
      }
    );
  }

  readBookingState(): void {
    this.localStorageService.getItem(
      this.BOOKED_ITEMS_LS_NAME,
      (savedBookedItems: EventCartItem[]) => {
        if (savedBookedItems) {
          this.bookedItems = savedBookedItems;
        }
      }
    );
  }

  readFreeStateState(): void {
    this.localStorageService.getItem(
      this.FIRST_FREE_DATE,
      (savedFreeEventDate: Date) => {
        if (savedFreeEventDate) {
          this.viewDate = savedFreeEventDate;
        }
      }
    );
  }

  book(): void {
    if (this.isValidBooking()) {
    }
  }

  isValidBooking(): boolean {
    if (this.eventForm.invalid) {
      LoggerService.error(this.eventForm);
      this.markFormGroupTouched(this.eventForm);
      this.isStripeBookButtonValidated();
      this.widgetUtilService.focusInvalidElements(this.elementRef);

      return false;
    }

    if (this.bookValidated() && this.isStripeBookButtonValidated()) {
      this.doBooking();
      return true;
    } else {
      LoggerService.error('Validation failed');
      return false;
    }
  }

  // Booking action
  doBooking(): void {
    this.disableBookingButton = true;
    this.helperService.bookingLoader.emit(true);

    // Extract custom field values
    let fieldIds = Object.keys(this.customFieldValues);
    const rawCustomFieldIds: number[] = this.tempArray.map((i) => i.id);

    if (rawCustomFieldIds?.length > 0) {
      fieldIds = fieldIds.filter((id: any) => {
        return rawCustomFieldIds.indexOf(Number(id)) > -1;
      });
    }

    const customFieldValues: {
      custom_field_id: number;
      type: string;
      value?: any;
      file_ids?: any;
      option_values?: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }[] = [];
    this.bookingErrorMessage = undefined;

    for (let i = 0; i < fieldIds.length; i++) {
      if (this.customFieldValues[fieldIds[i]]?.type === 'select') {
        if (Array.isArray(this.customFieldValues[fieldIds[i]]?.value)) {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: this.customFieldValues[fieldIds[i]]?.value,
          });
        } else {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: [this.customFieldValues[fieldIds[i]]?.value],
          });
        }
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'card-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'card-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'image-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'image-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (
        this.customFieldValues[fieldIds[i]]?.type === 'number-select'
      ) {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'number-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
          option_values: this.customFieldValues[fieldIds[i]]?.option_values,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'file') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'file',
          file_ids: Object.assign(
            [],
            this.customFieldValues[fieldIds[i]]?.file_ids
          ),
        });
      } else {
        let tempCustomFieldsDao: CustomFieldsDao;
        if (this.tempArray?.length > 0) {
          tempCustomFieldsDao = this.tempArray.find((item) => {
            return Number(item.id) === Number(fieldIds[i]);
          });
        }

        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: tempCustomFieldsDao ? tempCustomFieldsDao?.type : '',
          value: this.customFieldValues[fieldIds[i]]?.toString(),
        });
      }
    }

    // Create post body
    const bookingItems: EventBookItem[] = [];
    for (const item of this.cart) {
      this.isWaitingListBookingMessageEnabled = item.is_waitinglist;

      const bookingData: EventBookItem = {
        event_id: item.eventId,
        event_name: item.referenceEvent?.title,
        category: item.category,
        is_waitinglist: item.is_waitinglist,
        slot_id: item['slotId'],
        title: this.userState.title,
        prename: this.userState.forename,
        lastname: this.userState.lastName,
        gender: this.userState.gender,
        email: this.userState.eMail,
        phone: this.userState.phone,
        mobile: this.userState.mobile,
        country: this.userState.country_id,
        customer_notification_preference: this.userState.customer_notification_preference,
        customer_id: this.userState.customerId,
        customer_uuid: this.userState.customerUuid,
        company_name: (this.userState?.company || undefined),
        internal_comment: (this.userState?.internal_comment || undefined),
        comment: this.userState.comment,
        meeting_type_id: this.userState.meeting_type_id,
        customFieldValues: customFieldValues,
        user_browser_language: this.globals.user_browser_language,
        user_selected_language: this.globals.user_selected_language,
        guests: this.additionalGuests,
        worker_id: item.workerId,
        childSlots: item.selectedSlot ? [item.selectedSlot] : [],
        coupon_code:
          this.coupon && !this.isInvalidCoupon ? this.coupon.code : null,
        payment_type: this.paymentType,
        utm_source: ((this.utmSource && this.utmSource !== 'null') ? this.utmSource : undefined),
        utm_content: ((this.utmContent && this.utmContent !== 'null') ? this.utmContent : undefined),
        utm_medium: ((this.utmMedium && this.utmMedium !== 'null') ? this.utmMedium : undefined),
        utm_campaign: ((this.utmCampaign && this.utmCampaign !== 'null') ? this.utmCampaign : undefined),
        utm_term: ((this.utmTerm && this.utmTerm !== 'null') ? this.utmTerm : undefined),
        ...(this.partner?.show_address_on_widget !== 0 ? {
          street: this.userState.street,
          zip: this.userState.zip,
          city: this.userState.city
        } : {}),
      };

      if (this.paymentType === 'paypal') {
        bookingData.paypal_order_id = this.paypalOrderDetail.paypal_order_id;
        bookingData.paypal_payer_id = this.paypalOrderDetail.paypal_payer_id;
        bookingData.paypal_status = this.paypalOrderDetail.paypal_status;
        bookingData.paypal_raw_data = this.paypalOrderDetail.paypal_raw_data;
        bookingData.paypal_capture_id =
          this.paypalOrderDetail.paypal_capture_id;
      } else if (this.paymentType === 'saferpay') {
        bookingData.saferpay_transaction_id = this.saferpayPaymentDetails.transaction_id;
        bookingData.saferpay_payment_method = this.saferpayPaymentDetails.payment_method;
      } else if (this.paymentType === 'creditcard') {
        bookingData.stripe_card_token = this.stripePaymentMethodId ? this.stripePaymentMethodId : null;
      }

      bookingItems.push(bookingData);
    }

    for (let i = 0; i < fieldIds.length; i++) {
      if (this.customFieldValues[fieldIds[i]]?.type === 'file') {
        this.customFieldValues[fieldIds[i]].file_ids = [];
      }
    }

    this.utilService.updateLocalStorage(this.CUSTOMFIELDS_LS_NAME, this.customFieldValues);
    this.userState.captcha_challenge = undefined;
    this.utilService.updateLocalStorage(this.USER_LS_NAME, this.userState);

    this.formsService
      .bookEvents(bookingItems)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: appointment => {
          if (appointment?.errors?.[0]?.message) {
            this.bookingErrorMessage = appointment.errors[0].message;
            return;
          } else if (appointment.bookingErrors) {
            const bookingErrorKey = Object.keys(appointment.bookingErrors)?.[0];
            if (bookingErrorKey) {
              this.finalPage = false;
              this.bookingErrorMessage = appointment.bookingErrors[bookingErrorKey]?.error;
            }
          }

          if (appointment.success) {
            // send booking success to event to parent window
            this.googleAnalyticsService.emitEventToParent(WINDOW_LISTENER_CONSTANTS.EVENT_BOOKING_DONE, appointment?.notificationHelperArray);
            this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.EVENT_BOOKING_STEP_SUCCESS);

            // Check if waiting list message isn't active then check for any booking is not success because of
            // event limit is reached
            if (!this.isWaitingListBookingMessageEnabled) {
              this.showBookingLimitReachedMessageOnFinalPage = bookingItems?.length !== appointment?.notificationHelperArray?.length;
            }

            this.finalPage = true;
            if (appointment?.notificationHelperArray?.length > 0) {
              for (const item of appointment?.notificationHelperArray) {
                if (item?.customerRegistration?.uuid) {
                  this.successFullBookings.push({
                    landingPageLink: environment?.calioDashboardBaseUrl + '/events/' + item?.customerRegistration?.uuid,
                    bookingUUID: item?.customerRegistration?.uuid,
                  });
                }
              }
            }

            // clear cart from ls and object
            this.localStorageService.removeItem(this.STATE_LS_NAME, () => { });
            this.localStorageService.removeItem(this.CART_LS_NAME, () => { });
            this.localStorageService.removeItem(this.BOOKED_ITEMS_LS_NAME, () => { });

            this.eventState = {
              eventId: null,
              date: null,
              additional_guests_enabled: 0,
              number_allowed_additional_guests: 0,
              is_multi_day: 0,
              has_one_slot: 0,
              slots: [],
              day: 0,
              foundedSlot: null,
            };
            this.cart = [];
            this.additionalGuests = [];
          } else {
            // Redirect to final page with error message
            this.finalPage = false;
          }
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;

          if (this.widgetConf.subscription.subscription_type_id >= this.widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
            if (this.partner.is_redirect_after_booking_enabled && this.partner.successful_booking_redirect_url && !this.globals.isInternal) {
              window.open(this.partner.successful_booking_redirect_url, '_blank');
            }
          }

          this.booked = true;
          this.disableBookingButton = false;
          this.helperService.bookingLoader.emit(false);
          this.bookingStarted = false;
          this.isInvalidCoupon = false;
        },
        error: (error) => {
          LoggerService.error(error);
          this.finalPage = false;
          this.booked = true;
          this.bookingStarted = false;
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;
          this.isInvalidCoupon = false;
          this.disableBookingButton = false;
          this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.EVENT_BOOKING_STEP_FAILED);
          this.helperService.bookingLoader.emit(false);
        }
      });
  }

  // Check if all prerequests are good on order to enable book button
  bookValidated(): boolean {
    if (this.cart.length > 0) {
      if (this.globals?.isInternal) {
        // Check if customer is selected
        if (this.customerSelected) {
          return true;
        } else {
          if (this.userState.forename && this.userState.lastName) {
            if (this.partner?.needPhoneNumber && this.partner.widget_phone_required && !this.userState.phone) {
              return false;
            }

            if (this.partner?.needMobileNumber && this.partner.widget_mobile_required && !this.userState.mobile) {
              return false;
            }

            if (this.partner?.internal_widget_email_shown === 1) {
              if (
                this.partner?.internal_widget_email_required === 1 &&
                this.userState.eMail
              ) {
                return true;
              }
            }
            return true;
          }
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  updateUserState(newUserState: UserState): void {
    this.userState = newUserState;
  }

  updateCustomerSelected(newCustomerSelected: object): void {
    this.customerSelected = newCustomerSelected;
  }

  updateAdditionalGuests(additionalGuests: GuestModel[]) {
    this.additionalGuests = additionalGuests;
  }

  ignore(): boolean {
    return false;
  }

  verifyOnlinePaymentStatus() {
    this.partnerService.verifyOnlinePaymentStatus()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: paymentStatus => {
          this.isEventOnlinePaymentEnabled = Number(paymentStatus.isEventOnlinePaymentEnabled);
          if (paymentStatus) {
            if (
              paymentStatus.isEventOnlinePaymentEnabled === 1 &&
              paymentStatus.stripeEnabled
            ) {
              this.isStripeEnabled = true;
              this.helperService.loadStripeJs();
            }

            if (paymentStatus.isOnlinePaymentMandatory === 1) {
              this.isOnlinePaymentMandatory = true;
            }

            if (paymentStatus.isBexioEventPaymentEnabled === 1 && paymentStatus.bexioEnabled) {
              this.isBexioEnabled = true;
            }

            if (
              paymentStatus.isEventOnlinePaymentEnabled === 1 &&
              paymentStatus.saferpayEnabled
            ) {
              this.isSaferpayEnabled = true;
            }

            if (
              paymentStatus.isEventOnlinePaymentEnabled === 1 &&
              paymentStatus.paypalEnabled
            ) {
              this.isPaypalEnabled = true;
              this.paypalSetting = new PayPalSettingsDbModel();
              this.paypalSetting.access_token = paymentStatus.paypalAccessToken;
              this.paypalSetting.client_id = paymentStatus.paypalClientId;
              // this.helperService.loadPaypalJs(this.paypalSetting.client_id);
            }

            if (paymentStatus.onlinePaymentShowOnSitePaymentType === 1) {
              this.isStoreEnabled = true;
            }

            this.isLexOfficePaymentTypeEnabled = (paymentStatus.lexOfficeEnabled === true);
          }
          this.paymentType = undefined;
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  onChangePaymentType(event: string) {
    this.paymentType = event;
    this.stripePaymentMethodId = null;
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;

    // manage book and saferpay payment link visibility based on payment selection
    if (this.paymentType === 'saferpay') {
      this.showSaferpayButtonLink = true;
    } else {
      this.showSaferpayButtonLink = false;
    }
  }

  // Check if all prerequests are good on order to enable book button
  isStripeBookButtonValidated(): boolean {
    if (
      this.totalCartPrice === 0 ||
      this.totalCartPrice === -1 ||
      this.priceAfterCouponReduction <= 0
    ) {
      this.paymentType = 'store';
      return true;
    } else {
      if (
        !this.isBexioEnabled &&
        !this.isStripeEnabled &&
        !this.isPaypalEnabled &&
        !this.isSaferpayEnabled &&
        !this.isLexOfficePaymentTypeEnabled
      ) {
        // TODO Add comment online payment is not active
        this.paymentType = 'store';
        return true;
      } else {
        if (this.isOnlinePaymentMandatory) {
          if (
            (this.stripePaymentMethodId && this.paymentType === 'creditcard') ||
            this.paymentType === 'invoice' ||
            (this.paypalOrderDetail && this.paymentType === 'paypal') ||
            (this.saferpayPaymentDetails && this.paymentType === 'saferpay')
          ) {
            return true;
          } else {
            // TODO it should catch not valid stripe credit cards or payment type is store / null
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        } else {
          if (this.paymentType === 'creditcard') {
            if (this.stripePaymentMethodId) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'invoice') {
            return true;
          } else if (this.paymentType === 'store') {
            return true;
          } else if (this.paymentType === 'lex_office_invoice') {
            return true;
          } else if (this.paymentType === 'saferpay') {
            if (this.saferpayPaymentDetails) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'paypal') {
            if (this.paypalOrderDetail) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else {
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        }
      }
    }
  }

  onStripeSuccessfulEvent(event: string) {
    this.stripePaymentMethodId = event;
  }

  onResetStripeTokenEvent(event: any) {
    this.stripePaymentMethodId = null;
  }

  onPaypalSuccessfulEvent(event: PayPalOrderDetailModel) {
    this.paypalOrderDetail = event;
    this.customEventService.paypalOrderSuccessfullEvent.emit();
    this.book();
  }

  onResetPaypalEvent(event: any) {
    this.paypalOrderDetail = null;
  }

  onSaferpaySuccessfulEvent(paymentDetails: SaferpayPaymentDetailsDbModel) {
    this.saferpayPaymentDetails = paymentDetails;
    this.showFullscreenOverlay = false;
    this.showSaferpayButtonLink = false;
    this.book();
  }

  onResetSaferpayEvent(errorMessage: string): void {
    this.bookingErrorMessage = errorMessage;
    this.finalPage = false;
    this.disableBookingButton = false;
    this.helperService.bookingLoader.emit(false);
    this.saferpayPaymentDetails = undefined;
    this.showFullscreenOverlay = false;
  }

  hideBookingAgainButton(): boolean {
    if (this.finalPage) {
      if (this.partner.hide_book_additional_appointment_button === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onCouponSuccessEvent(event: CouponDbModel) {
    this.coupon = event;
    this.couponDisableConfirmButton = false;
    this.isInvalidCoupon = false;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;
    this.customEventService.couponChangeEvent.emit(this.finalCouponDiscount);

    // Case when payment type is saferpay and total cart price is 0 or -1 or price after coupon reduction is 0 or -1 then hide saferpay payment button and show actual book button
    if (
      this.paymentType === 'saferpay' &&
      (this.totalCartPrice === 0 || this.totalCartPrice === -1 || this.priceAfterCouponReduction <= 0)
    ) {
      this.showSaferpayButtonLink = false;
    }
  }

  calculateCoupon() {
    if (this.coupon && this.coupon.is_fixed_amount === 1) {
      this.finalCouponDiscount = Number(this.coupon.fixed_amount);
    }

    if (this.coupon && this.coupon.is_percentage === 1) {
      if (this.additionalGuests && this.additionalGuests.length > 0) {
        const totalPrice: number =
          (this.additionalGuests.length + 1) * Number(this.totalCartPrice);
        this.finalCouponDiscount = Number(
          ((Number(totalPrice) / 100) * this.coupon.percentage).toFixed(2)
        );
      } else {
        this.finalCouponDiscount = Number(
          (
            (Number(this.totalCartPrice) / 100) *
            this.coupon.percentage
          ).toFixed(2)
        );
      }
    }

    if (this.additionalGuests && this.additionalGuests.length > 0) {
      const totalPrice: number =
        (this.additionalGuests.length + 1) * Number(this.totalCartPrice);
      this.priceAfterCouponReduction =
        Number(totalPrice) - Number(this.finalCouponDiscount);
    } else {
      this.priceAfterCouponReduction =
        Number(this.totalCartPrice) - Number(this.finalCouponDiscount);
    }
  }

  onCouponFailedEvent(event: {
    disableBookingButton: boolean;
    invalidCoupon: boolean;
  }) {
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.couponDisableConfirmButton = event.disableBookingButton;
    this.isInvalidCoupon = event.invalidCoupon;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;

    this.paymentType === 'saferpay' && (this.showSaferpayButtonLink = true);
  }

  setupInitialTemplates() {
    this.nextButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NEXT_LABEL);
    this.nextButtonTemplate && (this.nextButtonTemplate.is_multi_language = 1);

    this.previousButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_PREVIOUS_LABEL);
    this.previousButtonTemplate && (this.previousButtonTemplate.is_multi_language = 1);

    this.bookButtonTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_BOOK_LABEL);
    this.bookButtonTemplate && (this.bookButtonTemplate.is_multi_language = 1);

    this.dateLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_DATE_LABEL);
    this.dateLabelTemplate && (this.dateLabelTemplate.is_multi_language = 1);

    this.manageAppointmentNowTemplate = this.widgetTemplates.find(template => template.id === 178);
    this.manageAppointmentNowTemplate && (this.manageAppointmentNowTemplate.is_multi_language = 1);

    this.downloadBookingDetailTemplate = this.widgetTemplates.find(template => template.id === 180);
    this.downloadBookingDetailTemplate && (this.downloadBookingDetailTemplate.is_multi_language = 1);

    this.widgetSuccessPageEventBookingLimitReachedTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_SUCCESS_PAGE_EVENT_BOOKING_LIMIT_REACHED);
    this.widgetSuccessPageEventBookingLimitReachedTemplate && (this.widgetSuccessPageEventBookingLimitReachedTemplate.is_multi_language = 1);
  }

  displayRegularData(event: EventDateDao): boolean {
    if (event.regular_booking_count < event.regular_capacity) {
      return true;
    } else {
      return false;
    }
  }

  displayWaitingData(event: EventDateDao): boolean {
    if (event.waitinglist_booking_count < event.waitinglist_capacity) {
      return true;
    } else {
      return false;
    }
  }

  getWidgetTemplates() {
    this.partnerService.getWidgetTemplates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: templates => {
          this.widgetTemplates = templates;
          this.setupInitialTemplates();
          this.createSuccessMessage(this.lang);
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  // Add item to local storage without remove previous items

  addToBookedItems(lsItemsToAdd: EventCartItem[]): void {
    this.localStorageService.getItem(
      this.BOOKED_ITEMS_LS_NAME,
      (items: EventCartItem[]) => {
        // Remove old items from local storage
        if (items && items.length) {
          const now = new Date();
          items.forEach((itm) => {
            const fullStartTime = new Date(
              itm.date.getFullYear(),
              itm.date.getMonth(),
              itm.date.getDay(),
              itm.startTime.getHours(),
              itm.startTime.getMinutes()
            );

            if (fullStartTime <= now) {
              // Exclude element from array
              const index = items.indexOf(itm);
              if (index > -1) {
                items.splice(index, 1);
              }
            }
          });
        }

        // Add new booked items
        let updatedItems: EventCartItem[] = items;

        if (updatedItems && updatedItems.length) {
          updatedItems = updatedItems.concat(lsItemsToAdd);
        } else {
          // Set first value
          updatedItems = [];
          updatedItems = updatedItems.concat(lsItemsToAdd);
        }

        this.localStorageService.setItem(
          this.BOOKED_ITEMS_LS_NAME,
          updatedItems,
          () => {
            this.bookedItems = updatedItems;
          }
        );
      }
    );
  }

  private markFormGroupTouched(form: NgForm) {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();

      /*if ((control as any).controls) {
        this.markFormGroupTouched(control);
      }*/
    });
  }

  private scrollToEventComponent(): void {
    if (this.widgetConf?.scroll_to_element_feature_is_enabled === 1) {
      const element = document.getElementById('event-component');
      element.scrollIntoView();
    }
  }

  private resetDataBeforeLoadingSummaryPage() {
    this.isStripeEnabled = false;
    this.isOnlinePaymentMandatory = false;
    this.isBexioEnabled = false;
    this.isStoreEnabled = false;
    this.isLexOfficePaymentTypeEnabled = false;
    this.isPaypalEnabled = false;
    this.isSaferpayEnabled = false;
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.priceAfterCouponReduction = undefined;
    this.stripePaymentMethodId = undefined;
    this.additionalGuests = [];
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;
  }

  payUsingSaferpay(): void {
    if (this.eventForm.valid) {
      this.showFullscreenOverlay = true;
      this.paymentService.initiateSaferpaySubject.next(true);
    } else {
      this.markFormGroupTouched(this.eventForm);
      this.isStripeBookButtonValidated();
    }
  }

  closeSaferpayOverlay(): void {
    this.showFullscreenOverlay = false;
    this.onResetSaferpayEvent('');
    this.paymentService.handleSaferpayBackgroundTriggerManually.next(true);
  }

  prepareAnalyticsPayload(): EventsAnalyticsDao {
    const payload = new EventsAnalyticsDao();

    // get selected event
    if (this.eventState?.eventId) {
      payload.event_uuid = this.eventState.eventId;
    }

    // get selected event category
    if (this.cart[0]?.category) {
      payload.event_category = this.cart[0].category;
    }

    // get selected worker id
    if (this.cart[0]?.workerId) {
      payload.worker_id = this.cart[0].workerId;
    }

    // get selected event title
    if (this.cart[0]?.referenceEvent?.title) {
      payload.event_title = this.cart[0].referenceEvent.title;
    }

    return payload;
  }

  protected handelAgendaView(event = { isNext: true, isPrevious: false }): void {
    this.calendarLoaded = false;
    this.eventOnYear = [];
    this.formsService
      .getSlots(this.eventState.eventId, this.agendaViewSelectedDate, 'year')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.calendarLoaded = true)
      )
      .subscribe({
        next: eventSlots => {
          this.availableEventDatesCount = eventSlots?.length ?? 0;
          this.noFreeSlots = !eventSlots?.length;

          if (eventSlots?.length) {
            this.eventOnYear = eventSlots;
            return;
          }

          const isSameOrAfterMaxYear = moment(this.agendaViewSelectedDate).isSameOrAfter(this.maxAgendaViewDate, 'year');
          const isSameAsCurrentYear = moment(this.agendaViewSelectedDate).isSame(this.currentDate, 'year');

          if (event.isNext && !isSameOrAfterMaxYear || event.isPrevious && !isSameAsCurrentYear) {
            this.viewYearChanged(event);
          }
        },
        error: (err) => LoggerService.error(err.message)
      });
  }

  protected viewYearChanged(event: { isNext: boolean, isPrevious: boolean }): void {
    this.customEventService.eventCalendarIsNextPressed = event.isNext;
    this.customEventService.eventCalendarIsPreviousPressed = event.isPrevious;
    if (event.isNext) {
      this.agendaViewSelectedDate = this.utilService.dateToStr(new Date(new Date(this.agendaViewSelectedDate).getFullYear() + 1, 0, 1));
    } else if (event.isPrevious) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const randomYear = new Date(this.agendaViewSelectedDate).getFullYear();
      if (currentYear === randomYear - 1) {
        this.agendaViewSelectedDate = this.utilService.dateToStr(currentDate);
      } else {
        this.agendaViewSelectedDate = this.utilService.dateToStr(new Date(new Date(this.agendaViewSelectedDate).getFullYear() - 1, 0, 1));
      }
    }
    this.handelAgendaView(event);
  }
}
