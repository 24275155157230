export class Partner {
  id: number;
  uuid: string;
  name: string;
  bookingName: string;
  logo: string;
  currency: string;
  url: string;
  languageCode: string;
  showEgalWer: boolean;
  needCustomerAddress: boolean;
  needPhoneNumber: boolean;
  needMobileNumber: boolean;
  needMessage: boolean;
  countryCode: string;
  courtesyForm: string;
  appointmentLeadTime: number;
  eventLeadTime: number;
  appointmentBookingInfo: string;
  eventSlotBookingInfo: string;
  hide_login_on_bookingsite: number;
  hide_back_on_bookingsite: number;
  hide_book_additional_appointment_button: number;
  subscription_type_id: number;
  allow_multiple_services_bookings: number;
  /*appointmentBookingInfoTemplate: BookingMessageTemplate;
  eventBookingInfoTemplate: BookingMessageTemplate;*/
  hide_resource_step: number;
  hide_mail_resource: number;
  future_booking_threshold_appointments: number;
  future_booking_threshold_events: number;
  bookingwidget_enabled: number;
  // widget_expand_appointment_service_categories: number;
  widget_appointment_service_category_status: number;
  supported_widget_languages: string[];
  language_identifier: string;
  // templates: CustomBookingMessageTemplate[] = [];
  enable_store_postcode_feature: number;
  ask_for_customer_notification_preference: number;
  disable_automatic_search: number;
  enable_services_search: number;
  widget_hide_resource_step_for_default_workers: number;
  widget_disable_search_all_resource_button: number;
  activate_phone_number_verification_feature: number;
  activate_mobile_number_verification_feature: number;
  widget_email_shown: number;
  widget_email_required: number;
  widget_show_lead_creation_button: number;
  show_distance_in_stores: number;
  automatic_store_zip_search: number;
  show_salutation_in_widget: number;
  internal_widget_email_shown: number;
  internal_widget_email_required: number;
  show_inline_description: number;
  is_maintenance_mode_enabled: number;
  show_privacy_message_checkbox: number;
  is_round_robin_feature_enabled: number;
  widget_address_required: number;
  is_customer_profiles_feature_enabled: number;
  is_redirect_after_booking_enabled: number;
  successful_booking_redirect_url: string;
  customer_profiles: CustomerProfile[] = [];
  widget_salutation_required: number;
  widget_phone_required: number;
  widget_message_required: number;
  internal_widget_store_search_list: number;
  hide_event_capacity?: number;
  widget_company_name_required?: number;
  widget_company_name_shown?: number;
  widget_internal_comment_required?: number;
  widget_internal_comment_shown?: number;
  is_widget_banner_hint_enabled: number;
  widget_banner_hint_color: string;
  widget_banner_hint_text: string;
  anyone_feature_hide_ressource: number;
  is_internal_widget_enabled: number;
  widget_title_required: number;
  widget_title_shown: number;
  widget_country_required: number;
  widget_country_shown: number;
  widget_show_resource_store_name: number;
  is_group_appointment_store_feature_enabled: number;
  is_group_appointment_category_feature_enabled: number;
  is_widget_event_single_slot_preselect_enabled: number;
  widget_mobile_required: number;
  group_appointments_filter_behaviour: string;
  widget_show_location_on_appointment_proposal: number;
  is_widget_service_shortcut_button_enabled: number;
  include_city_name_in_postcode_results: number;
  widget_show_free_slots_in_overview: number;
  is_captcha_feature_enabled: number;
  show_worker_selection_on_date_step: number;
  is_widget_postcode_worker_random_selection_enabled: number;
  show_widget_slot_location: number;
  widget_jumps_to_resources_step_when_single_service: number;
  is_appointment_reservation_enabled: number;
  split_personal_form_and_summary_in_widget: number;
  show_address_on_widget: number;
}

export class Subscription {
  id: number;
  subscription_type_id: number;
  employees;
}

export class GlobalObjects {
  intlOptions: IntlOptions = new IntlOptions();
  isInternal: boolean;
  isWhitelabeled: boolean;
  customers: any[];
  // _currentCustomer: Observable<UserDao>;
  eventId: string;
  user_browser_language: string;
  user_selected_language: string;
}

export class IntlOptions {
  initialCountry: string;
  separateDialCode: boolean;
  preferredCountries: string[] = [];
  formatOnDisplay: boolean;
  utilsScript: string;
}

export class CustomerProfile {
  id: number;
  uuid: string;
  title: string;
  description: string;
  icon: string;
  context: CustomerProfileContext;
  internal: number;
  partner_id: number;
  is_multi_language: number;
}

export class CustomerProfileContext {
  customer: string[] = [];
  customer_mandatory: string[] = [];
  broker: string[] = [];
  broker_mandatory: string[] = [];
  order: string[] = [];
}
